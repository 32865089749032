import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    strict: process.env.VUE_APP_STORE_STRICT || false,
    state: {
        dictList: [],/*字典值*/
        sysUser: {},
        lastErrorTime: 0,
        helpTaskCnt: 0
    },
    getters: {
        /**
         * 根据字典值code获取字典值列表
         * @param state
         * @returns {function(*): *}
         */
        getDictList: (state) => (code) => {
            return state.dictList.filter(dict => dict.code == code);
        },
        /**
         * 根据字典值code和value获取单个字典值对象
         * @param state
         * @returns {function(*, *): *}
         */
        getDict: (state) => (code, value) => {
            return state.dictList.find(dict => dict.code == code && dict.value == value);
        },
        /**
         * 根据字典值code和value获取单个字典值的名称
         * @param state
         * @returns {function(*, *): *}
         */
        getDictName: (state) => (code, value) => {
            let dict = state.dictList.find(dict => dict.code == code && dict.value == value);
            return dict ? dict.name : '';
        },
        getLastErrorTime: (state) => () => {
            return state.lastErrorTime;
        },
        hasRole: (state) => (roleCode) => {
            if (!state.sysUser.roles) {
                return false
            }
            for (let i = 0; i < state.sysUser.roles.length; i++) {
                let role = state.sysUser.roles[i];
                if (role.id == roleCode) {
                    return true;
                }
            }
            return false;
        },
        hasAnyRole: (state, getters) => (roles) => {
            roles = roles || []

            for (let i = 0; i < roles.length; i++) {
                let hasRole = getters.hasRole(roles[i]);
                if (hasRole) {
                    return true
                }
            }
            return false
        }
    },
    mutations: {
        /*设置字典值*/
        setDictList(state, list) {
            state.dictList = list;
        },
        setLastErrorTime(state, time) {
            state.lastErrorTime = time;
        },
        setSysUser(state, sysUser) {
            state.sysUser = sysUser
        },
        setHelpTaskCnt(state, cnt) {
            state.helpTaskCnt = cnt
        },
    }
})
export default store;