<template>
  <div>
    <el-upload ref="myUpload"
               :class="{hide:hideUpload}"
               :action="uploadUrl"
               :headers="uploadHeaders"
               :limit="imgCnt"
               multiple
               list-type="picture-card"
               :file-list="uploadList"
               accept=".jpg"
               :on-success="handleUploadSuccess"
               :on-remove="handleRemove"
               :on-preview="handlePictureCardPreview">
      <i class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" :fullscreen="true" :append-to-body="true">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <span>( <span>{{ imgCnt }}</span>张内JPG格式照片 )</span>
  </div>
</template>

<script>
export default {
  name: "ImageUploadComponent",
  props: {
    imgCnt: {
      type: Number,
      default: 1,
      validator: function (value) {
        return Number.isInteger(value) && value >= 1;
      }
    },
    url: {
      type: String,
      default: 'upload/singleFile'
    },
    fileList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      baseUrl: '',
      uploadUrl: '',
      uploadHeaders: {},
      dialogImageUrl: '',
      dialogVisible: false,
      uploadList: [],
      hideUpload: false
    };
  },
  created() {
    //重要设置
    this.uploadUrl = process.env.VUE_APP_AXIOS_IMG_BASE_URL + this.url;
    this.uploadHeaders = {token: localStorage.getItem('token')}
    this.uploadList = this.fileList
  },
  methods: {
    //上传成功
    handleUploadSuccess() {
      console.log("success");
      this.onChange();
    },
    handleRemove() {
      console.log("remove");
      this.onChange();
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    onChange() {
      let uploadFiles = this.$refs.myUpload.uploadFiles;
      var imageList = [];
      for (var i = 0; i < uploadFiles.length; i++) {
        if (uploadFiles[i].old) {
          imageList.push(uploadFiles[i].imageId)
        } else {
          //从这里获取服务器返回的数据,获取文件id
          imageList.push(uploadFiles[i].response.data.id)
        }
      }

      //回传文件id到父对象
      this.$emit('imageChange', imageList)

      this.hideUpload = imageList.length >= this.imgCnt;
    },
    onClear() {
      this.$refs.myUpload.uploadFiles = [];
    }
  },
  watch: {
    fileList: {
      immediate: true,
      handler: function () {
        this.uploadList = this.fileList;
        this.hideUpload = this.uploadList.length >= this.imgCnt;
      }
    }
  }
}
</script>

<style scoped>
.hide /deep/ .el-upload--picture-card {
  display: none;
}

</style>