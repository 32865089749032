<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
#app{
  min-height: 600px;
  min-width: 1200px;
}
</style>
