<template>
  <div>
    <el-table :data="itemList" border stripe v-loading="loading" height="500">
      <el-table-column align="center" label="ID" prop="id" width="180"></el-table-column>
      <el-table-column align="center" label="备件编码" prop="materialCode" width="120"></el-table-column>
      <el-table-column align="center" label="备件名称" prop="materialName" width="300"></el-table-column>
      <el-table-column align="center" label="加急标记" prop="urgentFlag"></el-table-column>
      <el-table-column align="center" label="工厂" prop="legalPlantCode"></el-table-column>
      <el-table-column align="center" label="检验计划编码" prop="testPlanCode" width="180"></el-table-column>
      <el-table-column align="center" label="收货数量" prop="receiveQty" width="100"></el-table-column>
      <el-table-column align="center" label="未借出数量" prop="notBorrowedAmount" width="100"></el-table-column>
      <el-table-column align="center" label="本次借出数量" prop="lendQty" width="110"></el-table-column>
      <el-table-column align="center" label="已借出数量" prop="alreadyBorrowedAmount" width="100"></el-table-column>
      <el-table-column align="center" label="电性能检验数量" prop="electricityRequiredTestQty" width="120"></el-table-column>
      <el-table-column align="center" label="外观检验数量" prop="appearanceRequiredTestQty" width="110"></el-table-column>
      <el-table-column align="center" label="外观检验方式" prop="appearanceType" width="110"></el-table-column>
      <el-table-column align="center" label="电性能检验方式" prop="electricityType" width="110"></el-table-column>
      <el-table-column align="center" label="备件来源" prop="materialSourceName" width="250"></el-table-column>
      <el-table-column align="center" label="来源订单号" prop="poDoc" width="200"></el-table-column>
      <el-table-column align="center" label="备件小类CODE" prop="subClass" width="120"></el-table-column>
      <el-table-column align="center" label="备件小类名称" prop="subClassName" width="200"></el-table-column>
      <el-table-column align="center" label="计划大类CODE" prop="planCategory" width="120"></el-table-column>
      <el-table-column align="center" label="计划大类名称" prop="planCategoryName" width="170"></el-table-column>
      <el-table-column align="center" label="运作产线CODE" prop="chargeLineCode" width="120"></el-table-column>
      <el-table-column align="center" label="运作产线名称" prop="chargeLineName" width="170"></el-table-column>
      <el-table-column align="center" label="收货时间" prop="receiverTime" width="170"></el-table-column>
      <el-table-column align="center" label="暂存收货ASN" prop="asnDoc" width="270"></el-table-column>
      <el-table-column align="center" label="暂存收货单号" prop="receiveDoc" width="210"></el-table-column>
      <el-table-column align="center" label="暂存订单入库完成时间" prop="testReceiveTime" width="170"></el-table-column>
      <el-table-column align="center" label="暂存收货完成人账号" prop="receiver" width="150"></el-table-column>
      <el-table-column align="center" label="在库时长(小时)" prop="atTheTimeOfTheLibrary" width="120"></el-table-column>
      <el-table-column align="center" label="备件技服条码" prop="sn" width="200"></el-table-column>
      <el-table-column align="center" label="备件供应商条码" prop="supplierSn" width="200"></el-table-column>
      <el-table-column align="center" label="备件标识" prop="materialMarkName" width="100"></el-table-column>
      <el-table-column align="center" label="不可索赔原因" prop="noRmaReason" width="120"></el-table-column>
      <el-table-column align="center" label="退回备注" prop="sendBackRemark" width="120"></el-table-column>
      <el-table-column align="center" label="索赔复判理由说明" prop="rmaRejudgeReasonName" width="120"></el-table-column>
      <el-table-column align="center" label="索赔复判备注" prop="rmaRejudgeRemark" width="120"></el-table-column>
      <el-table-column align="center" label="buffer入库单备注" prop="bufferRemark" width="120"></el-table-column>
      <el-table-column align="center" label="条码" prop="materialSn" width="220"></el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  name: "LendOrderItem",
  data() {
    return {
      itemList: [],
      loading: false
    }
  },
  methods: {
    async query(orderId) {
      this.loading = true;
      const {data: res} = await this.$axios.get('lendOrderItem/queryItems?orderId=' + orderId);
      this.loading = false;
      if (res.code !== 0) {
        this.$message.error(res.message)
        return
      }
      this.itemList = res.data;

    }
  }
}
</script>

<style scoped>

</style>