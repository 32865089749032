<template>
  <el-card class="order-info-card" :class="{'no-border':station=='qc'}" shadow="never">
    <el-row v-if="busItem.urgent">
      <el-col :span="2" class="itemText">提示：</el-col>
      <el-col :span="22" class="urgent-tip">该实物为加急件，请优先处理。</el-col>
    </el-row>
    <el-row>
      <el-col :span="2" class="itemText">检验类型：</el-col>
      <el-col :span="4" class="itemValue red-tip">{{ testTypeName }}
        <span v-if="busItem.finishAndTotalCountTip" style="font-size: 14px;color: #0000FF;cursor: pointer" @click="showNotFinish">（完成/总数：{{ busItem.finishAndTotalCountTip }}）</span>
      </el-col>
      <el-col :span="2" class="itemText">当前工站：</el-col>
      <el-col :span="4" class="itemValue red-tip">{{ busItem.currentStationName }}</el-col>
      <el-col :span="2" class="itemText">当前工站状态：</el-col>
      <el-col :span="4" class="itemValue red-tip">{{ busItem.currentStationStatusName }}</el-col>
      <el-col :span="2" class="itemText">下一工站：</el-col>
      <el-col :span="4" class="itemValue red-tip">{{ busItem.nextStationName }}</el-col>
    </el-row>
    <el-row>
      <el-col :span="2" class="itemText">外观检验结果：</el-col>
      <el-col :span="4" class="itemValue red-tip">{{ test1ResultText }}</el-col>
      <el-col :span="2" class="itemText">外观不良代码：</el-col>
      <el-col :span="4" class="itemValue red-tip" :title="test1TroubleCodeText">{{ test1TroubleCodeText }}</el-col>
      <el-col :span="2" class="itemText">外观不良原因：</el-col>
      <el-col :span="4" class="itemValue red-tip" :title="busItem.test1TroubleReason">{{ busItem.test1TroubleReason }}</el-col>
      <el-col :span="2" class="itemText">备件来源：</el-col>
      <el-col :span="4" class="itemValue red-tip">{{ busItem.materialSourceName }}</el-col>
    </el-row>
    <el-row>
      <el-col :span="2" class="itemText">电性能检验结果：</el-col>
      <el-col :span="4" class="itemValue red-tip">{{ test2ResultText }}</el-col>
      <el-col :span="2" class="itemText">电性能不良代码：</el-col>
      <el-col :span="4" class="itemValue red-tip" :title="test2TroubleCodeText">{{ test2TroubleCodeText }}</el-col>
      <el-col :span="2" class="itemText">电性能不良原因：</el-col>
      <el-col :span="4" class="itemValue red-tip" :title="busItem.test2TroubleReason">{{ busItem.test2TroubleReason }}</el-col>
      <el-col :span="2" class="itemText">运作产线：</el-col>
      <el-col :span="4" class="itemValue red-tip">{{ busItem.chargeLineName }}</el-col>
    </el-row>
    <el-row>
      <el-col :span="2" class="itemText">备件编码：</el-col>
      <el-col :span="4" class="itemValue red-tip">{{ busItem.materialCode }}</el-col>
      <el-col :span="2" class="itemText">检验结果：</el-col>
      <el-col :span="4" class="itemValue red-tip">{{ busItem.testResultName }}</el-col>
      <el-col :span="2" class="itemText">处置意见：</el-col>
      <el-col :span="4" class="itemValue red-tip" :title="busItem.disposeOpinionName">{{ busItem.disposeOpinionName }}</el-col>
      <el-col :span="2" class="itemText">检验结果描述：</el-col>
      <el-col :span="4" class="itemValue red-tip" :title="busItem.testResultRemark">{{ busItem.testResultRemark }}</el-col>
    </el-row>
    <el-row>
      <el-col :span="2" class="itemText">借出单号：</el-col>
      <el-col :span="4" class="itemValue">{{ busItem.lendOrderCode }}</el-col>
      <el-col :span="2" class="itemText">订单类型：</el-col>
      <el-col :span="4" class="itemValue">{{ lendOrder.orderTypeName }}</el-col>
      <el-col :span="2" class="itemText">生产线：</el-col>
      <el-col :span="4" class="itemValue">{{ busItem.sortLineName }}</el-col>
      <el-col :span="2" class="itemText">箱号：</el-col>
      <el-col :span="4" class="itemValue">{{ busItem.sortBoxNo }}</el-col>
    </el-row>
    <el-row>
      <el-col :span="2" class="itemText">不可索赔原因：</el-col>
      <el-col :span="4" class="itemValue">{{ lendOrderItem.noRmaReason }}</el-col>
      <el-col :span="2" class="itemText">退回备注：</el-col>
      <el-col :span="4" class="itemValue">{{ lendOrderItem.sendBackRemark }}</el-col>
      <el-col :span="2" class="itemText">索赔复判理由说明：</el-col>
      <el-col :span="4" class="itemValue">{{ lendOrderItem.rmaRejudgeReasonName }}</el-col>
      <el-col :span="2" class="itemText">索赔复判备注：</el-col>
      <el-col :span="2" class="itemValue">{{ lendOrderItem.rmaRejudgeRemark }}</el-col>
      <el-col :span="2">
        <el-button @click="changeShowInfo" type="text">{{ openMaterialInfo ? '隐藏更多' : '展开更多' }}</el-button>
      </el-col>
    </el-row>
    <div v-show="openMaterialInfo">
      <el-row>
        <el-col :span="2" class="itemText">包材编码：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.packMaterialCode }}</el-col>
        <el-col :span="2" class="itemText">可回收包材条码：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.recyclableMaterialSn }}</el-col>
        <el-col :span="2" class="itemText">供应商代码：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.supplierCode }}</el-col>
        <el-col :span="2" class="itemText">供应商名称：</el-col>
        <el-col :span="4" class="itemValue" :title="busItem.supplierName">{{ busItem.supplierName }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">外观是否可维修：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.test1Repairable }}</el-col>
        <el-col :span="2" class="itemText">外观是否可翻新：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.test1Refurbishable }}</el-col>
        <el-col :span="2" class="itemText">区别码1：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.diff1 }}</el-col>
        <el-col :span="2" class="itemText">区别码2：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.diff2 }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">备件名称：</el-col>
        <el-col :span="4" class="itemValue" :title="busItem.materialName">{{ busItem.materialName }}</el-col>
        <el-col :span="2" class="itemText">出厂SN码：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.sortSn }}</el-col>
        <el-col :span="2" class="itemText">资产码：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.assetSn }}</el-col>
        <el-col :span="2" class="itemText">供应商条码：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.supplierSn }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">备件小类CODE：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.subClass }}</el-col>
        <el-col :span="2" class="itemText">备件小类名称：</el-col>
        <el-col :span="4" class="itemValue" :title="busItem.subClassName">{{ busItem.subClassName }}</el-col>
        <el-col :span="2" class="itemText">计划大类CODE：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.planCategory }}</el-col>
        <el-col :span="2" class="itemText">计划大类名称：</el-col>
        <el-col :span="4" class="itemValue" :title="busItem.planCategoryName">{{ busItem.planCategoryName }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">维修商代码：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.test1RepairerCodeName }}</el-col>
        <el-col :span="2" class="itemText">入库库房名称：</el-col>
        <el-col :span="4" class="itemValue" :title="lendOrder.demanderWareHouseName">{{ lendOrder.demanderWareHouseName }}</el-col>
        <el-col :span="2" class="itemText">入库库区名称：</el-col>
        <el-col :span="4" class="itemValue" :title="lendOrder.demanderStockAreaName">{{ lendOrder.demanderStockAreaName }}</el-col>
        <el-col :span="2" class="itemText"></el-col>
        <el-col :span="4" class="itemValue"></el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">出库库房ID：</el-col>
        <el-col :span="4" class="itemValue">{{ lendOrder.providerWareHouse }}</el-col>
        <el-col :span="2" class="itemText">出库库房名称：</el-col>
        <el-col :span="4" class="itemValue" :title="lendOrder.providerWareHouseName">{{ lendOrder.providerWareHouseName }}</el-col>
        <el-col :span="2" class="itemText">出库库区ID：</el-col>
        <el-col :span="4" class="itemValue">{{ lendOrder.providerStockArea }}</el-col>
        <el-col :span="2" class="itemText">出库库区名称：</el-col>
        <el-col :span="4" class="itemValue" :title="lendOrder.providerStockAreaName">{{ lendOrder.providerStockAreaName }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">联想归还单号：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.lenovoCode }}</el-col>
        <el-col :span="2" class="itemText">内部归还单号：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.returnOrderCode }}</el-col>
        <el-col :span="2" class="itemText">捷思目标库区：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.storeTypeName }}</el-col>
        <el-col :span="2" class="itemText">联想目标库区：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.lenovoStoreName }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">分拣时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.sortTime }}</el-col>
        <el-col :span="2" class="itemText">分拣人：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.sortUserName }}</el-col>
        <el-col :span="2" class="itemText">工位：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.sortPosition }}</el-col>
        <el-col :span="2" class="itemText">时效开始时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.agingStartTime }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">外观开始时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.test1InTime }}</el-col>
        <el-col :span="2" class="itemText">外观结束时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.test1OutTime }}</el-col>
        <el-col :span="2" class="itemText">外观检测人：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.test1UserName }}</el-col>
        <el-col :span="2" class="itemText">工位：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.test1Position }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">电性能开始时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.test2InTime }}</el-col>
        <el-col :span="2" class="itemText">电性能结束时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.test2OutTime }}</el-col>
        <el-col :span="2" class="itemText">电性能检测人：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.test2UserName }}</el-col>
        <el-col :span="2" class="itemText">工位：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.test2Position }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">不良复检开始时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.review1InTime }}</el-col>
        <el-col :span="2" class="itemText">不良复检结束时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.review1OutTime }}</el-col>
        <el-col :span="2" class="itemText">不良复检人：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.review1UserName }}</el-col>
        <el-col :span="2" class="itemText">工位：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.review1Position }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">检验确认开始时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.confirmInTime }}</el-col>
        <el-col :span="2" class="itemText">检验确认结束时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.confirmOutTime }}</el-col>
        <el-col :span="2" class="itemText">检验确认人：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.confirmUserName }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">包装开始时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.packInTime }}</el-col>
        <el-col :span="2" class="itemText">包装结束时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.packOutTime }}</el-col>
        <el-col :span="2" class="itemText">包装人：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.packUserName }}</el-col>
        <el-col :span="2" class="itemText">工位：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.packPosition }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">归还开始时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.returnInTime }}</el-col>
        <el-col :span="2" class="itemText">归还结束时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.returnOutTime }}</el-col>
        <el-col :span="2" class="itemText">归还人：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.returnUserName }}</el-col>
        <el-col :span="2" class="itemText">工位：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.returnPosition }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">资料擦除开始时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.erasureInTime }}</el-col>
        <el-col :span="2" class="itemText">资料擦除结束时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.erasureOutTime }}</el-col>
        <el-col :span="2" class="itemText">资料擦除人：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.erasureUserName }}</el-col>
        <el-col :span="2" class="itemText">判库方式：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.judgeStoreTypeName }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">再利用检验开始时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.recycleInTime }}</el-col>
        <el-col :span="2" class="itemText">再利用检验结束时间：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.recycleOutTime }}</el-col>
        <el-col :span="2" class="itemText">再利用检验人：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.recycleUserName }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">来源订单号：</el-col>
        <el-col :span="4" class="itemValue">{{ lendOrderItem.poDoc }}</el-col>
        <el-col :span="2" class="itemText">buffer入库单备注：</el-col>
        <el-col :span="4" class="itemValue">{{ lendOrderItem.bufferRemark }}</el-col>
        <el-col :span="2" class="itemText">检验备注：</el-col>
        <el-col :span="10" class="itemValue">{{ busItem.busItemInfo ? busItem.busItemInfo.testRemark : '' }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">贴纸技服码：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.busItemInfo ? busItem.busItemInfo.stickerCode : '' }}</el-col>
        <el-col :span="2" class="itemText">来货次数：</el-col>
        <el-col :span="4" class="itemValue">{{ busItem.busItemInfo ? busItem.busItemInfo.inLineCnt : '' }}</el-col>
        <el-col :span="2" class="itemText">外观等级：</el-col>
        <el-col :span="4" class="itemValue">{{ busItemInfo.appearanceLevel }}</el-col>
        <el-col :span="2" class="itemText">外观修复位置：</el-col>
        <el-col :span="4" class="itemValue">{{ busItemInfo.test1RepairLocationName }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">键盘外观不良类型：</el-col>
        <el-col :span="4" class="itemValue">{{ busItemInfo.test1NgTypeName }}</el-col>
        <el-col :span="2" class="itemText">亮度值：</el-col>
        <el-col :span="4" class="itemValue">{{ busItemInfo.brightness }}</el-col>
        <el-col :span="2" class="itemText">是否校色：</el-col>
        <el-col :span="4" class="itemValue">{{ busItemInfo.ruleColorId === '3010313' ? '是' : '否' }}</el-col>
        <el-col :span="2" class="itemText">键盘有维修需求：</el-col>
        <el-col :span="4" class="itemValue">{{ busItemInfo.repairDemand ? '是' : '否' }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2" class="itemText">键盘外观符合修复标准：</el-col>
        <el-col :span="4" class="itemValue">{{ busItemInfo.repairStandard ? '是' : '否' }}</el-col>
      </el-row>
      <el-row v-if="station=='viewDetail'">
        <el-col :span="2" class="itemText">外观检验照片：</el-col>
        <el-col :span="4" class="itemValue imageValue">
          <ImageViewerSwitchComponent ref="test1Img" v-bind:image-list="busItem.test1ImageList"/>
        </el-col>
        <el-col :span="2" class="itemText">电性能测照片：</el-col>
        <el-col :span="4" class="itemValue imageValue">
          <ImageViewerSwitchComponent ref="test2Img" v-bind:image-list="busItem.test2ImageList"/>
        </el-col>
        <el-col :span="2" class="itemText">检验日志：</el-col>
        <el-col :span="4" class="itemValue">
          <el-link v-if="busItem.test2File!=null" :href="busItem.test2File.url" style="color: goldenrod;line-height: 20px">{{ busItem.test2File.fileName }}</el-link>
        </el-col>
        <el-col :span="2" class="itemText">再利用检验日志：</el-col>
        <el-col :span="4" class="itemValue">
          <el-link v-if="busItem.recyclableLogFile!=null" :href="busItem.recyclableLogFile.url" style="color: goldenrod;line-height: 20px">{{ busItem.recyclableLogFile.fileName }}</el-link>
        </el-col>
      </el-row>
    </div>
    <el-dialog title="已分拣未完成物品明细" :visible.sync="notFinishVisible" :close-on-click-modal="false" width="95%">
      <el-table :data="notFinishItemList" border stripe v-loading="notFinishLoading" height="500">
        <el-table-column label="#" type="index" align="center" width="50px"></el-table-column>
        <el-table-column align="center" prop="assetSn" label="资产码" width="200px"></el-table-column>
        <el-table-column align="center" prop="materialCode" label="备件编码"></el-table-column>
        <el-table-column align="center" prop="currentStationName" label="当前工站"></el-table-column>
        <el-table-column align="center" prop="currentStationUserName" label="当前工站操作人员"></el-table-column>
        <el-table-column align="center" prop="currentStationStatusName" label="当前工站工作状态"></el-table-column>
        <el-table-column align="center" prop="nextStationName" label="下一工站"></el-table-column>
        <el-table-column align="center" prop="sortBoxNo" label="分拣箱号"></el-table-column>
        <el-table-column align="center" prop="sortLineName" label="生产线"></el-table-column>
        <el-table-column align="center" prop="sortTestTypeName" label="检验类型"></el-table-column>
        <el-table-column align="center" prop="sortUserName" label="分拣人员"></el-table-column>
        <el-table-column align="center" prop="test1UserName" label="外观检验人员"></el-table-column>
        <el-table-column align="center" prop="test2UserName" label="电性能检验人员"></el-table-column>
        <el-table-column align="center" prop="packUserName" label="包装人员"></el-table-column>
      </el-table>
    </el-dialog>
  </el-card>
</template>

<script>
import ImageViewerSwitchComponent from "@/components/ImageViewerSwitchComponent";

export default {
  name: "MaterialInfo",
  components: {ImageViewerSwitchComponent},
  props: {
    busItem: Object,
    lendOrder: Object,
    lendOrderItem: Object,
    station: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showTest1Image: false,
      showTest2Image: false,
      order: {},
      openMaterialInfo: this.station == 'viewDetail' || this.station == 'dataErasure',
      notFinishVisible: false,
      notFinishItemList: [],
      notFinishLoading: false
    }
  },
  computed: {
    test1TroubleCodeText: function () {
      let text = (this.busItem.test1TroubleCode || '') + ' ' + (this.busItem.test1TroubleCodeName || '');
      return text.trim();
    },
    test2TroubleCodeText: function () {
      let text = (this.busItem.test2TroubleCode || '') + ' ' + (this.busItem.test2TroubleCodeName || '');
      return text.trim();
    },
    test1ResultText: function () {
      return this.getResultText(this.busItem.test1Result)
    },
    test2ResultText: function () {
      return this.getResultText(this.busItem.test2Result)
    },
    testTypeName() {
      let name1 = this.busItem?.sortTestTypeName
      let name2 = this.busItem?.busItemInfo?.testTypeName

      if (name2 && name1 != name2) {
        name1 = name1 + '(' + name2 + ')'
      }
      return name1
    },
    busItemInfo() {
      return this.busItem.busItemInfo || {}
    }
  },
  methods: {
    changeShowInfo() {
      this.openMaterialInfo = !this.openMaterialInfo
    },
    getResultText(val) {
      if (val === true) {
        return '正常';
      } else if (val === false) {
        return '不良';
      } else {
        return '';
      }
    },
    closeImage() {
      if (this.$refs.test1Img) {
        this.$refs.test1Img.closeImage()
      }
      if (this.$refs.test2Img) {
        this.$refs.test2Img.closeImage()
      }
    },
    showNotFinish() {
      this.notFinishVisible = true;

      this.notFinishLoading = true;
      this.$axios.post('busItem/notFinishItem', {
        lendOrderId: this.busItem.lendOrderId,
        currentStation: this.station
      }).then(response => {
        this.notFinishLoading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        this.notFinishItemList = res.data || [];
      }, error => {
        this.notFinishLoading = false;
        return this.$message.error('查询未完成明细数据失败，' + error.message);
      })

    }
  }
}
</script>

<style scoped>
.el-row {
  line-height: 32px;
}

.order-info-card {
  font-size: 12px;
  color: #606266;
  margin-bottom: 22px;
}

.order-info-card .el-row {
  display: flex;
}

.itemText {
  text-align: right;
}

.itemValue {
  color: goldenrod;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.itemValue.red-tip {
  color: red;
}

.imageValue {
  overflow: auto;
}

.urgent-tip {
  color: red;
  font-size: 18px;
}

.no-border {
  border: none
}
</style>