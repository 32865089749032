<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      <el-row style="padding-bottom: 10px;line-height: 40px">
        <el-col :span="24">
          <el-input ref="assetSn" v-model="formData.assetSn" placeholder="请输入或扫资产码" @keyup.enter.native="search" style="width: 250px"></el-input>
          <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="search">查询</el-button>
          <el-button type="primary" style="margin-left: 100px" @click="submit" :loading="commitLoading">擦除完成</el-button>
          <MessageComponent ref="searchMessage"/>
        </el-col>
      </el-row>
      <MaterialInfo :busItem="busItem" :lendOrder="lendOrder" :lend-order-item="lendOrderItem" station="dataErasure"/>
    </el-card>
  </div>
</template>

<script>
import MaterialInfo from "@/views/material/MaterialInfo";
import MessageComponent from "@/components/MessageComponent";

export default {
  name: "DataErasure",
  components: {MaterialInfo, MessageComponent},
  data() {
    return {
      formData: {
        assetSn: '',
        currentPosition: ''
      },
      busItem: {},
      lendOrder: {},
      lendOrderItem: {},
      loading: false,
      commitLoading: false,
      canErasure: false
    }
  },
  methods: {
    clearData() {
      this.formData.assetSn = ''
      this.busItem = {}
      this.lendOrder = {}
      this.lendOrderItem = {}
      this.canErasure = false
    },
    async submit() {
      let currentPosition = localStorage.getItem('current_position') || '';
      if (!currentPosition) {
        return this.$refs.searchMessage.showError('未设置工位，请重新登录系统并设置工位信息');
      } else {
        this.formData.currentPosition = currentPosition;
      }
      if (!this.formData.assetSn) {
        return this.$refs.searchMessage.showError('请输入或扫资产码');
      }
      if (!this.busItem.id) {
        return this.$refs.searchMessage.showError('请先查询资产信息');
      }
      if (!this.canErasure) {
        return this.$refs.searchMessage.showError('该资产码不符合资料擦除作业条件');
      }

      this.submitLoading = true;
      const {data: res} = await this.$axios.post('busItem/saveErasureResult', this.formData);
      this.submitLoading = false;
      if (res.code !== 0) {
        return this.$refs.searchMessage.showError(res.message);
      }
      this.$refs.searchMessage.showSuccess('资料擦除作业处理成功，下一工站为：' + res.data.nextStationName);

      //清掉之前的资产码
      this.clearData();
      this.$refs.assetSn.focus();
    },
    async search() {
      this.canErasure = false;
      this.$refs.searchMessage.clear();
      if (!this.formData.assetSn) {
        this.$refs.searchMessage.showError('请输入或扫资产码');
        return;
      }
      this.formData.assetSn = this.formData.assetSn.trim();

      this.loading = true;
      const {data: res} = await this.$axios.get('/busItem/queryInfoForErasure', {
        params: {assetSn: this.formData.assetSn}
      });
      this.loading = false;

      if (res.code !== 0) {
        this.$refs.searchMessage.showError(res.message);
        return
      }

      if (!res.data.busItem) {
        this.busItem = {};
        this.lendOrder = {};
        this.lendOrderItem = {};
        return this.$refs.searchMessage.showError('资产码不正确');
      }

      this.busItem = res.data.busItem || {};
      this.lendOrder = res.data.lendOrder || {};
      this.lendOrderItem = res.data.lendOrderItem || {};

      if (this.busItem.currentStation != 'dataErasure') {
        var msg = '该物品当前工站不是资料擦除工站';
        if (this.busItem.currentStationStatus == 'finish' && this.busItem.nextStationName) {
          msg += '，工站为：' + this.busItem.nextStationName;
        } else if (this.busItem.currentStationName) {
          msg += '，工站为：' + this.busItem.currentStationName;
        }
        return this.$refs.searchMessage.showError(msg);
      } else if (this.busItem.currentStation == 'dataErasure' && this.busItem.currentStationStatus == 'finish') {
        return this.$refs.searchMessage.showError('该物品已经完成资料擦除作业，不能重复作业');
      }

      this.canErasure = true;

    }
  }
}
</script>

<style scoped>
.bad-tip {
  font-weight: bold;
  font-size: 24px;
  color: red;
}

.form-item {
  width: 400px;
}

.options {
  padding-right: 10px;
  width: 200px;
}
</style>
