<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      <el-row style="padding-bottom: 10px">
        <el-col :span="24" style="text-align: left;line-height: 40px">
          <el-input ref="assetSn" v-model="formData.assetSn" placeholder="请输入或扫资产码" @keyup.enter.native="search" style="width: 300px"></el-input>
          <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="search">查询</el-button>
          <MessageComponent ref="searchMessage"/>
        </el-col>
      </el-row>
      <MaterialInfo :bus-item="busItem" :lend-order="lendOrder" :lend-order-item="lendOrderItem" station="viewDetail" ref="materialInfo"/>
    </el-card>
  </div>
</template>

<script>
import MaterialInfo from "@/views/material/MaterialInfo";
import MessageComponent from "@/components/MessageComponent";

export default {
  name: "MaterialDetail",
  components: {MaterialInfo, MessageComponent},
  data() {
    return {
      formData: {
        assetSn: ''
      },
      busItem: {},
      lendOrder: {},
      lendOrderItem: {},
      loading: false,
    }
  },
  methods: {
    async search() {
      this.$refs.assetSn.select();
      this.$refs.searchMessage.clear();
      this.$refs.materialInfo.closeImage();
      if (!this.formData.assetSn) {
        this.$refs.searchMessage.showError('请输入或扫资产码');
        return;
      }
      this.formData.assetSn = this.formData.assetSn.trim();

      this.loading = true;
      const {data: res} = await this.$axios.get('/busItem/queryInfoForDetail', {
        params: {assetSn: this.formData.assetSn}
      });
      this.loading = false;

      if (res.code !== 0) {
        this.$refs.searchMessage.showError(res.message);
        return
      }

      if (!res.data.busItem) {
        return this.$refs.searchMessage.showError('资产码不正确');
      }

      this.busItem = res.data.busItem || {};
      this.lendOrder = res.data.lendOrder || {};
      this.lendOrderItem = res.data.lendOrderItem || {};

    },
  }
}
</script>

<style scoped>

</style>
