<template>
  <div style="display: inline-block;">
    <el-switch v-model="showImage" v-if="imageList.length >0" active-color="#13ce66" inactive-color="#cccccc" style="margin-left: 5px"/>
    <ImageViewerComponent v-if="imageList.length >0&&showImage" v-bind:image-list="imageList"/>
  </div>
</template>

<script>
import ImageViewerComponent from "@/components/ImageViewerComponent";

export default {
  name: "ImageViewerSwitchComponent",
  components: {ImageViewerComponent},
  data() {
    return {
      showImage: false,
    }
  },
  props: {
    imageList: {
      type: Array, default: function () {
        return []
      }
    }
  },
  methods: {
    closeImage() {
      this.showImage = false
    }
  }
}
</script>

<style scoped>

</style>