<template>
  <el-card class="box-card" shadow="never">
    <el-row style="padding-bottom: 10px">
      <el-col :span="24" style="text-align: left;line-height: 40px">
        <el-input ref="assetSn" v-model="formData.assetSn" placeholder="请输入或扫资产码" @keyup.enter.native="search" style="width: 300px"></el-input>
        <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="search">查询</el-button>
        <MessageComponent ref="searchMessage"/>
      </el-col>
    </el-row>
    <MaterialInfo :busItem="busItem" :lendOrder="lendOrder" :lend-order-item="lendOrderItem" station="reviewTest"/>
    <el-form :model="formData" label-width="130px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="捷思目标库区：">
            <el-select v-model="formData.storeType" class="form-item" placeholder="目标库区" filterable>
              <el-option v-for="item in storeTypeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="联想目标库区：" v-if="busItem.lenovoJudgeStore">
            <el-select v-model="formData.lenovoStore" filterable placeholder="请选择联想目标库区" class="form-item">
              <el-option
                  v-for="item in lenovoStoreList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
            <span class="tip" style="line-height: 20px">{{ loadLenovoStoreTip }}</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5" style="text-align: right">
          <el-button type="primary" @click="submit" :loading="loading">检验确认结果提交</el-button>
        </el-col>
        <el-col :span="19">
          <MessageComponent ref="commitMessage"/>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
import MessageComponent from "@/components/MessageComponent";
import MaterialInfo from "@/views/material/MaterialInfo";

export default {
  name: "ManagerConfirm",
  components: {MaterialInfo, MessageComponent},
  data() {
    return {
      formData: {
        assetSn: '',
        storeType: '',
        lenovoStore: '',
      },
      lenovoStoreList: [],
      canCheck: false,
      busItem: {},
      lendOrder: {},
      lendOrderItem: {},
      loading: false,
      loadLenovoStoreTip: '',
    }
  },
  computed: {
    storeTypeList() {
      return this.$store.getters.getDictList('storeType') || [];
    },
  },
  methods: {
    resetValues() {
      this.formData.storeType = ''
      this.formData.lenovoStore = ''
      this.lenovoStoreList = []
      this.canCheck = false
      this.busItem = {}
      this.lendOrder = {}
      this.lendOrderItem = {}
      this.loadLenovoStoreTip = ''
    },
    search() {
      this.resetValues()
      this.$refs.searchMessage.clear();
      this.$refs.commitMessage.clear();
      if (!this.formData.assetSn) {
        this.$refs.searchMessage.showError('请输入或扫资产码');
        return;
      }
      this.formData.assetSn = this.formData.assetSn.trim();

      this.loading = true;
      this.$axios.get('busItem/queryInfoForManagerConfirm', {
        params: {assetSn: this.formData.assetSn}
      }).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.searchMessage.showError(res.message);
        }

        if (!res.data.busItem) {
          return this.$refs.searchMessage.showError('资产码不正确');
        }

        this.busItem = res.data.busItem || {};
        this.lendOrder = res.data.lendOrder || {};
        this.lendOrderItem = res.data.lendOrderItem || {};

        if (this.busItem.currentStation != '20') {
          let msg = '该物品当前工站不是检验确认工站';
          if (this.busItem.currentStationStatus == 'finish' && this.busItem.nextStationName) {
            msg += '，工站为：' + this.busItem.nextStationName;
          } else if (this.busItem.currentStationName) {
            msg += '，工站为：' + this.busItem.currentStationName;
          }
          return this.$refs.searchMessage.showError(msg);
        } else if (this.busItem.currentStationStatus == 'finish') {
          return this.$refs.searchMessage.showError('当前工站已完成，下一工站为：' + this.busItem.nextStationName);
        }

        this.formData.lenovoStore = this.busItem.lenovoStore
        this.formData.storeType = this.busItem.storeType

        if (this.busItem.lenovoJudgeStore) {
          this.loadLenovoStoreTip = '联想库区加载中...'
          this.$axios.post('/busItem/getLenovoStore', this.busItem.assetSn).then(response => {
            this.loadLenovoStoreTip = ''
            let res = response.data;
            if (res.code !== 0) {
              this.loadLenovoStoreTip = res.message;
              this.$message.error(this.loadLenovoStoreTip);
            }
            this.lenovoStoreList = res.data || [];
          }, error => {
            this.loadLenovoStoreTip = '调研联想判保接口失败，' + error.message;
            this.$message.error(this.loadLenovoStoreTip);
          });
        }

        this.canCheck = true;
      }, error => {
        this.loading = false;
        return this.$refs.searchMessage.showError(error.message);
      });
    },
    submit() {
      this.$refs.commitMessage.clear();

      if (!this.formData.assetSn) {
        return this.$refs.commitMessage.showError('请输入或扫资产码');
      }
      if (!this.canCheck) {
        return this.$refs.commitMessage.showError('该资产码不符合检验确认操作的条件');
      }

      if (!this.formData.storeType) {
        return this.$refs.commitMessage.showError('捷思目标库区不能为空');
      }
      if (this.busItem.lenovoJudgeStore && !this.formData.lenovoStore) {
        return this.$refs.commitMessage.showError('联想目标库区不能为空');
      }

      this.loading = true
      this.$axios.post('busItem/saveConfirmResult', this.formData).then(response => {
        this.loading = false
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.commitMessage.showError(res.message);
        }
        let msg = '提交成功，下一工站为：' + res.data.nextStationName;
        this.$refs.commitMessage.showSuccess(msg);
        this.$refs.searchMessage.showSuccess(msg);
        this.resetValues();
        this.formData.assetSn = ''
        this.$refs.assetSn.focus();
      }, error => {
        this.loading = false
        this.$message.error(error.message);
      });


    }


  }
}
</script>

<style scoped>
.form-item {
  width: 320px;
}
</style>