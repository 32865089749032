<template>
  <div>
    <el-table :data="itemList" border stripe v-loading="loading" height="500">
      <el-table-column align="center" label="上传状态" prop="statusName" width="70"></el-table-column>
      <el-table-column align="center" prop="assetSn" label="资产码" width="210px"></el-table-column>
      <el-table-column align="center" prop="busItem.storeTypeName" label="捷思目标库区" width="160px"></el-table-column>
      <el-table-column align="center" prop="busItem.lenovoStoreType" label="联想目标库区" width="160px"></el-table-column>
      <el-table-column align="center" prop="busItem.materialCode" label="备件编码" width="100px"></el-table-column>
      <el-table-column align="center" prop="busItem.materialName" label="备件名称" width="300px"></el-table-column>
      <el-table-column align="center" prop="busItem.lendOrderCode" label="借出单号" width="200px"></el-table-column>
      <el-table-column align="center" prop="busItem.asnNo" label="预入库单号" width="180px"></el-table-column>
      <el-table-column align="center" prop="busItem.granularityName" label="备件粒度" width="80px"></el-table-column>
      <el-table-column align="center" prop="busItem.supplierCode" label="供应商代码" width="100px"></el-table-column>
      <el-table-column align="center" prop="busItem.supplierName" label="供应商名称" width="240px"></el-table-column>
      <el-table-column align="center" prop="busItem.materialSourceName" label="备件来源" width="200px"></el-table-column>
      <el-table-column align="center" prop="busItem.supplierSn" label="供应商条码" width="200px"></el-table-column>
      <el-table-column align="center" prop="busItem.sortLineName" label="生产线" width="100px"></el-table-column>
      <el-table-column align="center" prop="busItem.subClass" label="备件小类CODE" width="110px"></el-table-column>
      <el-table-column align="center" prop="busItem.subClassName" label="备件小类名称" width="140px"></el-table-column>
      <el-table-column align="center" prop="busItem.planCategory" label="计划大类code" width="140px"></el-table-column>
      <el-table-column align="center" prop="busItem.planCategoryName" label="计划大类名称" width="140px"></el-table-column>
      <el-table-column align="center" prop="busItem.materialMarkName" label="备件标识" width="100px"></el-table-column>
      <el-table-column align="center" prop="busItem.sortTestTypeName" label="检验类型" width="100px"></el-table-column>
      <el-table-column align="center" prop="testRecord.appearanceResult" label="外观检验结果" :formatter="testFormatter" width="100px"></el-table-column>
      <el-table-column align="center" prop="testRecord.electricityResult" label="电性能检验结果" :formatter="testFormatter" width="110px"></el-table-column>
      <el-table-column align="center" prop="testRecord.testResultName" label="检验结果" width="240px"></el-table-column>
      <el-table-column align="center" prop="testRecord.disposeOpinionName" label="处理意见" width="140px"></el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  name: "LendOrderBusItem",
  data() {
    return {
      itemList: [],
      loading: false
    }
  },
  methods: {
    async query(code) {
      this.loading = true;
      const {data: res} = await this.$axios.get('returnOrder/getOrderItems?code=' + code);
      this.loading = false;
      if (res.code !== 0) {
        this.$message.error(res.message)
        return
      }
      this.itemList = res.data;

    },
    isFormatter(row, column, cellValue) {
      if (cellValue == true) {
        return '是';
      }
      if (cellValue == false) {
        return '否';
      }
      if (cellValue == null || cellValue == '') {
        return '';
      }
    },
    testFormatter(row, column, cellValue) {
      if (cellValue) {
        return '正常';
      }
      if (cellValue == false) {
        return '不良';
      }
    }
  }
}
</script>

<style scoped>

</style>