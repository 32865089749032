<template>
  <div style="padding:0 10px;">
    <el-table
        ref="table"
        :data="itemList"
        border
        stripe
        v-loading="loading"
        max-height="507"
        @selection-change="selectionChange"
    >
      <el-table-column type="selection" align="center" width="50"/>
      <el-table-column align="center" prop="materialCode" label="备件编码" width="150"></el-table-column>
      <el-table-column align="center" prop="materialCnt" label="数量" width="120"></el-table-column>
      <el-table-column align="left" prop="planCategoryName" label="计划大类" width="150"></el-table-column>
      <el-table-column align="left" prop="materialName" label="备件名称"></el-table-column>
    </el-table>
    <div style="margin-top: 30px;">
      <el-row>
        <el-col :span="5" class="right">备件总数量：</el-col>
        <el-col :span="3">{{ totalCnt }}</el-col>
        <el-col :span="3" class="right">已选数量：</el-col>
        <el-col :span="4" style="color: red">{{ selectCnt }}</el-col>
      </el-row>
      <el-row style="padding:20px 0">
        <el-col :span="5" class="right">
          <el-button type="primary" @click="submit" :loading="bntLoading">拆单</el-button>
        </el-col>
        <el-col :span="19">
          <MessageComponent ref="commitMessage"/>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import MessageComponent from "@/components/MessageComponent.vue";

export default {
  name: "SplitReturnOrder",
  components: {MessageComponent},
  data() {
    return {
      returnOrderCode: '',
      itemList: [],
      loading: false,
      bntLoading: false,
      selectionList: []
    }
  },
  computed: {
    totalCnt() {
      let cnt = 0
      this.itemList.forEach(item => cnt += item.materialCnt)
      return cnt
    },
    selectCnt() {
      let cnt = 0
      this.selectionList.forEach(item => cnt += item.materialCnt)
      return cnt
    }
  },
  methods: {
    async query(code) {
      this.$refs.commitMessage.clear()
      this.returnOrderCode = code
      await this.reload()
    },
    async reload() {
      this.loading = true;
      this.itemList = []
      this.selectionList = []
      const {data: res} = await this.$axios.get('returnOrder/queryMaterialAndCount?code=' + this.returnOrderCode);
      this.loading = false;
      if (res.code !== 0) {
        this.$message.error(res.message)
        return
      }
      this.itemList = res.data;
    },
    selectionChange(selectionList) {
      this.selectionList = selectionList
    },
    submit() {
      if (this.totalCnt == 0) {
        return this.$refs.commitMessage.showError('备件总数量为0');
      }
      if (this.selectCnt == 0) {
        return this.$refs.commitMessage.showError('请选择要拆单的备件');
      }
      if (this.totalCnt === this.selectCnt) {
        return this.$refs.commitMessage.showError('选择的备件数量和总数量相同，所以无需拆单');
      }

      let materialCodeList = []
      this.selectionList.forEach(item => materialCodeList.push(item.materialCode))

      this.bntLoading = true
      this.$axios.post('returnOrder/splitReturnOrder', {
        materialCodeList: materialCodeList,
        returnOrderCode: this.returnOrderCode
      }).then(response => {
        this.bntLoading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.commitMessage.showError(res.message);
        }

        this.$refs.commitMessage.showSuccess(`拆单成功，产生的归还单号为【${res.data}】`);
        this.reload()
      }, error => {
        this.bntLoading = false;
        return this.$refs.commitMessage.showError(error.message);
      });

    }
  }
}
</script>

<style scoped>
.right {
  text-align: right;
}

.el-row {
  padding: 10px
}
</style>