<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      <el-row style="padding-bottom: 10px">
        <el-col :span="24" style="text-align: left;line-height: 40px">
          <el-input ref="assetSn" v-model="assetSn" placeholder="扫资产码添加物品" style="width: 350px" @change="onAddItem"></el-input>
          <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="onAddItem">确定</el-button>
          <MessageComponent :font-large="60" :font-mini="100" ref="addMessage"/>
        </el-col>
      </el-row>
      <el-table :data="orderList" stripe border height="600">
        <el-table-column label="内部归还单号" prop="code" align="center" width="180px">
          <template slot-scope="scope">
            <el-link type="primary" @click="showItemDialog(scope.row)" style="font-size: 12px;font-weight: normal">{{ scope.row.code }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="运作产线" prop="chargeLineName" align="center"></el-table-column>
        <el-table-column label="检验类型" prop="testTypeName" align="center"></el-table-column>
        <el-table-column label="捷思目标库区" align="center">
          <template slot-scope="scope">{{ scope.row.intercept ? scope.row.storeTypeName + ' --> ' + scope.row.interceptStoreName : scope.row.storeTypeName }}</template>
        </el-table-column>
        <el-table-column label="联想目标库区" prop="lenovoStoreName" align="center"></el-table-column>
        <el-table-column label="状态" prop="statusName" align="center" width="90px"></el-table-column>
        <el-table-column label="物品数量" prop="itemCnt" align="center" width="70px"></el-table-column>
        <el-table-column label="优先库" prop="priority" align="center" width="70px" :formatter="isFormatter"></el-table-column>
        <el-table-column label="加急" prop="urgent" align="center" width="70px" :formatter="isFormatter"></el-table-column>
        <el-table-column label="计划大类" prop="planCategoryGroup" align="center" width="70px" :formatter="planCategoryGroupFormatter"></el-table-column>
        <el-table-column label="借出单编号" prop="lendOrderCode" align="center" width="200px"></el-table-column>
        <el-table-column label="备件编号" prop="materialCode" align="center" width="110px"></el-table-column>
        <el-table-column label="备件总数量" prop="materialCnt" align="center" width="100px"></el-table-column>
        <el-table-column label="已收录数量" prop="collectedCnt" align="center" width="100px"></el-table-column>
      </el-table>

    </el-card>

    <el-dialog title="归还单明细" :visible.sync="dialogVisible" :close-on-click-modal="false" width="1200px">
      <ReturnOrderItem ref="orderItemComponent"/>
    </el-dialog>
  </div>
</template>

<script>
import MessageComponent from "@/components/MessageComponent";
import ReturnOrderItem from "@/views/returnOrder/ReturnOrderItem";

export default {
  name: "ReturnOrder",
  components: {MessageComponent, ReturnOrderItem},
  props: {
    menuType: {type: String, required: true}
  },
  created() {
    this.getCreatedOrders();
  },
  data() {
    return {
      assetSn: '',
      returnOrder: {
        code: '',
        warehouseName: '',
        storageSectionName: '',
      },
      orderList: [],
      dialogVisible: false,
      loading: false,
      tipPopperVisible: false,
      popperClass: 'return-tip-popper',
      tipPopperContent: '无操作信息'
    }
  },
  methods: {
    hidePopperTip() {
      this.tipPopperVisible = false
    },
    async getCreatedOrders() {
      let url = 'returnOrder/getCreatedOrders'
      if (this.menuType == 'ng') {//不良品归还
        url = 'returnOrder/getCreatedNgOrders'
      }
      const {data: res} = await this.$axios.get(url);
      if (res.code !== 0) {
        this.$message.error(res.message)
        return
      }

      this.orderList = res.data;
    },
    showItemDialog(row) {
      if (!row) {
        return;
      }
      this.dialogVisible = true;
      setTimeout(() => {
        this.$refs.orderItemComponent.query(row.code);
      }, 200);
    },
    onAddItem() {
      this.$refs.addMessage.clear();
      this.$refs.assetSn.select();
      this.tipPopperContent = '无操作信息'
      this.popperClass = 'return-tip-popper'
      if (this.loading) {
        return;
      }

      let currentPosition = localStorage.getItem('current_position') || '';
      if (!currentPosition) {
        return this.$refs.addMessage.showError('未设置工位，请重新登录系统并设置工位信息');
      }

      if (this.assetSn.length < 8) {
        return;
      }

      this.loading = true;
      let url = 'returnOrder/addIntoReturnOrder'
      if (this.menuType == 'ng') {//不良品归还
        url = 'returnOrder/addIntoNgReturnOrder'
      }
      this.$axios.post(url, {
        assetSn: this.assetSn,
        currentPosition: currentPosition
      }).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          this.popperClass = 'return-tip-popper'
          this.tipPopperContent = res.message
          return this.$refs.addMessage.showError(res.message);
        } else {
          this.popperClass = 'return-tip-popper-success'
          let returnOrder = res.data;
          this.tipPopperContent = "已添加到【" + returnOrder.code + (returnOrder.planCategoryGroup == 'A' ? ', 主板 ' : '') + ", " + returnOrder.storeTypeName + "】，请继续扫码";
          this.$refs.addMessage.showSuccess(this.tipPopperContent);
          this.assetSn = '';
          this.$refs.assetSn.focus();

          this.getCreatedOrders();
          return
        }
      })

    },
    isFormatter(row, column, cellValue) {
      if (cellValue == true) {
        return '是';
      }
      if (cellValue == false) {
        return '否';
      }
      if (cellValue == null || cellValue == '') {
        return '';
      }
    },
    planCategoryGroupFormatter(row, column, cellValue) {
      if (cellValue == 'A') {
        return '主板';
      }
      return cellValue;
    },

  }
}
</script>

<style scoped>
.el-table {
  font-size: 12px;
}
</style>
