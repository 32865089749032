<template>
  <el-card class="box-card" shadow="never">
    <el-row style="padding-bottom: 10px">
      <el-col :span="24" style="text-align: left;line-height: 40px">
        <el-input ref="assetSn" v-model="formData.assetSn" placeholder="请输入或扫资产码" @keyup.enter.native="search" style="width: 300px"></el-input>
        <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="search">查询</el-button>
        <MessageComponent ref="searchMessage"/>
      </el-col>
    </el-row>
    <MaterialInfo :busItem="busItem" :lendOrder="lendOrder" :lend-order-item="lendOrderItem" station="reviewTest"/>
    <el-form :model="formData" label-width="130px">
      <el-row>
        <el-col :span="8">
          <el-form-item label="外观检验结果：">
            <el-radio-group v-model="formData.test1Result" @change="calcResult">
              <el-radio :label="true">正常</el-radio>
              <el-radio :label="false">不良</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="外观不良代码：" v-if="formData.test1Result===false">
            <el-select v-model="formData.test1TroubleCode" filterable placeholder="请选择外观不良代码" class="form-item">
              <el-option
                  v-for="item in troubleList"
                  :key="item.code"
                  :label="item.code+' '+item.name"
                  :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="外观不良原因：" v-if="formData.test1Result===false">
            <el-input v-model="formData.test1TroubleReason" type="textarea" :rows="2" class="form-item"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="电性能检验结果：">
            <el-radio-group v-model="formData.test2Result" @change="calcResult">
              <el-radio :label="true">正常</el-radio>
              <el-radio :label="false">不良</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="电性能不良代码：" v-if="formData.test2Result===false">
            <el-select v-model="formData.test2TroubleCode" filterable placeholder="请选择电性能不良代码" class="form-item">
              <el-option
                  v-for="item in troubleList"
                  :key="item.code"
                  :label="item.code+' '+item.name"
                  :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="电性能不良原因：" v-if="formData.test2Result===false">
            <el-input v-model="formData.test2TroubleReason" type="textarea" :rows="2" class="form-item"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="8">
          <el-form-item label="是否9成新：">
            <el-radio-group v-model="formData.busItemInfo.nineNew">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="小于2000小时：">
            <el-radio-group v-model="formData.busItemInfo.lessHour">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="检验结果：">
            <el-select v-model="formData.testResult" class="form-item">
              <el-option
                  v-for="item in testResultList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="处置意见：">
            <el-select v-model="formData.disposeOpinion" class="form-item">
              <el-option
                  v-for="item in disposeOpinionList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="检验结果描述：">
            <el-input v-model="formData.testResultRemark" type="textarea" :rows="2" class="form-item"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="外观检验照片：">
            <PhotographComponent @setImgIds="setTest1ImgIds" ref="test1ImageUpload"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="电性能检验照片：">
            <PhotographComponent @setImgIds="setTest2ImgIds" ref="test2ImageUpload"/>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="!isHardDisk">
          <el-form-item label="检验日志：">
            <el-link type="primary" :underline="false" href="GoldenKeyExe://">打开金钥匙程序</el-link>
            <div style="width: 400px">
              <DocUploadComponent :data="{assetSn:this.formData.assetSn}" url="upload/test2JsonFile" @docChange="setDocIds" ref="docUpload"/>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5" style="text-align: right">
          <el-button type="primary" @click="submit" :loading="loading">检验确认结果提交</el-button>
        </el-col>
        <el-col :span="19">
          <MessageComponent ref="commitMessage"/>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
import MessageComponent from "@/components/MessageComponent";
import MaterialInfo from "@/views/material/MaterialInfo";
import DocUploadComponent from "@/components/DocUploadComponent.vue";
import PhotographComponent from "@/components/PhotographComponent.vue";

export default {
  name: "RecyclableTest",
  components: {PhotographComponent, DocUploadComponent, MaterialInfo, MessageComponent},
  data() {
    return {
      formData: {
        assetSn: '',
        test1Result: undefined,
        test1TroubleCode: '',
        test1TroubleReason: '',
        test2Result: undefined,
        test2TroubleCode: '',
        test2TroubleReason: '',
        testResult: '',
        disposeOpinion: '',
        testResultRemark: '',
        busItemInfo: {
          nineNew: undefined,
          lessHour: undefined,
          recycleFileId: '',
        }
      },
      canCheck: false,
      busItem: {},
      lendOrder: {},
      lendOrderItem: {},
      loading: false,
      troubleList: [],
    }
  },
  computed: {
    disposeOpinionList() {
      return this.$store.getters.getDictList('disposeOpinion') || [];
    },
    testResultList() {
      return this.$store.getters.getDictList('testResult') || [];
    },
    isHardDisk() {
      /*
      005 CPU
      054 内存
      040 硬盘
      171 固态硬盘
      SM06 移动硬盘
       */
      return ['040', '171', 'SM06'].includes(this.busItem.planCategory)
    }
  },
  methods: {
    resetValues() {
      this.formData.busItemInfo.nineNew = undefined
      this.formData.busItemInfo.lessHour = undefined
      this.formData.busItemInfo.recycleFileId = ''
      this.formData.test1Result = undefined
      this.formData.test1TroubleCode = ''
      this.formData.test1TroubleReason = ''
      this.formData.test2Result = undefined
      this.formData.test2TroubleCode = ''
      this.formData.test2TroubleReason = ''
      this.formData.testResult = ''
      this.formData.disposeOpinion = ''
      this.formData.testResultRemark = ''
      this.formData.test1ImageIds = []
      this.formData.test2ImageIds = []
      this.canCheck = false
      this.lendOrder = {}
      this.lendOrderItem = {}
      this.troubleList = [];

      this.$refs.test1ImageUpload.onClear();
      this.$refs.test2ImageUpload.onClear();
      if (this.$refs.docUpload) {
        this.$refs.docUpload.onClear();
      }

    },
    search() {
      this.resetValues()
      this.$refs.searchMessage.clear();
      this.$refs.commitMessage.clear();
      if (!this.formData.assetSn) {
        this.$refs.searchMessage.showError('请输入或扫资产码');
        return;
      }
      this.formData.assetSn = this.formData.assetSn.trim();

      this.loading = true;
      this.$axios.get('busItem/queryInfoForRecyclable', {
        params: {assetSn: this.formData.assetSn}
      }).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.searchMessage.showError(res.message);
        }

        if (!res.data.busItem) {
          return this.$refs.searchMessage.showError('资产码不正确');
        }

        this.busItem = res.data.busItem || {};
        this.lendOrder = res.data.lendOrder || {};
        this.lendOrderItem = res.data.lendOrderItem || {};
        this.formData.test1Result = this.busItem.test1Result;
        this.formData.test1TroubleReason = this.busItem.test1TroubleReason
        this.formData.test1TroubleCode = this.busItem.test1TroubleCode
        this.formData.testResult = this.busItem.testResult
        this.formData.testResultRemark = this.busItem.testResultRemark;
        this.formData.disposeOpinion = this.busItem.disposeOpinion
        this.troubleList = res.data.troubleList || [];

        if (this.busItem.currentStation != 'recycle') {
          let msg = '该物品当前工站不是再利用检验工站';
          if (this.busItem.currentStationStatus == 'finish' && this.busItem.nextStationName) {
            msg += '，工站为：' + this.busItem.nextStationName;
          } else if (this.busItem.currentStationName) {
            msg += '，工站为：' + this.busItem.currentStationName;
          }
          return this.$refs.searchMessage.showError(msg);
        } else if (this.busItem.currentStationStatus == 'finish') {
          return this.$refs.searchMessage.showError('当前工站已完成，下一工站为：' + this.busItem.nextStationName);
        }

        this.canCheck = true;
      }, error => {
        this.loading = false;
        return this.$refs.searchMessage.showError(error.message);
      });
    },
    submit() {
      this.$refs.commitMessage.clear();

      if (!this.formData.assetSn) {
        return this.$refs.commitMessage.showError('请输入或扫资产码');
      }
      if (!this.canCheck) {
        return this.$refs.commitMessage.showError('该资产码不符合检验确认操作的条件');
      }

      let nineNew = this.formData.busItemInfo.nineNew;
      if (nineNew !== false && nineNew !== true) {
        return this.$refs.commitMessage.showError('请选择是否9成新');
      }

      if (this.formData.test1Result !== true && this.formData.test1Result !== false) {
        return this.$refs.commitMessage.showError('请选择外观检验结果');
      }

      if (this.formData.test2Result !== true && this.formData.test2Result !== false) {
        return this.$refs.commitMessage.showError('请选择电性能检验结果');
      }

      if (!this.formData.testResult) {
        return this.$refs.commitMessage.showError('请选择检验结果');
      }

      if (!this.formData.disposeOpinion) {
        return this.$refs.commitMessage.showError('请选择处置意见');
      }


      if (this.isHardDisk) {
        let lessHour = this.formData.busItemInfo.lessHour;
        if (lessHour !== false && lessHour !== true) {
          return this.$refs.commitMessage.showError('请选择是否小于2000小时');
        }
      } else {
        if (!this.formData.busItemInfo.recycleFileId && this.formData.disposeOpinion != '40') {
          return this.$refs.commitMessage.showError('请上传金钥匙日志');
        }
      }

      this.loading = true
      this.$axios.post('busItem/saveRecyclableResult', this.formData).then(response => {
        this.loading = false
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.commitMessage.showError(res.message);
        }
        let msg = '提交成功，下一工站为：' + res.data.nextStationName;
        this.$refs.commitMessage.showSuccess(msg);
        this.$refs.searchMessage.showSuccess(msg);
        this.resetValues();
        this.formData.assetSn = ''
        this.$refs.assetSn.focus();
      }, error => {
        this.loading = false
        this.$message.error(error.message);
      });


    },
    setTest1ImgIds(idList) {
      this.formData.test1ImageIds = idList || []
    },
    setTest2ImgIds(idList) {
      this.formData.test2ImageIds = idList || []
    },
    setDocIds(docIds) {
      if (docIds && docIds.length > 0) {
        this.formData.busItemInfo.recycleFileId = docIds[0];
      } else {
        this.formData.busItemInfo.recycleFileId = '';
      }
    },
    calcResult() {
      if (this.formData.test1Result && this.formData.test2Result) {
        this.formData.testResult = '1'
        this.formData.disposeOpinion = '20'
      } else {
        this.formData.testResult = undefined
        this.formData.disposeOpinion = undefined
      }
    },

  }
}
</script>

<style scoped>
.form-item {
  width: 320px;
}
</style>