<template>
  <el-container class="home-container">
    <el-header style="height: 50px">
      <div class="title-box">
        <span class="title">捷思联想检验智能管控系统</span>
        <span v-if="helpTaskCnt>0" class="help-tip"><i class="el-icon-bell"></i>您有【{{ helpTaskCnt }}】条协助任务待处理。</span>
      </div>
      <div>
        <span class="header-info">{{ nowTime }}</span>
        <span class="header-info" :title="sysUser.roleNames">{{ roleText }}</span>
        <span class="header-info" :title="currentPosition">{{ sysUser.fullName }} 欢迎您</span>
        <el-button type="info" size="mini" @click="logout">退出</el-button>
      </div>
    </el-header>
    <el-main>
      <el-tabs type="border-card" :before-leave="tabBeforeLeave">
        <el-tab-pane :key="1" label="借出订单管理" v-if="tabs.showLendOrder">
          <LendOrder/>
        </el-tab-pane>
        <el-tab-pane :key="5" label="物品详情" v-if="tabs.showMaterialDetail">
          <MaterialDetail/>
        </el-tab-pane>
        <el-tab-pane :key="4" label="分拣作业" v-if="tabs.showSortMaterial">
          <SortMaterial/>
        </el-tab-pane>
        <el-tab-pane :key="6" label="外观检验" v-if="tabs.showAppearanceTest">
          <AppearanceTest menu-type="appearance"/>
        </el-tab-pane>
        <el-tab-pane key="KeyboardRepair" label="键盘维修" v-if="tabs.showAppearanceTest">
          <KeyboardRepair/>
        </el-tab-pane>
        <el-tab-pane :key="7" label="电性能检验" v-if="tabs.showFunctionTest">
          <FunctionTest menu-type="electric"/>
        </el-tab-pane>
        <el-tab-pane key="qcAppearance" label="品质分析(外观)" v-if="tabs.showQc">
          <AppearanceTest menu-type="qcAppearance"/>
        </el-tab-pane>
        <el-tab-pane key="qc" label="品质分析" v-if="tabs.showQc">
          <FunctionTest menu-type="qc"/>
        </el-tab-pane>
        <el-tab-pane :key="20" label="不良复检" v-if="tabs.showTestReview">
          <ReviewTest/>
        </el-tab-pane>
        <el-tab-pane key="TestAdvanced" label="高级检验" v-if="tabs.showTestAdvanced">
          <AdvancedTest/>
        </el-tab-pane>
        <el-tab-pane key="confirm" label="检验确认" v-if="tabs.showManagerConfirm">
          <ManagerConfirm/>
        </el-tab-pane>
        <el-tab-pane key="recycle" label="再利用检验" v-if="tabs.showRecycle">
          <RecyclableTest/>
        </el-tab-pane>
        <el-tab-pane key="DataErasure" label="资料擦除" v-if="tabs.showFunctionTest||tabs.showTestReview||tabs.showTestAdvanced||tabs.showQc">
          <DataErasure/>
        </el-tab-pane>
        <el-tab-pane :key="8" label="物品包装" v-if="tabs.showPack">
          <PackMaterial menu-type="good"/>
        </el-tab-pane>
        <el-tab-pane :key="30" label="不良品包装" v-if="tabs.showNgPack">
          <PackMaterial menu-type="ng"/>
        </el-tab-pane>
        <el-tab-pane :key="9" label="物品归还" name="goodReturnOrder" v-if="tabs.showReturnOrder">
          <ReturnOrder menu-type="good" ref="goodReturnOrder"/>
        </el-tab-pane>
        <el-tab-pane :key="25" label="不良物品归还" name="ngReturnOrder" v-if="tabs.showNgReturnOrder">
          <ReturnOrder menu-type="ng" ref="ngReturnOrder"/>
        </el-tab-pane>
        <el-tab-pane :key="21" label="归还单管理" v-if="tabs.showReturnOrder||tabs.showNgReturnOrder">
          <ReturnOrderList/>
        </el-tab-pane>
        <el-tab-pane :key="100" name="BusinessLayout" v-if="tabs.showBusinessManager">
          <span slot="label">
            业务管理<el-badge v-if="helpTaskCnt>0" :value="helpTaskCnt" class="task-badge" :max="99"></el-badge>
          </span>
          <BusinessLayout/>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>

<script>
import LendOrder from "@/views/lendOrder/LendOrder";
import SortMaterial from "@/views/sortMaterial/SortMaterial";
import AppearanceTest from "@/views/material/AppearanceTest";
import FunctionTest from "@/views/material/FunctionTest";
import PackMaterial from "@/views/material/PackMaterial";
import {currentDate} from "@/utils/common";
import MaterialDetail from "@/views/material/MaterialDetail";
import ReturnOrder from "@/views/returnOrder/ReturnOrder";
import ReturnOrderList from "@/views/returnOrder/ReturnOrderList";
import ReviewTest from "@/views/material/ReviewTest";
import AdvancedTest from "@/views/material/AdvancedTest";
import DataErasure from "@/views/material/DataErasure";
import BusinessLayout from "@/views/business/BusinessLayout";
import ManagerConfirm from "@/views/material/ManagerConfirm";
import KeyboardRepair from "@/views/material/KeyboardRepair.vue";
import RecyclableTest from "@/views/material/RecyclableTest.vue";

export default {
  name: "Home",
  components: {
    RecyclableTest,
    KeyboardRepair,
    ManagerConfirm,
    BusinessLayout,
    DataErasure,
    AdvancedTest,
    ReviewTest,
    ReturnOrder, ReturnOrderList,
    MaterialDetail, PackMaterial, FunctionTest, AppearanceTest, SortMaterial, LendOrder
  },
  data() {
    return {
      tabs: {
        showLendOrder: true,
        showSortMaterial: false,
        showMaterialDetail: false,
        showAppearanceTest: false,
        showFunctionTest: false,
        showTestReview: false,
        showTestAdvanced: false,
        showQc: false,
        showManagerConfirm: false,
        showRecycle: false,
        showPack: false,
        showNgPack: false,
        showReturnOrder: false,
        showNgReturnOrder: false,
        showUser: false,
        showBusinessManager: false
      },
      nowTime: currentDate(),
      sysUser: {
        fullName: '',
        roleNames: ''
      },
      activePath: '',
      timeTimer: '',
      taskCntTimer: ''
    }
  },
  computed: {
    currentPosition() {
      let pos = localStorage.getItem('current_position') || '';
      return '您的工位是：' + pos;
    },
    roleText() {
      let text = this.sysUser.roleNames || '';
      if (text.length > 25) {
        text = text.substring(0, 25) + '...';
      }
      return text;
    },
    helpTaskCnt() {
      return this.$store.state.helpTaskCnt
    }
  },
  methods: {
    logout() {
      this.$confirm('确定退出吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      }).then(() => {
        let token = localStorage.getItem('token');
        if (token) {
          this.$axios.post('invalidToken', {token: token});
        }
        localStorage.removeItem('token');
        this.$router.push('/login')
      })
    },
    saveNavState(path) {
      localStorage.setItem('activePath', path);
      this.activePath = path;
    },
    async refreshUser() {
      let {data: res} = await this.$axios.post('/user/getSysUser');
      if (res.code !== 0) {
        this.$message.error(res.message);
        if (res.code === 1001) {
          await this.$router.push('/login')
        }
        return;
      }
      this.sysUser = res.data;
      this.$store.commit("setSysUser", res.data);

      //setTabs
      this.tabs.showMaterialDetail = true;
      this.tabs.showBusinessManager = true;
      this.tabs.showSortMaterial = this.hasRole("oper_sort");
      this.tabs.showAppearanceTest = this.hasRole("oper_test1");
      this.tabs.showFunctionTest = this.hasRole("oper_test2");
      this.tabs.showTestReview = this.hasRole("oper_review");
      this.tabs.showTestAdvanced = this.hasRole("oper_advanced");
      this.tabs.showQc = this.hasRole("oper_qc");
      this.tabs.showManagerConfirm = this.hasRole("oper_manager_confirm");
      this.tabs.showRecycle = this.hasRole("oper_recycle");
      this.tabs.showPack = this.hasRole("oper_pack");
      this.tabs.showNgPack = this.hasRole("oper_ng_pack");
      this.tabs.showReturnOrder = this.hasRole("oper_return");
      this.tabs.showNgReturnOrder = this.hasRole("oper_ng_return");
      this.tabs.showUser = this.hasRole("admin");
    },
    hasRole(roleCode) {
      for (let i = 0; i < this.sysUser.roles.length; i++) {
        let role = this.sysUser.roles[i];
        if (role.id == roleCode) {
          return true;
        }
      }
      return false;
    },
    tabBeforeLeave(activeName, oldActiveName) {
      if (oldActiveName == 'ngReturnOrder') {
        this.$refs.ngReturnOrder.hidePopperTip()
      } else if (oldActiveName == 'goodReturnOrder') {
        this.$refs.goodReturnOrder.hidePopperTip()
      }
    },
    loadMyHelpTaskCnt() {
      this.$axios.get("help/queryMyHelpTaskCnt").then(response => {
        if (response.data.code !== 0) {
          this.$message.error('获取协助任务数量失败');
        }
        this.$store.commit("setHelpTaskCnt", response.data.data);
      })

    },
    cleanTimer() {
      clearInterval(this.taskCntTimer)
      clearInterval(this.timeTimer)
    }
  },
  async created() {
    //加载字典值
    this.$axios.get("sys/selectAll").then(response => {
      if (response.data.code !== 0) {
        this.$message.error('获取字典值失败');
      }
      this.$store.commit("setDictList", response.data.data);
    })

    this.activePath = localStorage.getItem('activePath')

    this.refreshUser();
    this.loadMyHelpTaskCnt()
    this.timeTimer = setInterval(() => this.nowTime = currentDate(), 1000);
    this.taskCntTimer = setInterval(() => this.loadMyHelpTaskCnt(), 1000 * 60);
  },
  beforeRouteLeave(to, form, next) {
    this.cleanTimer()
    next()
  }
}
</script>

<style scoped>
.home-container {
  height: 100%;
}

.el-header {
  background-color: #373d41;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.el-header .header-info {
  font-size: 14px;
  padding-right: 10px;
}

.el-aside {
  background-color: #333744;
}

.el-main {
  background-color: #eaedf1;
  padding: 5px;
}

.el-dialog__body {
  padding: 3px;
}

.help-tip {
  color: orange;
  font-size: 14px;
  margin-left: 30px;
}

.task-badge {
  top: -5px;
}

.home-container /deep/ .el-tabs__item {
  padding: 0 12px;
}
</style>