<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      <el-row style="padding-bottom: 10px;line-height: 40px">
        <el-input ref="assetSn" v-model="formData.assetSn" placeholder="请扫资产码" @keyup.enter.native="search" style="width: 250px"></el-input>
        <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="search">查询</el-button>
        <MessageComponent ref="searchMessage"/>
      </el-row>
      <MaterialInfo :busItem="busItem" :lendOrder="lendOrder" :lend-order-item="lendOrderItem" station="pack"/>

      <el-form :model="formData" label-width="200px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="包材编码：">{{ busItem.packMaterialCode }}</el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="包装提示：">
              <div class="fun-tip">{{ ruleText }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="原包装：" v-if="originalPackMaterialTypeName"> {{ originalPackMaterialTypeName }}</el-form-item>
            <el-form-item label="打印外码：">
              <el-radio-group v-model="formData.printAssetSn">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="可回收包材：" v-if="menuType === 'good'">
              <el-input v-model.trim="formData.recyclableMaterialSn" placeholder="请扫可回收包材条码" style="width: 250px;" clearable></el-input>
            </el-form-item>
            <el-form-item label="贴纸技服码：" v-if="needStickerCode">
              <el-input v-model.trim="formData.busItemInfo.stickerCode" placeholder="贴纸技服码" maxlength="20" class="form-item"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="包装指导图片：">
              <div style="width: 425px" v-if="guideImageList.length>0">
                <ImageViewerComponent @imageClick="guideImageClick" :image-list="guideImageList"/>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="">
          <el-button type="primary" @click="submit" :loading="commitLoading">包装完成</el-button>
          <MessageComponent ref="commitMessage"/>
        </el-form-item>

      </el-form>
    </el-card>
  </div>
</template>

<script>
import MaterialInfo from "@/views/material/MaterialInfo";
import MessageComponent from "@/components/MessageComponent";
import ImageViewerComponent from "@/components/ImageViewerComponent.vue";

export default {
  name: "PackMaterial",
  components: {ImageViewerComponent, MaterialInfo, MessageComponent},
  props: {
    menuType: {type: String, required: true}
  },
  data() {
    return {
      formData: {
        assetSn: '',
        printAssetSn: true,
        currentPosition: '',
        busItemInfo: {
          stickerCode: ''
        }
      },
      busItem: {},
      lendOrder: {},
      lendOrderItem: {},
      guideImageList: [],
      guideImageOpen: {},
      ruleText: '',
      loading: false,
      commitLoading: false,
      needStickerCode: false,
      canPack: false
    }
  },
  computed: {
    originalPackMaterialTypeName() {
      return this.busItem.busItemInfo?.originalPackMaterialTypeName
    }
  },
  methods: {
    clearData() {
      this.formData.assetSn = ''
      this.formData.recyclableMaterialSn = ''
      this.formData.busItemInfo.stickerCode = ''
      this.busItem = {}
      this.lendOrder = {}
      this.lendOrderItem = {}
      this.canPack = false
      this.needStickerCode = false
      this.guideImageList = [];
      this.guideImageOpen = {};
      this.ruleText = ''
    },

    submit() {
      this.$refs.searchMessage.clear();
      this.$refs.commitMessage.clear();
      let currentPosition = localStorage.getItem('current_position') || '';
      if (!currentPosition) {
        return this.$refs.commitMessage.showError('未设置工位，请重新登录系统并设置工位信息');
      } else {
        this.formData.currentPosition = currentPosition;
      }
      if (!this.formData.assetSn) {
        return this.$refs.commitMessage.showError('请输入或扫资产码');
      }
      if (!this.busItem.id) {
        return this.$refs.commitMessage.showError('请先查询资产信息');
      }
      if (!this.canPack) {
        return this.$refs.commitMessage.showError('该资产码不符合包装作业条件');
      }

      if (this.formData.recyclableMaterialSn) {
        this.formData.recyclableMaterialSn = this.formData.recyclableMaterialSn.toUpperCase();
        if (this.formData.recyclableMaterialSn.length != 23 || !this.formData.recyclableMaterialSn.startsWith('BOX')) {
          return this.$refs.commitMessage.showError('可回收包材条码必须23位且以"BOX"开头');
        }
        if (!this.busItem.packMaterialCode) {
          return this.$refs.commitMessage.showError('可回收包材条码与包材编码不匹配');
        }
        let boxCode = this.busItem.packMaterialCode.replaceAll('-', '');
        if (this.formData.recyclableMaterialSn.substring(3, 10) != boxCode) {
          return this.$refs.commitMessage.showError('可回收包材条码与包材编码不匹配');
        }
      }

      if (this.notOpenGuide()) {
        return this.$refs.commitMessage.showError('请按照每张包装指导图片进行作业。');
      }

      if (this.$socket.notOk()) {
        this.$confirm('未成功连接打印机小程序，是否继续？', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确定',
          type: 'warning'
        }).then(() => {
          this.save()
        })
      } else {
        this.save();
      }
    },
    save() {
      let url = 'busItem/saveGoodPackResult'
      if (this.menuType == 'ng') {//不良品包装
        url = 'busItem/saveNgPackResult'
      }
      this.commitLoading = true;
      this.$axios.post(url, this.formData).then(response => {
        this.commitLoading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.commitMessage.showError(res.message);
        }
        let item = res.data.item;
        this.$refs.commitMessage.showSuccess('包装作业处理成功，下一工站为：' + item.nextStationName);

        let assetSnBO = res.data.assetSnBO;
        if (assetSnBO) {
          this.$socket.send({
            "requestId": Math.round(Math.random() * 100000000),
            "printType": "asset",
            "printer": process.env.VUE_APP_ASSET_SN_PRINTER_NAME,
            "printVO": assetSnBO
          })
        }

        let store = res.data.store;
        if (store) {
          this.$socket.send({
            "requestId": Math.round(Math.random() * 100000000),
            "printType": "store",
            "printer": process.env.VUE_APP_TROUBLE_CODE_PRINTER_NAME,
            "printVO": store
          })
        }
        let trouble = res.data.trouble;
        if (trouble) {
          let obj = {
            "requestId": Math.round(Math.random() * 100000000),
            "printType": "trouble",
            "printer": process.env.VUE_APP_TROUBLE_CODE_PRINTER_NAME,
            "printVO": trouble
          };
          this.$socket.send(obj);
          //不良标签打印2个完全一样的
          this.$socket.send(obj)
        }

        //清掉之前的资产码
        this.clearData();
        this.$refs.assetSn.focus();

      }, error => {
        this.commitLoading = false;
        return this.$refs.commitMessage.showError('操作失败，' + error.message);
      });
    },
    async search() {
      this.canPack = false;
      this.$refs.searchMessage.clear();
      this.$refs.commitMessage.clear();
      if (!this.formData.assetSn) {
        this.$refs.searchMessage.showError('请输入或扫资产码');
        return;
      }
      this.formData.assetSn = this.formData.assetSn.trim();
      this.loading = true;

      const {data: res} = await this.$axios.get('/busItem/queryInfoForPack', {
        params: {assetSn: this.formData.assetSn}
      });
      this.loading = false;

      if (res.code !== 0) {
        this.$refs.searchMessage.showError(res.message);
        return
      }

      if (!res.data.busItem) {
        this.busItem = {};
        this.lendOrder = {};
        this.lendOrderItem = {};
        return this.$refs.searchMessage.showError('资产码不正确');
      }
      this.busItem = res.data.busItem || {};
      this.lendOrder = res.data.lendOrder || {};
      this.lendOrderItem = res.data.lendOrderItem || {};
      this.needStickerCode = res.data.needStickerCode;

      let packGuideImage = res.data.packGuideImage;
      if (packGuideImage) {
        let arr = [];
        for (let i = 1; i <= 3; i++) {
          let id = packGuideImage['image' + i + 'Id'];
          if (id) {
            let url = packGuideImage['image' + i + 'Url'];
            arr.push({id: id, url: url});
            this.guideImageOpen[id] = false
          }
        }
        this.guideImageList = arr;
      }

      this.ruleText = res.data.ruleText || '';

      if (this.busItem.currentStation != 'pack') {
        var msg = '该物品当前工站不是包装工站';
        if (this.busItem.currentStationStatus == 'finish' && this.busItem.nextStationName) {
          msg += '，工站为：' + this.busItem.nextStationName;
        } else if (this.busItem.currentStationName) {
          msg += '，工站为：' + this.busItem.currentStationName;
        }
        return this.$refs.searchMessage.showError(msg);
      } else if (this.busItem.currentStation == 'pack' && this.busItem.currentStationStatus == 'finish') {
        return this.$refs.searchMessage.showError('该物品已经完成包装作业，不能重复作业');
      }

      if (this.menuType == 'ng' && this.busItem.testResult == '1') {
        return this.$refs.searchMessage.showError('该物品为合格品，请交于合格品包装员进行包装');
      } else if (this.menuType == 'good' && this.busItem.testResult != '1') {
        return this.$refs.searchMessage.showError('该物品为不良品，请交于不良品包装员进行包装');
      }
      this.canPack = true;

    },
    guideImageClick(id) {
      this.guideImageOpen[id] = true
    },
    notOpenGuide() {
      let notOpen = false;
      if (!this.guideImageList || this.guideImageList.length == 0) {
        return notOpen;
      }
      for (let key in this.guideImageOpen) {
        if (this.guideImageOpen[key] === false) {
          notOpen = true;
          break;
        }
      }
      return notOpen;
    }
  }
}
</script>

<style scoped>
.fun-tip {
  font-weight: bold;
  font-size: 16px;
  color: red;
}
.bad-tip {
  font-weight: bold;
  font-size: 24px;
  color: red;
}

.form-item {
  width: 400px;
}

.options {
  padding-right: 10px;
  width: 200px;
}
</style>
