<template>
  <div class="imgDiv">
    <el-image @click="click(item.id)" v-for="(item,index) in imageList" :src="item[urlField]" :key="item[urlField]" :preview-src-list="getImgList(index)" lazy></el-image>
  </div>
</template>

<script>
export default {
  props: {
    urlField: {type: String, default: 'url'},
    imageList: {
      type: Array, default: function () {
        return []
      }
    },
    urlList: {
      type: Array, default: function () {
        return []
      }
    }
  },
  data() {
    return {};
  },
  created() {
    if (this.imageList.length == 0 && this.urlList.length > 0) {
      for (let i = 0; i < this.urlList.length; i++) {
        this.imageList.push({url: this.urlList[i]})
      }
    }
  },
  methods: {
    click(id) {
      this.$emit('imageClick', id)
    },
    getImgList(index) {
      let arr = []
      if(this.imageList[index]){
        arr.push(this.imageList[index][this.urlField])
      }
      return arr;
    }
  }
}
</script>

<style scoped>
.el-image {
  width: 130px;
  height: 130px;
  padding: 3px
}

.imgDiv {
  border: solid 1px #e5e5e5;
  padding: 5px;
  height: 136px;
}

</style>