import axios from "axios";
import Vue from "vue";
import router from "@/router";
import {baseUrl} from "@/utils/common";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const instance = axios.create({
    baseURL: baseUrl(),
    timeout: process.env.VUE_APP_AXIOS_TIMEOUT
})

instance.interceptors.request.use(config => {
    let token = localStorage.getItem('token');
    if (token) {
        config.headers['token'] = token
    }
    //在最后必须return config
    return config
}, error => {
    console.log(error) // for debug
    return Promise.reject(error)
})

instance.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.data.status == 401) {
        let tokenStatus = localStorage.getItem('tokenStatus');
        if (tokenStatus === 'true') {
            Vue.prototype.$message.error("您尚未登录或登录信息已过期");
        }
        localStorage.setItem('tokenStatus', 'false')
        router.push('/login');
    }
    if ('Network Error' == error.message) {
        //Vue.prototype.$message.error("网络连接失败");
    } else if (error.message.startsWith('timeout of')) {
        //Vue.prototype.$message.error("请求超时");
    }
    return Promise.reject(error)
});

export default instance



