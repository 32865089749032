<template>
  <el-card class="box-card" shadow="never">
    <el-form inline>
      <el-form-item>
        <el-input v-model="queryInfo.code" clearable style="width: 300px" placeholder="借出订单号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="queryInfo.status" placeholder="借出单状态" clearable>
          <el-option v-for="item in statusList" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-col :span="11">
          <el-date-picker type="date" placeholder="制单开始时间" v-model="queryInfo.createTimeBegin" style="width: 100%;" value-format="yyyy-MM-dd"></el-date-picker>
        </el-col>
        <el-col class="line" :span="1" style="text-align: center">-</el-col>
        <el-col :span="11">
          <el-date-picker type="date" placeholder="制单结束时间" v-model="queryInfo.createTimeEnd" style="width: 100%;" value-format="yyyy-MM-dd"></el-date-picker>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="currentChange(1)">搜索</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="orderList" border highlight-current-row @row-click="rowClick" height="650">
      <el-table-column align="center" width="35">
        <template slot-scope="scope">
          <el-radio v-model="radio" :label="scope.row.id" style="margin-right: -12px">{{ '' }}</el-radio>
        </template>
      </el-table-column>
      <el-table-column align="left" header-align="center" label="操作" width="280px">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="showOrderItem(scope.row)">明细</el-button>
          <el-button v-if="scope.row.status=='200'" size="mini" type="primary" @click="syncStatus(scope.row)" :loading="scope.row.loading">同步状态</el-button>
          <el-button v-if="scope.row.status!='200' && scope.row.status!='210'" type="primary" size="mini" @click="showBusItem(scope.row)">物品明细</el-button>
          <el-button v-if="scope.row.status!='200' && scope.row.status!='210'" type="primary" size="mini" @click="exportBusItem(scope.row)" :loading="scope.row.loading">物品导出</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="借出订单号" prop="code" width="225"></el-table-column>
      <el-table-column align="center" label="订单类型" prop="orderTypeName" width="260"></el-table-column>
      <el-table-column align="center" label="检验类型" prop="testTypeName" width="150"></el-table-column>
      <el-table-column align="center" label="状态" prop="statusName" width="100"></el-table-column>
      <el-table-column align="center" label="挂起状态" prop="hangUpStatusName" width="100"></el-table-column>
      <el-table-column align="center" label="备件总件数" prop="totalNumber" width="100"></el-table-column>
      <el-table-column align="center" label="电性能检验总数量" prop="electricityRequiredTestQty" width="140"></el-table-column>
      <el-table-column align="center" label="外观检验总数量" prop="appearanceRequiredTestQty" width="130"></el-table-column>
      <el-table-column align="center" label="入库库房ID" prop="demanderWareHouse" width="100"></el-table-column>
      <el-table-column align="center" label="入库库房名称" prop="demanderWareHouseName" width="180"></el-table-column>
      <el-table-column align="center" label="入库库区ID" prop="demanderStockArea" width="100"></el-table-column>
      <el-table-column align="center" label="入库库区名称" prop="demanderStockAreaName" width="220"></el-table-column>
      <el-table-column align="center" label="出库库房ID" prop="providerWareHouse" width="100"></el-table-column>
      <el-table-column align="center" label="出库库房名称" prop="providerWareHouseName" width="150"></el-table-column>
      <el-table-column align="center" label="出库库区ID" prop="providerStockArea" width="100"></el-table-column>
      <el-table-column align="center" label="出库库区名称" prop="providerStockAreaName" width="220"></el-table-column>
      <el-table-column align="center" label="制单人" prop="creatorName" width="100"></el-table-column>
      <el-table-column align="center" label="制单时间" prop="createTime" width="170"></el-table-column>
      <el-table-column align="center" label="修改人员" prop="updaterName" width="100"></el-table-column>
      <el-table-column align="center" label="修改时间" prop="updateTime" width="170"></el-table-column>
      <el-table-column align="center" label="审核人员" prop="auditorName" width="100"></el-table-column>
      <el-table-column align="center" label="审核时间" prop="auditorTime" width="160"></el-table-column>
      <el-table-column align="center" label="审核结论" prop="auditMemo"></el-table-column>
      <el-table-column align="center" label="备件粒度" prop="granularity" width="90"></el-table-column>
      <el-table-column align="center" label="需要运输" prop="providerTransFlag" width="90" :formatter="isFormatter"></el-table-column>
      <el-table-column align="center" label="检验商编码" prop="testVendorCode" width="100"></el-table-column>
      <el-table-column align="center" label="检验商名称" prop="testVendorName" width="250"></el-table-column>
      <el-table-column align="center" label="分支机构编码" prop="branchOrganizationCode" width="120"></el-table-column>
      <el-table-column align="center" label="分支机构名称" prop="branchOrganizationName" width="300"></el-table-column>
      <el-table-column align="center" label="供应商代码" prop="supplierCode" width="100"></el-table-column>
      <el-table-column align="center" label="供应商名称" prop="supplierName" width="300"></el-table-column>
      <el-table-column align="center" label="供应商组织代码" prop="organizationCode" width="120"></el-table-column>
      <el-table-column align="center" label="检验收货ASN" prop="asnNo" width="200"></el-table-column>
      <el-table-column align="center" label="检验收货完成时间/入库完成时间" prop="finishEnteredTime" width="250"></el-table-column>
      <el-table-column align="center" label="检验收货完成人账号" prop="finishUserCode" width="150"></el-table-column>
      <el-table-column align="center" label="发货完成时间" prop="deliveryTime" width="170"></el-table-column>
      <el-table-column align="center" label="发货单号" prop="goodsIssueDocCode" width="200"></el-table-column>
      <el-table-column align="center" label="发货完成账号" prop="deliveryUserCode" width="100"></el-table-column>
      <el-table-column align="center" label="检验收货单号" prop="receiptCode" width="200"></el-table-column>
      <el-table-column align="center" label="说明" prop="description" width="400"></el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination
          :total="total"
          layout="total,prev, pager, next"
          background
          :page-size="pageSize"
          :current-page.sync="queryInfo.pageNo"
          @current-change="currentChange"
      ></el-pagination>
    </div>

    <el-dialog title="借出订单明细" :visible.sync="orderItemVisible" :close-on-click-modal="false" width="95%">
      <LendOrderItem ref="orderItemComponent"/>
    </el-dialog>

    <el-dialog title="物品明细" :visible.sync="busItemVisible" :close-on-click-modal="false" width="95%">
      <LendOrderBusItem ref="busItemComponent"/>
    </el-dialog>
  </el-card>
</template>

<script>
import LendOrderItem from "@/views/lendOrder/LendOrderItem";
import LendOrderBusItem from "@/views/lendOrder/LendOrderBusItem";

export default {
  name: "LendOrder",
  components: {LendOrderItem, LendOrderBusItem},
  created() {
    this.getOrderList();
  },
  data() {
    return {
      queryInfo: {
        code: '',
        status: '',
        createTimeBegin: '',
        createTimeEnd: '',
        pageNo: 1
      },
      orderList: [],
      total: 0,
      pageSize: 20,
      radio: '',
      currentRow: {},
      orderItemVisible: false,
      busItemVisible: false
    }
  },
  computed: {
    statusList() {
      return this.$store.getters.getDictList('lendOrderStatus') || [];
    }
  },
  methods: {
    async getOrderList() {
      const {data: res} = await this.$axios.post('lendOrder/list', this.queryInfo);
      if (res.code !== 0) {
        this.$message.error(res.message)
        return
      }

      let orderList = res.data.records || [];
      orderList.forEach(item => item.loading = false)
      this.orderList = orderList;
      this.pageSize = res.data.size;
      this.total = res.data.total
    },
    //add xgb
    syncStatus(row) {
      row.loading = true
      this.$axios.post('lendOrder/syncStatus', row).then(response => {
        row.loading = false
        this.getOrderList()
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
      }, error => {
        row.loading = false
        this.getOrderList()
        return this.$message.error(error.message);
      });
    },
    showOrderItem(row) {
      if (!row) {
        return;
      }
      this.orderItemVisible = true;
      setTimeout(() => {
        this.$refs.orderItemComponent.query(row.id);
      }, 200);

    },
    showBusItem(row) {
      if (!row) {
        return;
      }
      this.busItemVisible = true;
      setTimeout(() => {
        this.$refs.busItemComponent.query(row.id);
      }, 200);

    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.getOrderList()
    },
    rowClick(row) {
      if (this.radio == row.id) {
        return;
      }
      this.currentRow = row;
      this.radio = row.id
    },
    isFormatter(row, column, cellValue) {
      if (cellValue == true) {
        return '是';
      }
      if (cellValue == false) {
        return '否';
      }
      if (cellValue == null || cellValue == '') {
        return '';
      }
    },
    exportBusItem(row) {
      row.loading = true
      this.$axios.post('report/exportBusItem', row.id, {timeout: 600000}).then(response => {
        row.loading = false
        console.log(row)
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        if (res.data.url) {
          let form = document.createElement('form');
          form.action = res.data.url;
          form.target = '_blank';
          document.body.appendChild(form);
          form.submit();
        }
      }, error => {
        row.loading = false
        return this.$message.error('导出失败，' + error.message);
      });
    }
  }
}
</script>

<style scoped>

</style>
