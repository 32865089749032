<template>
  <div>
    <div style="display: flex;">
      <div v-if="imageList.length>0">
        <div class="img-box" :key="index" v-for="(item,index) in imageList">
          <el-image :src="item.url" :preview-src-list="getImgList(index)"></el-image>
          <div class="delete" @click="delImg(index)">
            <i class="el-icon-delete"></i>
          </div>
        </div>
      </div>
      <el-button type="text" @click="dialogVisible=true" v-show="imageList.length<imgCnt">点击拍照</el-button>
    </div>
    <el-dialog
        title="拍摄检验照片"
        @opened="opened"
        @open="open"
        :close-on-click-modal="false"
        width="1550px"
        top='5vh'
        :visible.sync="dialogVisible">
      <div style="width: 100%;height: 550px;">
        <el-row v-if="videoDevices.length>1">
          <el-col :span="2" style="text-align: right">摄像头设备：</el-col>
          <el-col :span="6">
            <el-select v-model="deviceId" @change="deviceChange" style="width: 100%">
              <el-option v-for="item in videoDevices" :key="item.deviceId" :label="item.label" :value="item.deviceId"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="text-align: center">
          <el-col :span="12">
            <div>摄像头图像</div>
            <video ref="videoCamera" :width="750" :height="495"></video>
            <video ref="videoCameraSize" style="display: none"></video>
            <canvas style="display: none" ref="canvas" :width="imgWidth" :height="imgHeight"></canvas>
          </el-col>
          <el-col :span="12">
            <div>照片预览</div>
            <el-image :src="src" style="width: 750px;height: 495px" :preview-src-list="[src]" :z-index="2025">
              <div slot="error" class="image-slot" style="font-size: 80px;display: flex;justify-content: center;align-items: center;height: 100%">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="photo" type="primary">{{ this.src ? '重拍' : '拍照' }}</el-button>
        <el-button @click="upload" type="primary">上传</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: "PhotographComponent",
  data() {
    return {
      imgWidth: 2048,
      imgHeight: 1080,
      context: '',
      video: '',
      videoSize: '',
      canvas: '',
      src: '',
      imageList: [],
      videoDevices: [],
      deviceId: '',
      dialogVisible: false,
      uploadUrl: process.env.VUE_APP_AXIOS_IMG_BASE_URL + 'upload/singleFile'
    }
  },
  props: {
    imgCnt: {
      type: Number,
      default: 3,
      validator: function (value) {
        return Number.isInteger(value) && value >= 1;
      }
    },
  },
  methods: {
    open() {
      this.src = ''
    },
    opened() {
      if (!this.video) {
        console.log("create")
        this.getCompetence();
      } else {
        console.log("not create")
      }
    },
    upload() {
      let file = this.dataURLtoFile(this.src, "检验照片.jpg");
      let formData = new FormData();
      formData.append("file", file);
      this.$axios.post(this.uploadUrl, formData).then(response => {
        if (response.data.code !== 0) {
          this.$message.error('上传检验照片失败');
          return;
        }
        this.imageList.push(response.data.data)
        //回传文件id到父对象
        let arr = [];
        for (let i = 0; i < this.imageList.length; i++) {
          arr.push(this.imageList[i].id)
        }
        this.$emit('setImgIds', arr)
        this.dialogVisible = false;
      })

    },
    onClear() {
      this.src = ''
      this.imageList = []
    },
    // base64转文件
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, {type: mime})
    },
    // 调用权限（打开摄像头功能）
    getCompetence() {
      // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {}
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia || navigator.mediaDevices.getUserMedia
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'))
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject)
          })
        }
      }

      // 列出相机和麦克风。
      navigator.mediaDevices.enumerateDevices().then(devices => {
        let deviceArr = devices.filter(device => device.kind === 'videoinput' && device.deviceId);
        deviceArr.sort((a, b) => a.deviceId - b.deviceId)
        this.videoDevices = deviceArr

        this.openMedia()
      }).catch(err => {
        this.$message.error(`获取设备列表失败，${err.name} ：${err.message}`);
        console.log(err.name + ": " + err.message);

        this.openMedia()
      });

    },
    deviceChange() {
      this.close()
      this.openMedia()
    },
    openMedia() {
      if (!this.deviceId && this.videoDevices.length > 0) {
        this.deviceId = this.videoDevices[0].deviceId
      }

      let constraints = {
        audio: false,
        video: {
          width: {ideal: 5000},
          height: {ideal: 5000}
        }
      }

      if (this.deviceId) {
        constraints.video.deviceId = this.deviceId
      }

      let _this = this
      this.video = this.$refs.videoCamera;
      this.videoSize = this.$refs.videoCameraSize;
      this.canvas = this.$refs.canvas;
      this.context = this.canvas.getContext("2d");

      navigator.mediaDevices.getUserMedia(constraints).then(stream => {
        // 旧的浏览器可能没有srcObject
        if ('srcObject' in this.video) {
          this.video.srcObject = stream
        } else {
          // 避免在新的浏览器中使用它，因为它正在被弃用。
          this.video.src = window.URL.createObjectURL(stream)
        }
        this.video.onloadedmetadata = function () {
          _this.video.play()
        }

        this.videoSize.srcObject = stream
        this.videoSize.onloadedmetadata = function () {
          _this.videoSize.play()
          _this.imgWidth = this.videoWidth
          _this.imgHeight = this.videoHeight
        }
      }).catch(err => {
        console.error(err)
        let message = err.message || err
        let msg = {
          'permission denied': '浏览器禁止本页面使用摄像头，请开启相关的权限',
          'requested device not found': '未检测到摄像头'
        }
        this.$message.error(msg[message.toLowerCase()] || '开启摄像头失败，未知错误')
      })
    },
    photo() {
      //绘制画面
      this.context.drawImage(this.video, 0, 0, this.imgWidth, this.imgHeight);
      this.src = this.canvas.toDataURL('image/jpeg', 0.9);
    },
    close() {
      this.video.srcObject.getTracks()[0].stop();
      this.video.srcObject = null
      this.video = null
    },
    getImgList(index) {
      let arr = []
      arr.push(this.imageList[index].url)
      return arr;
    },
    delImg(index) {
      this.imageList.splice(index, 1)
      //回传文件id到父对象
      let arr = [];
      for (let i = 0; i < this.imageList.length; i++) {
        arr.push(this.imageList[i].id)
      }
      this.$emit('setImgIds', arr)
    },
  }
}
</script>

<style scoped>
.el-image {
  width: 144px;
  height: 144px;
  margin: 3px
}

.img-box {
  position: relative;
  height: 150px;
  display: inline-block;
}

.delete {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  line-height: 16px;
  font-size: 16px;
  background-color: #fff;
}

.image-slot {
  font-size: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%
}
</style>