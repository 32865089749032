const allRoles = [
    'admin',
    'bus_data_manager',
    'bus_manager',
    'high_bus_manager',
    'oper_advanced',
    'oper_capacity',
    'oper_manager_confirm',
    'oper_ng_pack',
    'oper_ng_return',
    'oper_pack',
    'oper_pack_material',
    'oper_print_asset_sn',
    'oper_return',
    'oper_return_advanced',
    'oper_review',
    'oper_sort',
    'oper_sort_advanced',
    'oper_spot_check',
    'oper_target_store',
    'oper_test1',
    'oper_test2',
    'oper_test_rule',
    'oper_urgent'
]
export const menuList = [
    {
        index: 'export',
        title: '数据导出',
        icon: 'el-icon-s-data',
        items: [
            {index: '/home/userExport', title: '个人数据导出', component: 'userReport/ExportUserReport', roles: [...allRoles]},
            {index: '/home/exportReport', title: '报表导出', component: 'report/ExportReport', roles: ['bus_manager']},
            {index: '/home/inStoreAgingView', title: '在库时效', component: 'report/InStoreAgingView', roles: ['bus_manager']}
        ]
    }, {
        index: 'labelPrint',
        title: '标签重打',
        icon: 'el-icon-menu',
        items: [
            {index: '/home/labelPrint', title: '标签重打', component: 'returnOrder/LabelPrint', roles: ['oper_ng_pack']},
            {index: '/home/assetSnPrint', title: '资产码补打', component: 'material/AssetSnPrint', roles: ['oper_print_asset_sn']},
            {index: '/home/calibration/colorCalibrationPrint', title: '校色屏补打', component: 'calibration/ColorCalibrationPrint', roles: ['oper_calibration']},
        ]
    }, {
        index: 'colorCalibration',
        title: '校色屏',
        icon: 'el-icon-menu',
        items: [
            {index: '/home/calibration/colorCalibration', title: '校色作业', component: 'calibration/ColorCalibration', roles: ['oper_calibration']},
            {index: '/home/calibration/colorCalibrationPn', title: 'PN关系管理', component: 'calibration/ColorCalibrationPn', roles: ['bus_manager']},
            {index: '/home/calibration/colorCalibrationBefore', title: '调拨数据管理', component: 'calibration/ColorCalibrationBefore', roles: ['bus_manager']},
            {index: '/home/calibration/colorCalibrationAfter', title: '归还数据管理', component: 'calibration/ColorCalibrationAfter', roles: ['bus_manager']},
        ]
    }, {
        index: 'doa',
        title: 'DOA管理',
        icon: 'el-icon-menu',
        items: [
            {index: '/home/dao/doaWorkRecord', title: 'Aging作业', component: 'business/DoaWorkRecord', roles: ['bus_manager']},
            {index: '/home/doaData', title: '联想DOA时间', component: 'material/DoaData', roles: ['bus_data_manager', 'bus_manager']},
            {index: '/home/doaReason', title: 'DOA故障原因', component: 'business/DoaReason.vue', roles: ['bus_manager']},
        ]
    }, {
        index: 'help',
        title: '协助管理',
        icon: 'el-icon-menu',
        items: [
            {index: '/home/help/task', title: '我的协助任务', component: 'help/MyHelpTask', roles: [...allRoles]},
            {index: '/home/help/apply', title: '请求协助', component: 'help/CreateHelp', roles: [...allRoles]},
            {index: '/home/help/myCreated', title: '我发起的协助', component: 'help/MyCreatedHelp', roles: [...allRoles]},
            {index: '/home/help/list', title: '协助任务查询', component: 'help/HelpList', roles: ['bus_manager']},
        ]
    }, {
        index: 'issue',
        title: '作业异常管理',
        icon: 'el-icon-menu',
        items: [
            {index: '/home/issue/myIssue', title: '我的异常登记', component: 'issue/MyIssue', roles: [...allRoles]},
            {index: '/home/issue/myAllIssue', title: '我的异常查询', component: 'issue/MyAllIssue', roles: [...allRoles]},
            {index: '/home/issue/dealIssue', title: '作业异常处理', component: 'issue/DealIssue', roles: ['bus_manager']},
            {index: '/home/issue/list', title: '作业异常查询', component: 'issue/IssueList', roles: ['bus_manager']},
        ]
    }, /*{
        index: 'spotCheck',
        title: '抽检管理',
        icon: 'el-icon-menu',
        items: [
            {index: '/home/checkBeforeSortAdd', title: '分拣前抽检录入', component: 'spotCheck/CheckBeforeSortAdd', roles: ['oper_spot_check']},
            {index: '/home/checkBeforeSort', title: '分拣前抽检管理', component: 'spotCheck/CheckBeforeSort', roles: ['oper_spot_check']},
            {index: '/home/checkBeforePackAdd', title: '包装前抽检录入', component: 'spotCheck/CheckBeforePackAdd', roles: ['oper_spot_check']},
            {index: '/home/checkBeforePack', title: '包装前抽检管理', component: 'spotCheck/CheckBeforePack', roles: ['oper_spot_check']},
            {index: '/home/checkAfterPackAdd', title: '包装后OBA抽检录入', component: 'spotCheck/CheckAfterPackAdd', roles: ['oper_spot_check']},
            {index: '/home/checkAfterPack', title: '包装后OBA抽检管理', component: 'spotCheck/CheckAfterPack', roles: ['oper_spot_check']},
        ]
    }, */{
        index: 'sort',
        title: '分拣管理',
        icon: 'el-icon-menu',
        items: [
            {index: '/home/capacity', title: '计划产能', component: 'businessOrder/Capacity', roles: ['oper_capacity']},
            {index: '/home/ruleIntercept', title: '分拣拦截', component: 'businessOrder/RuleIntercept', roles: ['bus_manager']},
            {index: '/home/ruleColorCalibration', title: '校色拦截', component: 'businessOrder/RuleColorCalibration', roles: ['bus_manager']},
            {index: '/home/cpuInscriptionMaterial', title: 'CPU碑文管理', component: 'businessOrder/CpuInscriptionMaterial', roles: ['bus_manager']},
            {index: '/home/materialCodeEquivalence', title: '备件编码等价字符', component: 'businessOrder/MaterialCodeEquivalence', roles: ['bus_manager']},
            {index: '/home/boardGsku', title: '备件编码COA编码', component: 'business/BoardGsku.vue', roles: ['bus_manager']},
            {index: '/home/ruleSortLine', title: '线体管理', component: 'business/RuleSortLine.vue', roles: ['bus_manager']},
            {index: '/home/business/MaterialKeyInCategory', title: '备件投产大类', component: 'business/MaterialKeyInCategory', roles: ['bus_manager']},
            {index: '/home/recyclable/recyclableLendOrder', title: '再利用借出单', component: 'recyclable/RecyclableLendOrder', roles: ['bus_manager']},
        ]
    }, {
        index: 'test',
        title: '检验管理',
        icon: 'el-icon-menu',
        items: [
            {index: '/home/tipRule', title: '检验提示', component: 'businessOrder/TipRule', roles: ['oper_test_rule']},
            {index: '/home/goldenKeyRate', title: '金钥匙检验', component: 'businessOrder/GoldenKeyRate', roles: ['oper_test_rule']},
            {index: '/home/advancedRule', title: '高级检验规则', component: 'businessOrder/AdvancedRule', roles: ['oper_test_rule']},
            {index: '/home/testGuideImage', title: '检验指导图片', component: 'business/TestGuideImage', roles: ['bus_manager']},
            {index: '/home/colorBrightness', title: '最小亮度管理', component: 'businessOrder/ColorBrightness', roles: ['bus_manager']},
            /*{index: '/home/testCheckCategory', title: '检验项目管理', component: 'business/TestCheckCategory', roles: ['bus_manager']},*/
            {index: '/home/busImageLibrary', title: '图片库数据', component: 'business/BusImageLibrary', roles: ['bus_manager']},
            {index: '/home/ruleRetest', title: '试错拦截管理', component: 'business/RuleRetest', roles: ['bus_manager']},
            {index: '/home/busItemImageEdit', title: '检验照片管理', component: 'business/BusItemImageEdit', roles: ['high_bus_manager']},
            {index: '/home/ruleExemption', title: '电性能检验比例', component: 'businessOrder/RuleExemption', roles: ['high_bus_manager']},
        ]
    }, {
        index: 'judge',
        title: '判库管理',
        icon: 'el-icon-menu',
        items: [
            {index: '/home/ruleJudgeStore', title: '判库规则', component: 'business/RuleJudgeStore', roles: ['oper_target_store']},
            {index: '/home/priorityRule', title: '合格品优先库规则', component: 'businessOrder/PriorityRule', roles: ['oper_target_store']},
            {index: '/home/ngStoreInterceptRule', title: '不良品库区拦截规则', component: 'businessOrder/NgStoreInterceptRule', roles: ['oper_target_store']},
            {index: '/home/materialProductionInfo', title: '生产日期管理', component: 'business/MaterialProductionInfo', roles: ['bus_manager']},
            {index: '/home/materialRepairWarranty', title: '维修买保表维护', component: 'business/MaterialRepairWarranty', roles: ['bus_manager']},
        ]
    }, {
        index: 'keyBoard',
        title: '键盘管理',
        icon: 'el-icon-menu',
        items: [
            {index: '/home/keyboardModel', title: '键盘机种管理', component: 'business/KeyboardModel', roles: ['bus_manager']},
            {index: '/home/keyboardAte', title: '键盘ATE数据', component: 'business/KeyboardAte', roles: ['bus_data_manager']},
        ]
    }, {
        index: 'packMaterial',
        title: '包材管理',
        icon: 'el-icon-menu',
        items: [
            {index: '/home/packMaterial/inStore', title: '入库作业', component: 'packMaterial/InStore', roles: ['oper_pack_material']},
            {index: '/home/packMaterial/transfer', title: '复用作业', component: 'packMaterial/Transfer', roles: ['oper_pack_material']},
            {index: '/home/packMaterial/consumableOut', title: '耗材发放', component: 'packMaterial/ConsumableOut', roles: ['bus_manager']},
            {index: '/home/packMaterial/stockList', title: '包材库存', component: 'packMaterial/StockList', roles: ['oper_pack_material']},
            {index: '/home/packMaterial/stockManage', title: '库存盘点', component: 'packMaterial/StockManage', roles: ['bus_manager']},
            {index: '/home/packMaterialSubClass', title: '包材小类', component: 'material/PackMaterialSubClass', roles: ['oper_pack_material']},
            {index: '/home/packMaterialRelation', title: '备件包材', component: 'material/PackMaterialRelation', roles: ['oper_pack_material_advanced']},
            {index: '/home/packMaterial/packMaterialManage', title: '包材编码', component: 'packMaterial/PackMaterialManage', roles: ['bus_manager']},
        ]
    }, {
        index: 'recyclableBox',
        title: '可回收包材',
        icon: 'el-icon-menu',
        items: [
            {index: '/home/recyclableBox/inStore', title: '入库作业', component: 'recyclableBox/RecyclableBoxInStore', roles: ['oper_pack_material']},
            {index: '/home/recyclableBox/batchInStore', title: '批量入库', component: 'recyclableBox/RecyclableBoxBatchInStore', roles: ['oper_pack_material']},
            {index: '/home/recyclableBox/toRepair', title: '送修登记', component: 'recyclableBox/RecyclableBoxToRepair', roles: ['oper_pack_material']},
            {index: '/home/recyclableBox/scrap', title: '包材报废', component: 'recyclableBox/RecyclableBoxScrap', roles: ['oper_pack_material']},
            {index: '/home/recyclableBox/delUseLog', title: '耗用记录删除', component: 'recyclableBox/DelUseLog', roles: ['bus_manager']},
            {index: '/home/recyclableBox/update', title: '包材状态调整', component: 'recyclableBox/RecyclableBoxUpdate', roles: ['bus_manager']},
            {index: '/home/recyclableBox/recyclableBoxMaterial', title: '禁用乐BOX备件', component: 'recyclableBox/RecyclableBoxMaterial', roles: ['bus_manager']},
            {index: '/home/recyclableBox/export', title: '报表导出', component: 'recyclableBox/RecyclableBoxExport', roles: ['bus_manager']},
        ]
    }, {
        index: 'pack',
        title: '包装管理',
        icon: 'el-icon-menu',
        items: [
            {index: '/home/rulePackTip', title: '包装提示', component: 'rule/RulePackTip', roles: ['bus_manager']},
            {index: '/home/packGuideImage', title: '包装指导图片', component: 'business/PackGuideImage', roles: ['bus_manager']},
        ]
    }, {
        index: 'return',
        title: '归还管理',
        icon: 'el-icon-menu',
        items: [
            {index: '/home/ruleStoreEquivalence', title: '等价库区', component: 'business/RuleStoreEquivalence', roles: ['bus_manager']},
        ]
    }, {
        index: 'data',
        title: '数据调整',
        icon: 'el-icon-edit-outline',
        items: [
            {index: '/home/materialDetailModify', title: '未完工物品调整', component: 'material/MaterialDetailModify', roles: ['bus_manager']},
            {index: '/home/dataManagement', title: '业务数据调整', component: 'business/DataManagement', roles: ['bus_data_manager']},
        ]
    }, {
        index: 'dataImport',
        title: '数据导入',
        icon: 'el-icon-menu',
        items: [
            {index: '/home/importReceiveTime', title: '联想入库时间导入', component: 'returnOrder/ImportReceiveTime', roles: ['bus_data_manager']},
            {index: '/home/iqcData', title: 'IQC数据管理', component: 'material/IqcData', roles: ['bus_data_manager']},
        ]
    }, {
        index: '/home/urgentRule',
        title: '加急管理',
        icon: 'el-icon-setting',
        component: 'businessOrder/UrgentRule',
        roles: ['oper_urgent'],
    }, {
        index: '/home/user',
        title: '用户管理',
        icon: 'el-icon-user',
        component: 'admin/User',
        roles: ['admin'],
    }, {
        index: 'lineReport',
        title: '大屏看板',
        icon: 'el-icon-data-line',
        items: [
            {index: '#/board/packMaterial', title: '包材需求看板', roles: [...allRoles]},
            {index: '#/board/inStore', title: '在库时效看板', roles: [...allRoles]},
            {index: '#/lineReport/line1', title: '产线看板1', roles: [...allRoles]},
            {index: '#/lineReport/line2', title: '产线看板2', roles: [...allRoles]},
            {index: '#/lineReport/line3', title: '产线看板3', roles: [...allRoles]},
            {index: '#/lineReport/line4', title: '产线看板4', roles: [...allRoles]},
            {index: '#/lineReport/line5', title: '产线看板5', roles: [...allRoles]},
        ]
    }, {
        index: '/home/scanTimeTest',
        title: '扫码时间测试',
        icon: 'el-icon-timer',
        component: 'scantime/ScanTimeTest',
        roles: ['admin'],
    }, {
        index: '/home/help',
        title: '帮助',
        icon: 'el-icon-question',
        component: 'Help',
        roles: [...allRoles],
    }
]