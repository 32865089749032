<template>
  <el-container>
    <el-aside width="200px">
      <el-menu
          :router="true"
          :unique-opened="true"
          @select="select"
          active-text-color="#409EFF">
        <template v-for="menu in menuList">
          <el-submenu v-if="menu.items&&menu.items.length>0&&showSubMenu(menu)" :index="menu.index" :key="menu.index">
            <template slot="title">
              <i :class="menu.icon"></i>
              <span slot="title">{{ menu.title }}</span>
              <el-badge v-if="helpTaskCnt>0 && menu.index=='help'" :value="helpTaskCnt" class="task-badge" :max="99"></el-badge>
            </template>
            <template v-for="item in menu.items">
              <el-menu-item v-if="showMenuItem(item.index)" :key="item.index" :index="item.index">
                {{ item.title }}
                <el-badge v-if="helpTaskCnt>0&&item.index=='/home/help/task'" :value="helpTaskCnt" class="task-badge" :max="99"></el-badge>
              </el-menu-item>
            </template>
          </el-submenu>
          <el-menu-item v-if="!menu.items&&showMenuItem(menu.index)" :key="menu.index" :index="menu.index">
            <i :class="menu.icon"></i>
            <span slot="title">{{ menu.title }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-aside>
    <el-main>
      <keep-alive>
        <router-view/>
      </keep-alive>
    </el-main>
  </el-container>
</template>

<script>
import {menuList} from "@/utils/menu";

export default {
  name: "BusinessLayout",
  data() {
    return {
      menuList: menuList,
    }
  },
  computed: {
    helpTaskCnt() {
      return this.$store.state.helpTaskCnt
    }
  },
  methods: {
    showSubMenu(menu) {
      let filter = menu.items.filter(item => {
        return this.showMenuItem(item.index)
      });
      return filter.length > 0
    },
    showMenuItem(path) {
      let route = this.getRoute(path);
      if (!route) {
        return false
      }
      let roles = route.meta.roles || []
      if (roles.length == 0) {
        return true
      }
      return this.$store.getters.hasAnyRole(roles)
    },
    getRoute(path) {
      let routes = this.$router.getRoutes();
      for (let route of routes) {
        if (route.path === path || '#' + route.path === path) {
          return route;
        } else if (route.path === '/lineReport/:lineCode' && path.startsWith('#/lineReport/')) {
          return route;
        }
      }
      return null;
    },
    select(path) {
      if (path.startsWith('#/lineReport/line') || path.startsWith('#/board/')) {
        let routeData = this.$router.resolve({
          //去掉开头防使用index做router的#符
          path: path.replace("#", "")
        });
        window.open(routeData.href, "_blank");
      }
    }
  }
}
</script>

<style scoped>
.el-container {
  border: 1px solid #EBEEF5;
  min-height: 600px;
}

.el-menu {
  height: 100%;
}

</style>