export default function createSocket(callback) {
    let socket;
    if (!window.WebSocket) {
        window.WebSocket = window.MozWebSocket;
    }
    if (!window.WebSocket) {
        console.error('Your browser does not support Web Socket.');
    }
    const create = function (onmessageWS) {
        socket = new WebSocket(process.env.VUE_APP_WEB_SOCKET_URL);
        socket.onopen = onopenWS
        socket.onmessage = onmessageWS
        socket.onerror = onerrorWS
        socket.onclose = oncloseWS
    }

    const onopenWS = function () {
        console.log('Web Socket opened!')
    };
    const oncloseWS = function () {
        console.log('Web Socket closed!');
    }
    const onerrorWS = function () {
        console.log('Web Socket error!');
        socket && socket.close()
    }

    const notOk = function () {
        return socket.readyState != 1;
    }

    /**
     * 发送消息，json字符串或对象
     * @param message
     * @returns {boolean}
     */
    const send = function (message) {
        if (!window.WebSocket) {
            console.error('Your browser does not support Web Socket.');
            return false;
        }
        console.log(socket.readyState, message)
        if (socket.readyState === 1) {
            if (typeof message == 'object') {
                socket.send(JSON.stringify(message));
            } else {
                socket.send(message);
            }
            return true;
        } else {
            console.error('The socket is not open.');
        }
        return false;
    }
    create(callback);
    setInterval(function () {
        if (socket.readyState == 2 || socket.readyState == 3) {
            create(callback);
        }
    }, 5000);
    return {socket, send, notOk}
}
