export function baseUrl() {
    let baseUrl = process.env.VUE_APP_AXIOS_BASE_URL;
    if (!baseUrl.startsWith('http')) {
        baseUrl = window.location.protocol + "//" + window.location.host + baseUrl;
    }
    return baseUrl;
}

export const params = function (url, name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = url.substr(1).match(reg);
    if (r != null) {
        return decodeURI(r[2]);
    }
    return null;
};

/**
 * 重置表单
 * @param refName 表单引用名称
 */
export function resetForm(refName) {
    if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
    }
}

/**
 *  获取昨日日期
 */
export function yesterday() {
    var date = new Date();
    date.setTime(date.getTime() - 24 * 60 * 60 * 1000)
    var Y = date.getFullYear();
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var current = Y + '-' + M + '-' + D;
    return current;
}

/**
 *  获取当前日期时间
 */
export function currentDate() {
    var date = new Date();
    var Y = date.getFullYear();
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    var current = Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s;
    return current;
}

/**
 *  获取当前日期时间
 */
export function currentDay() {
    var date = new Date();
    var Y = date.getFullYear();
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var current = Y + '-' + M + '-' + D;
    return current;
}

/**
 * 获取两个日期之间的天数
 * @param fromDateStr 开始日期字符串
 * @param toDateStr 结束日期字符串
 * @returns {number}
 */
export function betweenDays(fromDateStr, toDateStr) {
    let fromDate = new Date(fromDateStr);
    let toDate = new Date(toDateStr);
    let day = Math.ceil((toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24));
    return day;
}

/**
 * 获取上个月月份字符串，格式'2021-09'
 * @returns {string}
 */
export function getLastMonth() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    if (month == 0) {
        year = year - 1;
        month = 12;
    }
    if (month < 10) {
        month = '0' + month
    }
    return year + "-" + month;
}