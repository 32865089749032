<template>
  <div>
    <el-table :data="itemList" border stripe v-loading="loading" height="500">
      <el-table-column align="center" prop="assetSn" label="资产码" width="210px"></el-table-column>
      <el-table-column align="center" prop="materialCode" label="备件编码" width="110px"></el-table-column>
      <el-table-column align="center" prop="currentStationName" label="当前工站" width="130px"></el-table-column>
      <el-table-column align="center" prop="currentStationUserName" label="当前工站操作人员" width="120px"></el-table-column>
      <el-table-column align="center" prop="currentStationStatusName" label="当前工站工作状态" width="120px"></el-table-column>
      <el-table-column align="center" prop="nextStationName" label="下一工站" width="120px"></el-table-column>
      <el-table-column align="center" prop="lendOrderCode" label="借出单号" width="200px"></el-table-column>
      <el-table-column align="center" prop="materialName" label="备件名称" width="400px"></el-table-column>
      <el-table-column align="center" prop="asnNo" label="预入库单号" width="170px"></el-table-column>
      <el-table-column align="center" prop="granularityName" label="备件粒度" width="100px"></el-table-column>
      <el-table-column align="center" prop="supplierCode" label="供应商代码" width="100px"></el-table-column>
      <el-table-column align="center" prop="supplierName" label="供应商名称" width="240px"></el-table-column>
      <el-table-column align="center" prop="materialSourceName" label="备件来源" width="300px"></el-table-column>
      <el-table-column align="center" prop="supplierSn" label="供应商条码" width="300px"></el-table-column>
      <el-table-column align="center" prop="sortTime" label="分拣时间" width="150px"></el-table-column>
      <el-table-column align="center" prop="sortUserName" label="分拣人员" width="100px"></el-table-column>
      <el-table-column align="center" prop="sortSn" label="出厂SN码" width="250px"></el-table-column>
      <el-table-column align="center" prop="sortBoxNo" label="分拣箱号" width="80px"></el-table-column>
      <el-table-column align="center" prop="sortLineName" label="生产线" width="100px"></el-table-column>
      <el-table-column align="center" prop="sortTestTypeName" label="检验类型" width="100px"></el-table-column>
      <el-table-column align="center" prop="testResultName" label="检验结果" width="200px"></el-table-column>
      <el-table-column align="center" prop="disposeOpinionName" label="处置意见" width="150px"></el-table-column>
      <el-table-column align="center" prop="testResultRemark" label="检验结果描述" width="200px"></el-table-column>
      <el-table-column align="center" prop="test1Result" label="外观检验结果" width="100px" :formatter="resultFormatter"></el-table-column>
      <el-table-column align="center" prop="test1InTime" label="外观检验开始时间" width="170px"></el-table-column>
      <el-table-column align="center" prop="test1OutTime" label="外观检验结束时间" width="170px"></el-table-column>
      <el-table-column align="center" prop="test1UserName" label="外观检验人员" width="100px"></el-table-column>
      <el-table-column align="center" prop="test1CodeName" label="外观故障原因" width="300px"></el-table-column>
      <el-table-column align="center" prop="test1TroubleReason" label="外观故障描述" width="300px"></el-table-column>
      <el-table-column align="center" prop="test1Repairable" label="外观是否可修复" width="110px" :formatter="isFormatter"></el-table-column>
      <el-table-column align="center" prop="test1End" label="外观检验是否结束" :formatter="isFormatter" width="120px"></el-table-column>
      <el-table-column align="center" prop="review1InTime" label="外观不良复检开始时间" width="200px"></el-table-column>
      <el-table-column align="center" prop="review1OutTime" label="外观不良复检结束时间" width="200px"></el-table-column>
      <el-table-column align="center" prop="review1UserName" label="外观不良复检人员" width="300px"></el-table-column>
      <el-table-column align="center" prop="review1End" label="外观不良复检是否结束" :formatter="isFormatter" width="300px"></el-table-column>
      <el-table-column align="center" prop="test2Result" label="电性能检验结果" width="110px" :formatter="resultFormatter"></el-table-column>
      <el-table-column align="center" prop="test2InTime" label="电性能检验开始时间" width="170px"></el-table-column>
      <el-table-column align="center" prop="test2OutTime" label="电性能检验结束时间" width="170px"></el-table-column>
      <el-table-column align="center" prop="test2UserName" label="电性能检验人员" width="110px"></el-table-column>
      <el-table-column align="center" prop="test2CodeName" label="电性能故障原因" width="400px"></el-table-column>
      <el-table-column align="center" prop="test2TroubleReason" label="电性能故障描述" width="300px"></el-table-column>
      <el-table-column align="center" prop="test2End" label="电性能检验是否结束" :formatter="isFormatter" width="160px"></el-table-column>
      <el-table-column align="center" prop="review2InTime" label="电性能不良复检开始时间" width="200px"></el-table-column>
      <el-table-column align="center" prop="review2OutTime" label="电性能不良复检结束时间" width="200px"></el-table-column>
      <el-table-column align="center" prop="review2UserName" label="电性能不良复检人员" width="300px"></el-table-column>
      <el-table-column align="center" prop="review2End" label="电性能不良复检是否结束" :formatter="isFormatter" width="300px"></el-table-column>
      <el-table-column align="center" prop="packInTime" label="包装操作开始时间" width="170px"></el-table-column>
      <el-table-column align="center" prop="packOutTime" label="包装操作结束时间" width="170px"></el-table-column>
      <el-table-column align="center" prop="packUserName" label="包装人员" width="100px"></el-table-column>
      <el-table-column align="center" prop="packEnd" label="包装是否结束" :formatter="isFormatter" width="120px"></el-table-column>
      <el-table-column align="center" prop="returnInTime" label="归还操作开始时间" width="170px"></el-table-column>
      <el-table-column align="center" prop="returnOutTime" label="归还操作结束时间" width="170px"></el-table-column>
      <el-table-column align="center" prop="returnUserName" label="归还人员" width="100px"></el-table-column>
      <el-table-column align="center" prop="returnEnd" label="归还操作是否结束" :formatter="isFormatter" width="140px"></el-table-column>
      <el-table-column align="center" prop="subClass" label="备件小类CODE" width="110px"></el-table-column>
      <el-table-column align="center" prop="subClassName" label="备件小类名称" width="140px"></el-table-column>
      <el-table-column align="center" prop="planCategory" label="计划大类CODE" width="110px"></el-table-column>
      <el-table-column align="center" prop="planCategoryName" label="计划大类名称" width="140px"></el-table-column>
      <el-table-column align="center" prop="materialMarkName" label="备件标识" width="140px"></el-table-column>
      <el-table-column align="center" prop="testRecord.appearanceResult" label="外观检验结果" :formatter="resultFormatter" width="100px"></el-table-column>
      <el-table-column align="center" prop="testRecord.electricityResult" label="电性能检验结果" :formatter="resultFormatter" width="110px"></el-table-column>
      <el-table-column align="center" prop="testRecord.troubleCodeName" label="故障代码" width="400px"></el-table-column>
      <el-table-column align="center" prop="testRecord.troubleCodeRemark" label="故障代码描述" width="300px"></el-table-column>
      <el-table-column align="center" prop="testRecord.checkPersonName" label="检验员" width="100px"></el-table-column>
      <el-table-column align="center" prop="testRecord.remark" label="检验结果描述" width="140px"></el-table-column>
      <el-table-column align="center" prop="testRecord.testReturnWarehouseId" label="检验归还库房" width="140px"></el-table-column>
      <el-table-column align="center" prop="testRecord.testReturnStorageSectionId" label="检验归还库区" width="140px"></el-table-column>
      <el-table-column align="center" prop="testRecord.finallyStorageSectionId" label="最终入库库区" width="140px"></el-table-column>
      <el-table-column align="center" prop="testRecord.storageSectionId" label="入库库区" width="140px"></el-table-column>
      <el-table-column align="center" prop="lendOrderItem.poDoc" label="来源订单号" width="200px"></el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  name: "LendOrderBusItem",
  data() {
    return {
      itemList: [],
      loading: false
    }
  },
  methods: {
    async query(orderId) {
      this.loading = true;
      const {data: res} = await this.$axios.get('busItem/getLendOrderItems?lendOrderId=' + orderId);
      this.loading = false;
      if (res.code !== 0) {
        this.$message.error(res.message)
        return
      }
      this.itemList = res.data;

    },
    isFormatter(row, column, cellValue) {
      if (cellValue == true) {
        return '是';
      }
      if (cellValue == false) {
        return '否';
      }
      if (cellValue == null || cellValue == '') {
        return '';
      }
    },
    resultFormatter(row, column, cellValue) {
      if (cellValue === true) {
        return '正常';
      } else if (cellValue === false) {
        return '不良';
      } else {
        return '';
      }
    },
  }
}
</script>

<style scoped>

</style>