<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      <el-form inline>
        <el-col :span="14">
          <el-form-item>
            <el-input v-model="queryInfo.code" placeholder="归还单号" clearable style="width: 170px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="queryInfo.lenovoCode" placeholder="联想归还单号" clearable style="width: 170px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="queryInfo.lendOrderCode" placeholder="借出单号" clearable style="width: 170px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="queryInfo.materialCode" placeholder="备件编码" clearable style="width: 170px"></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="queryInfo.chargeLineCode" placeholder="运作产线" clearable style="width: 170px">
              <el-option v-for="item in chargeLineCodeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="queryInfo.status" placeholder="归还单状态" clearable style="width: 170px">
              <el-option v-for="item in statusList" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="queryInfo.createUserId" filterable clearable style="width: 170px" placeholder="创建人">
              <el-option v-for="item in userList" :key="item.id" :label="item.userFullName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="currentChange(1)" :loading="loading">搜索</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <span style="float:right">
            <el-form-item>
              <el-input v-model="submitInfo.lenovoCode" placeholder="联想归还单号" style="width: 210px"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input-number v-model="submitInfo.lenovoCnt" :min="0" :max="9999" label="归还单物品数量" style="width: 140px"></el-input-number>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="bindLenovoCode" :loading="binding">绑定单号</el-button>
            </el-form-item>
          </span>
        </el-col>
      </el-form>
      <div class="tip" style="clear: both">
        <el-tooltip placement="bottom">
          <div slot="content">
            1、物品数量是当前归还单里已添加物品的数量，备件总数量是当前借出单当前备件有多少个物品，已收录数量是当前借出单当前备件已经扫码了多个物品。<br/>
            2、抽检、整单等会有备件总数量和已收录数量。<br/>
            3、抽检时，备件总数量=已收录数量时表示抽检物品已齐可上传(可能会有多个内部归还单)。<br/>
            4、【库区A --> 库区B】表示进行了不良品库区拦截，将库区A拦截到库区B，当有拦截时出现。<br/>
            5、捷思目标仓库：捷思系统判的目标仓库；联想目标仓库：联想系统判的目标仓库。如发现判库规则错误，反馈至主管。<br/>
            6、实物以捷思目标仓库为准，上传的检验记录以联想目标库区为准(因联想系统限制原因)，所以可能会出现实物库区和传给联想检验记录的库区不一致的情况，详情咨询主管。
          </div>
          <el-button type="text" style="color: orange;"><i class="el-icon-question"></i>提示</el-button>
        </el-tooltip>
      </div>
      <el-table :data="orderList" stripe border size="mini" @selection-change="handleSelectionChange">
        <el-table-column type="selection" align="center" width="40"/>
        <el-table-column label="内部归还单号" prop="code" align="center" width="160px">
          <template slot-scope="scope">
            <el-link type="primary" @click="showItemDialog(scope.row)" style="font-size: 12px;font-weight: normal">{{ scope.row.code }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="联想归还单号" prop="lenovoCode" align="center" width="195px"></el-table-column>
        <el-table-column label="运作产线" prop="chargeLineName" align="center" width="90"></el-table-column>
        <el-table-column label="检验类型" prop="testTypeName" align="center" width="80"></el-table-column>
        <el-table-column label="捷思目标库区" align="center" width="220px">
          <template slot-scope="scope">{{ scope.row.intercept ? scope.row.storeTypeName + ' --> ' + scope.row.interceptStoreName : scope.row.storeTypeName }}</template>
        </el-table-column>
        <el-table-column label="联想目标库区" prop="lenovoStoreName" align="center" width="180px"></el-table-column>
        <el-table-column label="状态" prop="statusName" align="center" width="60px"></el-table-column>
        <el-table-column label="创建人" prop="createUserName" align="center" width="60px"></el-table-column>
        <el-table-column label="物品数量" prop="itemCnt" align="center" width="70px"></el-table-column>
        <el-table-column label="优先库" prop="priority" align="center" width="60px" :formatter="isFormatter"></el-table-column>
        <el-table-column label="加急" prop="urgent" align="center" width="45px" :formatter="isFormatter"></el-table-column>
        <el-table-column label="计划大类" prop="planCategoryGroup" align="center" width="70px" :formatter="planCategoryGroupFormatter"></el-table-column>
        <el-table-column label="借出单编号" prop="lendOrderCode" align="center" width="210px"></el-table-column>
        <el-table-column label="备件编号" prop="materialCode" align="center" width="100px"></el-table-column>
        <el-table-column label="备件总数量" prop="materialCnt" align="center" width="85px"></el-table-column>
        <el-table-column label="已收录数量" prop="collectedCnt" align="center" width="85px"></el-table-column>
        <el-table-column label="操作" align="left" header-align="center" min-width="165px" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" v-if="scope.row.uploadable" @click="onUpload(scope.row)" :loading="scope.row.loading">上传</el-button>
            <el-button
                type="primary"
                size="mini"
                v-if="scope.row.uploadable&&!scope.row.materialCode&&scope.row.storeType==='188'"
                :loading="scope.row.loading"
                @click="showSplitDialog(scope.row)"
            >拆单
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-box">
        <el-pagination
            :total="total"
            layout="total,prev, pager, next"
            :page-size="pageSize"
            :current-page.sync="queryInfo.pageNo"
            background
            @current-change="currentChange"
        ></el-pagination>
      </div>
    </el-card>

    <el-dialog title="归还单明细" :visible.sync="dialogVisible" :close-on-click-modal="false" width="1200px">
      <ReturnOrderItem ref="orderItemComponent"/>
    </el-dialog>

    <el-dialog :title="dialogTitle" :visible.sync="splitDialogVisible" :close-on-click-modal="false" width="1000px" top="5vh" :before-close="beforeDialogClose">
      <SplitReturnOrder ref="splitReturnOrder"/>
    </el-dialog>

  </div>
</template>

<script>
import ReturnOrderItem from "@/views/returnOrder/ReturnOrderItem";
import Vue from "vue";
import SplitReturnOrder from "@/views/returnOrder/SplitReturnOrder.vue";

export default {
  name: "ReturnOrderList",
  components: {SplitReturnOrder, ReturnOrderItem},
  created() {
    this.getOrderList();
    this.getAllUserList();
  },
  data() {
    return {
      queryInfo: {
        code: '',
        lenovoCode: '',
        status: '',
        chargeLineCode: '',
        pageNo: 1
      },
      submitInfo: {
        selectedCodes: [],
        lenovoCode: '',
        lenovoCnt: 0,
        currentPosition: ''
      },
      userList: [],
      orderList: [],
      multipleSelection: [],
      forceUpload: false,
      binding: false,
      loading: false,
      total: 0,
      pageSize: 20,
      dialogVisible: false,
      splitDialogVisible: false,
      dialogTitle: '归还单拆单'
    }
  },
  computed: {
    statusList() {
      return this.$store.getters.getDictList('returnOrderStatus') || [];
    },
    chargeLineCodeList() {
      return this.$store.getters.getDictList('chargeLineCode') || [];
    },
  },
  methods: {
    async getAllUserList() {
      const {data: res2} = await this.$axios.get('user/getAllUser');
      if (res2.code !== 0) {
        return this.$message.error('获取用户名称失败！');
      }
      this.userList = res2.data || [];
    },
    getOrderList() {
      this.loading = true
      this.$axios.post('returnOrder/list', this.queryInfo).then(response => {
        this.loading = false
        let res = response.data;
        if (res.code !== 0) {
          this.$message.error(res.message)
          return
        }
        let orderList = res.data.records || [];
        orderList.forEach(item => item.loading = false)
        this.orderList = orderList;
        this.pageSize = res.data.size;
        this.total = res.data.total
      }, error => {
        this.loading = false;
        return this.$message.error('查询失败，' + error.message);
      })
    },
    showItemDialog(row) {
      if (!row) {
        return;
      }
      this.dialogVisible = true;
      Vue.nextTick(() => {
        this.$refs.orderItemComponent.query(row.code);
      })
    },
    showSplitDialog(row) {
      if (!row) {
        return;
      }
      this.dialogTitle = `归还单拆单【${row.code}】`
      this.splitDialogVisible = true;

      Vue.nextTick(() => {
        this.$refs.splitReturnOrder.query(row.code);
      })
    },
    onUpload(row) {
      if (!row) {
        return;
      }
      //9、索赔库、采购不良库，上传检验记录时提示“请确认客户是否已通知入库？”
      if (row.storeType == '194' || row.storeType == '8759') {
        this.$confirm('请确认客户是否已通知入库？', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确定',
          type: 'warning'
        }).then(() => {
          this.save(row)
        })
      } else {
        this.save(row);
      }
    },
    save(row) {
      row.loading = true
      this.$axios.post('returnOrder/upload', {
        code: row.code,
        forceUpload: this.forceUpload
      }).then(response => {
        let res = response.data;
        row.loading = false
        if (res.code !== 0) {
          this.$message.error(res.message)
          return
        } else {
          this.$message.success("上传成功，请在联想系统中继续操作，并回填联想的归还单号");
        }

        this.getOrderList();
      }, error => {
        row.loading = false
        return this.$message.error('上传失败，' + error.message);
      });
    },
    bindLenovoCode() {
      let currentPosition = localStorage.getItem('current_position') || '';
      if (!currentPosition) {
        return this.$message.error('未设置工位，请重新登录系统并设置工位信息');
      } else {
        this.submitInfo.currentPosition = currentPosition;
      }

      console.log(this.submitInfo.selectedCodes);
      if (this.submitInfo.selectedCodes.length == 0) {
        this.$message.error("未选择内部归还单");
        return;
      }

      if (this.submitInfo.lenovoCode.length == 0) {
        this.$message.error("未输入联想归还单号");
        return;
      }

      if (this.submitInfo.lenovoCnt == 0) {
        this.$message.error("未输入联想归还单物品数量");
        return;
      }

      this.binding = true;
      this.$axios.post('returnOrder/bindLenovoCode', this.submitInfo, {timeout: 60000}).then(response => {
        this.binding = false;
        let res = response.data;
        if (res.code !== 0) {
          this.$message.error(res.message)
          return
        } else {
          this.$message.success("合并成功");
        }
        this.submitInfo.lenovoCode = ''
        this.submitInfo.lenovoCnt = 0

        this.getOrderList();
      }, error => {
        this.binding = false;
        return this.$message.error('绑定失败，' + error.message);
      });


    },
    handleSelectionChange(val) {
      this.multipleSelection = val;

      this.submitInfo.selectedCodes = [];
      for (var i = 0; i < this.multipleSelection.length; i++) {
        this.submitInfo.selectedCodes.push(this.multipleSelection[i].code);
      }
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.getOrderList();
    },
    isFormatter(row, column, cellValue) {
      if (cellValue == true) {
        return '是';
      }
      if (cellValue == false) {
        return '否';
      }
      if (cellValue == null || cellValue == '') {
        return '';
      }
    },
    planCategoryGroupFormatter(row, column, cellValue) {
      if (cellValue == 'A') {
        return '主板';
      }
      return cellValue;
    },
    beforeDialogClose(done) {
      this.currentChange(1)
      done()
    }
  }
}
</script>

<style scoped>
.el-input {
  line-height: 32px;
}

.el-input, .el-table, .el-select {
  font-size: 12px;
}
</style>
