import Vue from 'vue'
import 'normalize.css/normalize.css'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/global.css'
import router from "@/router";
import axiosInstance from "./utils/axios";
import store from "@/store";
import qs from 'qs'
import {baseUrl, resetForm} from "@/utils/common";
import createSocket from "@/utils/websocket";

import dataV from '@jiaminghi/data-view'

Vue.prototype.$baseUrl = baseUrl()
Vue.use(dataV)

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$axios = axiosInstance;
Vue.prototype.$qs = qs;

Vue.prototype.resetForm = resetForm;
let socket = createSocket(function (e) {
    let data = JSON.parse(e.data);
    console.log(data)
    if (data.code !== 0) {
        Vue.prototype.$alert(data.message, '打印错误提示');
    }

});
Vue.prototype.$socket = socket;

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
