import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/Home";
import store from "@/store";
import {menuList} from "@/utils/menu";

Vue.use(VueRouter)

export const publicRoutes = [
    {path: '/', redirect: '/login'},
    {path: '/login', component: () => import('@/views/Login'), meta: {title: '登录'}},
    {path: '/autoTest', component: () => import('@/views/material/AutoTestHome'), meta: {title: '捷思联想检验智能管控系统'}},
    {path: '/report1', component: () => import('@/views/report/Report1')},
    {path: '/lineReport/:lineCode', component: () => import('@/views/report/LineReport')},
    {path: '/repair/testScreen/:lineCode', component: () => import('@/views/board/screen/TestScreen.vue')},
    {path: '/repair/repairScreen/:lineCode', component: () => import('@/views/board/screen/RepairScreen.vue')},
    {path: '/repair/burnOqcScreen/:lineCode', component: () => import('@/views/board/screen/BurnOqcScreen.vue')},
    {path: '/board/packMaterial', component: () => import('@/views/board/PackMaterialBoard.vue')},
    {path: '/board/inStore', component: () => import('@/views/board/InStoreBoard.vue')},
    {path: '/help', component: () => import('@/views/Help')},
    {
        path: '/admin',
        component: () => import('@/views/admin/Home'),
        meta: {title: '捷思联想检验智能管控系统'}
    }
]

let homeMenu = {
    path: '/home',
    component: Home,
    meta: {title: '捷思联想检验智能管控系统'},
    children: [
        {path: '*', component: () => import('@/views/Building')},
    ]
}

menuList.forEach(menu => {
    if (menu.index == 'lineReport') {
        //结束本次循环
        return false;
    }
    if (menu.items) {
        menu.items.forEach(item => {
            let route = {
                path: item.index,
                component: () => import('@/views/' + item.component),
                meta: {
                    title: item.title,
                    roles: item.roles
                }
            }
            homeMenu.children.unshift(route)
        })
    } else {
        let route = {
            path: menu.index,
            component: () => import('@/views/' + menu.component),
            meta: {
                title: menu.title,
                roles: menu.roles
            }
        }
        homeMenu.children.unshift(route)
    }

})

publicRoutes.push(homeMenu)

const createRouter = () => new VueRouter({
    scrollBehavior: () => ({y: 0}),
    routes: publicRoutes
});


const router = createRouter()

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    console.log(to, to.path.startsWith('/repair/'), to.path.toLowerCase())
    if (to.path === '/login'
        || to.path === '/linereport/line1'
        || to.path === '/linereport/line2'
        || to.path === '/linereport/line3'
        || to.path === '/linereport/line4'
        || to.path === '/linereport/line5'
        || to.path.toLowerCase() === '/repair/testscreen/line1'
        || to.path.toLowerCase() === '/repair/testscreen/line2'
        || to.path.toLowerCase() === '/repair/repairscreen/line1'
        || to.path.toLowerCase() === '/repair/repairscreen/line2'
        || to.path.toLowerCase() === '/repair/burnoqcscreen/line1'
        || to.path.toLowerCase() === '/repair/burnoqcscreen/line2'
        || to.path.startsWith('/repair/')
        || to.path === '/board/packMaterial'
        || to.path === '/board/inStore'
        || to.path === '/scantest'
        || to.path === '/help'
    ) {
        return next()
    }
    let token = localStorage.getItem('token');
    if (!token) {
        return next('/login')
    }
    let roles = to.meta.roles || []
    if (to.path.startsWith('/home/') && roles.length > 0 && !store.getters.hasAnyRole(roles)) {
        return next('/home')
    }
    next()
})

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router
