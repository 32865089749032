<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      <el-row style="padding-bottom: 10px">
        <el-col :span="24" style="text-align: left;line-height: 40px">
          <el-input ref="assetSn" v-model="formData.assetSn" placeholder="请输入或扫资产码" @keyup.enter.native="search" style="width: 300px"></el-input>
          <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="search">查询</el-button>
          <MessageComponent ref="searchMessage"/>
        </el-col>
      </el-row>

      <el-tabs v-if="menuType==='qcAppearance'" type="border-card" active-name="first">
        <el-tab-pane key="first" name="first" :label="lendOrder.code||'物品详情'">
          <MaterialInfo :busItem="busItem" :lendOrder="lendOrder" :lend-order-item="lendOrderItem" station="qc"/>
        </el-tab-pane>

        <el-tab-pane v-for="item in busItemList" :key="item.id" :name="item.id" :label="item.lendOrderCode">
          <MaterialInfo :busItem="item" :lendOrder="item.lendOrder" :lend-order-item="item.lendOrderItem" station="qc"/>
        </el-tab-pane>

        <el-tab-pane key="doaReason" name="doaReason" label="DOA故障原因">
          <el-table :data="doaReasonList" border stripe height="262px">
            <el-table-column align="center" prop="assetSn" label="资产码" min-width="220px"></el-table-column>
            <el-table-column align="left" prop="reason" label="DOA故障原因" min-width="500px" header-align="center"></el-table-column>
            <el-table-column align="center" prop="doaDate" label="备案日期" width="150px"></el-table-column>
          </el-table>
        </el-tab-pane>

      </el-tabs>

      <MaterialInfo v-else :busItem="busItem" :lendOrder="lendOrder" :lend-order-item="lendOrderItem" station="appearance"/>

      <el-form :model="formData" label-width="130px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="检验类型：">
              <el-select v-model="formData.sortTestType" disabled class="form-item">
                <el-option label="外观" value="appearance"></el-option>
                <el-option label="电性能" value="electric"></el-option>
                <el-option label="金钥匙" value="goldenKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="检验提示：">
              <div class="fun-tip">{{ ruleText }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <template v-if="!isKeyboard">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="外观检验结果：">
                    <el-radio-group v-model="formData.test1Result" @change="calcResult">
                      <el-radio :label="true">正常</el-radio>
                      <el-radio :label="false">不良</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="外观是否可修复：" v-if="this.formData.test1Result===false">
                    <el-radio-group v-model="formData.test1Repairable">
                      <el-radio :label="true">是</el-radio>
                      <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="维修商代码：">
                    <el-select v-model="formData.test1RepairerCode" filterable clearable class="form-item">
                      <el-option
                          v-for="item in repairerCodeList"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="外采标签：">
                    <el-input v-model.trim="formData.outsourcedLabel" placeholder="外采商到货标签" maxlength="20" class="form-item"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="this.formData.test1Result===false">
                <el-col :span="12">
                  <el-form-item label="是否可翻新：" v-if="formData.test1Repairable===true&&busItem.planCategory=='011'">
                    <el-radio-group v-model="formData.test1Refurbishable">
                      <el-radio :label="true">是</el-radio>
                      <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>

            <template v-if="isKeyboard">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="外观检验结果：">
                    <el-select v-model="formData.busItemInfo.test1ResultType" class="form-item" @change="test1ResultTypeChange">
                      <el-option v-for="item in keyboardTest1ResultTypeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="外观是否可修复：" v-if="formData.busItemInfo.test1ResultType&&formData.busItemInfo.test1ResultType!='ok'">
                    <el-radio-group v-model="formData.test1Repairable" :disabled="true">
                      <el-radio :label="true">是</el-radio>
                      <el-radio :label="false">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>


            <el-row v-if="this.formData.test1Result===false">
              <el-col :span="12">
                <el-form-item label="外观不良代码：">
                  <el-select v-model="formData.test1TroubleCode" filterable placeholder="请选择外观不良代码" class="form-item">
                    <el-option
                        v-for="item in troubleList"
                        :key="item.code"
                        :label="item.code+' '+item.name"
                        :value="item.code"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="外观不良原因：">
                  <el-input v-model="formData.test1TroubleReason" type="textarea" :rows="2" class="form-item"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <template v-if="busItem.sortTestType=='appearance'||isKeyboard&&formData.busItemInfo.test1ResultType=='ng_no_power'">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="检验结果：">
                    <el-select v-model="formData.testResult" class="form-item">
                      <el-option
                          v-for="item in testResultList"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="检验结果描述：">
                    <el-input v-model="formData.testResultRemark" type="textarea" :rows="2" class="form-item"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="处置意见：">
                    <el-select v-model="formData.disposeOpinion" class="form-item">
                      <el-option
                          v-for="item in disposeOpinionList"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>

            <el-row>
              <el-col :span="12">
                <el-form-item label="贴纸技服码：" v-if="needStickerCode">
                  <el-input v-model.trim="formData.busItemInfo.stickerCode" placeholder="贴纸技服码" maxlength="20" class="form-item"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="外观检验照片：">
                  <PhotographComponent @setImgIds="setImgIds" ref="imageUpload"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="检验备注：">
                  <el-input v-model="formData.busItemInfo.testRemark" type="textarea" :rows="2" class="form-item"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检验指导图片：" v-if="guideImageList.length>0">
              <div style="width: 425px">
                <ImageViewerComponent @imageClick="guideImageClick" :image-list="guideImageList"/>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="check-tip-title" v-if="categoryGroupList.length>0">【{{ busItem.planCategoryName }}】检验项目：</div>
        <el-row v-for="item in categoryGroupList" :key="item.categoryType" class="border-top">
          <el-col :span="3">
            <el-form-item label="" style="color: #606266;font-weight: bold" label-width="0px">{{ item.categoryType }}</el-form-item>
          </el-col>
          <el-col :span="21">
            <el-row>
              <el-col :span="12" v-for="subItem in item.items" :key="subItem.name">
                <el-row>
                  <el-form-item style="text-align: left" :label="subItem.categoryName+'：'" label-width="185px">
                    <el-radio-group v-model="subItem.categoryResult">
                      <el-radio :label="true">OK</el-radio>
                      <el-radio :label="false">NG</el-radio>
                    </el-radio-group>
                    <span class="tip" style="padding-left: 10px">{{ subItem.categoryTip }}</span>
                  </el-form-item>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="联想目标库区：" v-if="lenovoStore&&showLenovoSelect">
            <el-select v-model="formData.storeType" filterable placeholder="请选择联想目标库区" class="form-item">
              <el-option
                  v-for="item in lenovoStoreList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="5" style="text-align: right">
            <el-button type="primary" @click="submit" :loading="submitLoading">作业结果提交</el-button>
          </el-col>
          <el-col :span="19">
            <MessageComponent ref="commitMessage"/>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

  </div>
</template>

<script>
import MaterialInfo from "@/views/material/MaterialInfo";
import MessageComponent from "@/components/MessageComponent";
import PhotographComponent from "@/components/PhotographComponent";
import ImageViewerComponent from "@/components/ImageViewerComponent";

export default {
  name: "AppearanceTest",
  components: {ImageViewerComponent, PhotographComponent, MaterialInfo, MessageComponent},
  props: {
    menuType: {type: String, required: true}
  },
  data() {
    return {
      formData: {
        assetSn: '',
        sortTestType: '',
        test1Result: undefined,
        test1Repairable: '',
        test1Refurbishable: '',
        test1RepairerCode: '',
        test1ImageIds: [],
        test1TroubleReason: '',
        test1TroubleCode: '',
        testResult: '',
        disposeOpinion: '',
        currentPosition: '',
        storeType: '',
        judgeStoreType: '',
        busItemInfo: {
          testRemark: '',
          stickerCode: '',
          test1RepairLocation: '',
          test1ResultType: '',
          test1NgType: '',
        },
        categoryRecords: []
      },
      canCheck: false,
      mustPhotograph: false,
      needStickerCode: false,
      busItem: {},
      busItemList: [],
      doaReasonList: [],
      lendOrder: {},
      lendOrderItem: {},
      troubleList: [],
      guideImageList: [],
      guideImageOpen: {},
      loading: false,
      submitLoading: false,
      ruleText: '',
      lenovoStoreList: [],
      showLenovoSelect: false,
      categoryGroupList: []
    }
  },
  computed: {
    disposeOpinionList() {
      return this.$store.getters.getDictList('disposeOpinion') || [];
    },
    testResultList() {
      return this.$store.getters.getDictList('testResult') || [];
    },
    storeTypeList() {
      return this.$store.getters.getDictList('storeType') || [];
    },
    repairerCodeList() {
      return this.$store.getters.getDictList('repairerCode') || [];
    },
    test1NgTypeList() {
      return this.$store.getters.getDictList('test1NgType') || [];
    },
    test1RepairLocationList() {
      return this.$store.getters.getDictList('test1RepairLocation') || [];
    },
    keyboardTest1ResultTypeList() {
      let list = this.$store.getters.getDictList('keyboardTest1ResultType') || [];
      return list.filter(item => ['ok', 'ng', 'ng_repairable', 'ng_no_power'].includes(item.value))
    },
    lastTest() {
      return this.busItem.sortTestType == 'appearance' && this.formData.test1Result === true
    },
    lenovoStore() {
      return this.lastTest && this.busItem.lenovoJudgeStore
    },
    judgeStoreType() {
      if (this.lenovoStore && this.showLenovoSelect) {
        return 'lenovo_more'
      } else if (this.lenovoStore) {
        return 'lenovo_one'
      }
      return 'system_auto'
    },
    isKeyboard() {
      return this.busItem.busItemInfo?.keyboardAte
    }
  },
  methods: {
    calcResult() {
      if (this.busItem.sortTestType == 'appearance') {
        if (this.formData.test1Result) {
          this.formData.testResult = '1'
          this.formData.disposeOpinion = '20'
        } else {
          this.formData.testResult = undefined
          this.formData.disposeOpinion = undefined
        }
      }

    },
    resetValues() {
      this.formData.sortTestType = ''
      this.formData.test1Result = undefined
      this.formData.test1Repairable = ''
      this.formData.test1Refurbishable = ''
      this.formData.test1RepairerCode = ''
      this.formData.test1ImageIds = []
      this.formData.test1TroubleReason = ''
      this.formData.test1TroubleCode = ''
      this.formData.testResult = ''
      this.formData.disposeOpinion = ''
      this.formData.testResultRemark = ''
      this.formData.storeType = '';
      this.formData.busItemInfo.testRemark = '';
      this.formData.busItemInfo.stickerCode = '';
      this.formData.busItemInfo.test1RepairLocation = '';
      this.formData.busItemInfo.test1ResultType = '';
      this.formData.busItemInfo.test1NgType = '';
      this.lenovoStoreList = [];
      this.showLenovoSelect = false;
      this.categoryGroupList = []
      this.formData.categoryRecords = []

      this.canCheck = false;
      this.mustPhotograph = false;
      this.needStickerCode = false;
      this.busItem = {};
      this.busItemList = [];
      this.doaReasonList = [];
      this.lendOrder = {};
      this.lendOrderItem = {};
      this.troubleList = [];
      this.guideImageList = [];
      this.guideImageOpen = {};
      this.loading = false;
      this.submitLoading = false;
      this.ruleText = ''
      this.$refs.imageUpload.onClear();
    },
    async submit() {
      this.$refs.commitMessage.clear();
      if (!this.lastTest) {
        this.formData.storeType = '';
        this.lenovoStoreList = [];
        this.showLenovoSelect = false;
      }
      let currentPosition = localStorage.getItem('current_position') || '';
      if (!currentPosition) {
        return this.$refs.commitMessage.showError('未设置工位，请重新登录系统并设置工位信息');
      } else {
        this.formData.currentPosition = currentPosition;
      }
      if (!this.formData.assetSn) {
        return this.$refs.commitMessage.showError('请输入或扫资产码');
      }
      if (!this.canCheck) {
        return this.$refs.commitMessage.showError('该资产码不符合' + (this.menuType === 'appearance' ? '外观' : '品质分析(外观)') + '检验条件');
      }

      if (this.isKeyboard) {
        let info = this.formData.busItemInfo || {};
        if (!info.test1ResultType) {
          return this.$refs.commitMessage.showError('请选择外观检验结果');
        }

        if (info.test1ResultType != 'ok') {
          if (this.formData.test1Repairable !== true && this.formData.test1Repairable !== false) {
            return this.$refs.commitMessage.showError('请选择外观是否可修复');
          }

        }

        //键盘ATE的，全部给联想推送外观不良，不管检验结果选的哪个
        this.formData.test1Result = false

      }


      if (this.formData.test1Result !== true && this.formData.test1Result !== false) {
        return this.$refs.commitMessage.showError('请选择外观检验结果');
      }

      //不良时校验不良字段
      if (!this.formData.test1Result) {
        if (this.formData.test1Repairable === '') {
          return this.$refs.commitMessage.showError('请选择外观是否可修复');
        }
        if (this.formData.test1Repairable === true && this.busItem.planCategory == '011' && this.formData.test1Refurbishable === '') {
          return this.$refs.commitMessage.showError('请选择是否可翻新');
        }

        if (this.formData.test1TroubleCode === '') {
          return this.$refs.commitMessage.showError('请选择外观不良代码');
        }

        if (this.isKeyboard && !this.formData.test1TroubleReason) {
          return this.$refs.commitMessage.showError('请填写外观不良原因');
        }
      }

      if (this.busItem.sortTestType == 'appearance' || (this.isKeyboard && this.formData.busItemInfo.test1ResultType == 'ng_no_power')) {
        if (!this.formData.testResult) {
          return this.$refs.commitMessage.showError('请选择检验结果');
        } else if (this.formData.test1Result && this.formData.testResult != '1') {
          return this.$refs.commitMessage.showError('检验结果必须为合格');
        }
        if (!this.formData.disposeOpinion) {
          return this.$refs.commitMessage.showError('请选择处置意见');
        } else if (this.formData.test1Result && this.formData.disposeOpinion != '20') {
          return this.$refs.commitMessage.showError('处置意见必须为合格');
        }
      }

      if (this.notOpenGuide()) {
        return this.$refs.commitMessage.showError('请按照每张检验指导图片进行作业。');
      }

      this.formData.categoryRecords = []
      if (this.categoryGroupList && this.categoryGroupList.length > 0) {
        let allOK = true;
        for (let categoryGroup of this.categoryGroupList) {
          for (let categoryObj of categoryGroup.items) {
            if (categoryObj.categoryResult !== true && categoryObj.categoryResult !== false) {
              return this.$refs.commitMessage.showError('请按检验项目【' + categoryGroup.categoryType + '：  ' + categoryObj.categoryName + '】进行检验');
            }
            if (categoryObj.categoryResult === false) {
              allOK = false
            }
            this.formData.categoryRecords.push({
              categoryId: categoryObj.id,
              categoryResult: categoryObj.categoryResult
            })
          }
        }
        if (!allOK && this.formData.test1Result) {
          return this.$refs.commitMessage.showError('检验项目存在NG时，外观检验结果必须为不良');
        } else if (allOK && !this.formData.test1Result) {
          return this.$refs.commitMessage.showError('检验项目全部OK时，外观检验结果必须为合格');
        }
      }

      if (this.mustPhotograph && this.formData.test1ImageIds.length < 1) {
        return this.$refs.commitMessage.showError('请拍检验照片');
      }

      if (!this.formData.storeType && this.lenovoStore) {
        if (this.lenovoStoreList.length == 0) {
          this.submitLoading = true;
          const {data: storeRes} = await this.$axios.post('/busItem/judgeStore', this.formData);
          this.submitLoading = false;
          if (storeRes.code !== 0) {
            return this.$refs.commitMessage.showError(storeRes.message);
          }
          this.lenovoStoreList = storeRes.data || [];
          if (this.lenovoStoreList.length == 0) {
            this.$message.error('联想判保接口没有返回库区，请将条码及检验结果信息给主管，找联想协助检查判库规则')
          }
        }
        if (this.lenovoStoreList.length == 1) {
          this.formData.storeType = this.lenovoStoreList[0].id;
        } else {
          this.showLenovoSelect = true
          return this.$refs.commitMessage.showError("请选择联想目标仓库");
        }
      }

      this.formData.judgeStoreType = this.judgeStoreType
      this.submitLoading = true;
      let url = '/busItem/saveTest1Result'
      if (this.menuType === 'qcAppearance') {
        url = '/busItem/saveQcTest1Result'
      }
      const {data: res} = await this.$axios.post(url, this.formData);
      this.submitLoading = false;
      if (res.code !== 0) {
        return this.$refs.commitMessage.showError(res.message);
      }
      this.$refs.commitMessage.showSuccess('提交成功，下一工站为：' + res.data.nextStationName);
      this.resetValues();
      this.formData.assetSn = '';
      this.$refs.assetSn.focus();
    },
    async search() {
      this.resetValues();
      this.$refs.searchMessage.clear();
      this.$refs.commitMessage.clear();

      if (!this.formData.assetSn) {
        this.$refs.searchMessage.showError('请输入或扫资产码');
        return;
      }
      this.formData.assetSn = this.formData.assetSn.trim();

      this.loading = true;
      let url = '/busItem/queryInfoForTest1'
      if (this.menuType === 'qcAppearance') {
        url = '/busItem/queryInfoForQcTest1'
      }
      const {data: res} = await this.$axios.get(url, {
        params: {assetSn: this.formData.assetSn}
      });
      this.loading = false;

      if (res.code !== 0) {
        this.$refs.searchMessage.showError(res.message);
        return
      }

      if (!res.data.busItem) {
        return this.$refs.searchMessage.showError('资产码不正确');
      }

      this.busItem = res.data.busItem || {};
      this.busItemList = res.data.busItemList || [];
      this.doaReasonList = res.data.doaReasonList || [];
      this.busItem.busItemInfo = this.busItem.busItemInfo || {};
      this.lendOrder = res.data.lendOrder || {};
      this.lendOrderItem = res.data.lendOrderItem || {};
      this.formData.sortTestType = this.busItem.sortTestType || '';
      this.formData.test1Result = this.busItem.test1Result || '';
      this.formData.testResult = this.busItem.testResult
      this.formData.testResultRemark = this.busItem.testResultRemark;
      this.formData.disposeOpinion = this.busItem.disposeOpinion
      this.formData.busItemInfo.testRemark = this.busItem.busItemInfo.testRemark
      this.troubleList = res.data.troubleList || [];

      let categoryList = res.data.categoryList || []
      this.categoryGroupList = []
      for (let item of categoryList) {
        item.categoryResult = true
        /*//检验项目：主板外设置默认
        if (!['041', '051', '078', '079'].includes(this.busItem.planCategory)) {
          item.categoryResult = true
        }*/
        let categoryObj = this.categoryGroupList.find(obj => {
          return obj.categoryType === item.categoryType
        });
        if (categoryObj) {
          categoryObj.items.push(item);
        } else {
          this.categoryGroupList.push({
            categoryType: item.categoryType,
            items: [item]
          })
        }
      }

      let testGuideImage = res.data.testGuideImage;
      if (testGuideImage) {
        let arr = [];
        for (let i = 1; i <= 3; i++) {
          let id = testGuideImage['image' + i + 'Id'];
          if (id) {
            let url = testGuideImage['image' + i + 'Url'];
            arr.push({id: id, url: url});
            this.guideImageOpen[id] = false
          }
        }
        this.guideImageList = arr;
      }

      this.ruleText = res.data.ruleText || '';
      let inLineCnt = this.busItem.busItemInfo.inLineCnt || 0;
      if (inLineCnt > 2) {
        this.ruleText = '【第' + inLineCnt + '次来货】' + this.ruleText
      }
      this.mustPhotograph = res.data.mustPhotograph || false;
      this.needStickerCode = res.data.needStickerCode || false;

      if (res.data.canCheck === false) {
        return this.$refs.searchMessage.showError('该物品需要高级检验人员检验');
      }

      if (!['appearance', 'qcAppearance'].includes(this.busItem.currentStation)) {
        let msg = '该物品当前工站不是' + (this.menuType === 'appearance' ? '外观' : '品质分析(外观)') + '工站';
        if (this.busItem.currentStationStatus == 'finish' && this.busItem.nextStationName) {
          msg += '，工站为：' + this.busItem.nextStationName;
        } else if (this.busItem.currentStationName) {
          msg += '，工站为：' + this.busItem.currentStationName;
        }
        return this.$refs.searchMessage.showError(msg);
      } else if (this.busItem.currentStationStatus == 'finish') {
        return this.$refs.searchMessage.showError('当前工站已完成，下一工站为：' + this.busItem.nextStationName);
      }
      this.canCheck = true;

    },
    setImgIds(idList) {
      this.formData.test1ImageIds = idList || []
    },
    guideImageClick(id) {
      this.guideImageOpen[id] = true
    },
    notOpenGuide() {
      let notOpen = false;
      if (!this.guideImageList || this.guideImageList.length == 0) {
        return notOpen;
      }
      for (let key in this.guideImageOpen) {
        if (this.guideImageOpen[key] === false) {
          notOpen = true;
          break;
        }
      }
      return notOpen;
    },
    test1ResultTypeChange() {
      this.formData.testResult = undefined
      this.formData.disposeOpinion = undefined
      this.formData.testResultRemark = undefined
      if (this.formData.busItemInfo.test1ResultType === 'ok') {
        this.formData.test1Repairable = undefined
      } else if (this.formData.busItemInfo.test1ResultType === 'ng') {
        this.formData.test1Repairable = false
      } else if (this.formData.busItemInfo.test1ResultType === 'ng_repairable') {
        this.formData.test1Repairable = true
      } else if (this.formData.busItemInfo.test1ResultType === 'ng_no_power') {
        this.formData.test1Repairable = false
        this.formData.testResult = '3'
        this.formData.disposeOpinion = '40'
        this.formData.testResultRemark = '外观不良'
      }
      this.formData.test1Result = false
    }
  }
}
</script>

<style scoped>
.fun-tip {
  font-weight: bold;
  font-size: 16px;
  color: red;
}

.form-item {
  width: 320px;
}

.check-tip-title {
  padding-bottom: 5px;
  padding-top: 10px;
  color: #0000FF;
  font-weight: bold;
}

.border-top {
  border-top: 1px solid #ddd;
}

.el-tabs {
  margin-bottom: 20px;
  box-shadow: inherit;
}
</style>
