<template>
  <div>
    <el-upload ref="myUpload"
               :action="uploadUrl"
               :headers="uploadHeaders"
               :data="data"
               :on-success="handleUploadSuccess"
               :on-remove="handleRemove"
               :before-upload="beforeUpload"
               multiple
               :limit="limit"
               :accept="accept"
               :file-list="fileList">
      <el-button type="text">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: "DocUploadComponent",
  props: {
    limit: {
      type: Number,
      default: 1
    },
    accept: {
      type: String,
      default: '.json'
    },
    url: {
      type: String,
      default: 'upload/singleFile'
    },
    sn: {
      type: String
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      baseUrl: '',
      uploadUrl: '',
      uploadHeaders: {},
      fileList: []
    }
  },
  created() {
    //重要设置
    this.uploadUrl = process.env.VUE_APP_AXIOS_IMG_BASE_URL + this.url;
    this.uploadHeaders = {token: localStorage.getItem('token')}
  },
  methods: {
    beforeUpload(rawFile) {
      if (!this.sn) {
        return true;
      }

      let fileName = rawFile?.name?.toUpperCase();
      if (!fileName) {
        return false;
      }

      //ColorCalibration_8SSD11D69632B1CQ34100XP.zip
      let str = 'COLORCALIBRATION_' + this.sn.toUpperCase() + '.ZIP'
      if (fileName == str) {
        return true;
      }

      this.$message.error(`所选文件和当前资产码【${this.sn}】不符`)
      return false;
    },
    handleUploadSuccess() {
      this.onChange();
    },
    handleRemove() {
      this.onChange();
    },
    onChange() {
      let docList = [];
      for (let i = 0; i < this.$refs.myUpload.uploadFiles.length; i++) {
        let response = this.$refs.myUpload.uploadFiles[i].response;
        let uploadData = response.data;
        if (response.code === 0 && uploadData) {
          docList.push(uploadData.id)
        } else {
          this.$message.error(response.message);
          this.$refs.myUpload.uploadFiles.splice(i, 1);
        }

      }
      //回传文件id到父对象
      this.$emit('docChange', docList)

    },
    onClear() {
      this.$refs.myUpload.uploadFiles = [];
    }
  }
}
</script>

<style scoped>

</style>