<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      <el-row style="padding-bottom: 10px">
        <el-col :span="24" style="text-align: left;line-height: 40px">
          <el-autocomplete
              v-model="formData.code"
              @change="codeChange"
              placeholder="请输入借出单号"
              :fetch-suggestions="queryLendOrderCode"
              @keyup.enter.native="queryOrder"
              style="width: 300px"
          ></el-autocomplete>
          <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="queryOrder">查询</el-button>
          <MessageComponent ref="searchMessage"/>
        </el-col>
      </el-row>
      <el-form :model="formData" label-width="150px">
        <OrderInfo :order="order"/>
        <el-form-item label="备件编码：" :required="true">
          <el-select v-model="formData.materialCode" filterable @change="materialCodeChange" placeholder="请选择备件编码" class="form-item">
            <el-option v-for="item in itemList" :key="item.id" :label="item.materialCode+'【'+item.materialName+'】'" :value="item.materialCode"></el-option>
          </el-select>
          <el-switch v-if="codeNameRepeat"
                     v-model="codeNameRepeatConfirm"
                     style="margin-left: 10px"
                     active-text="我已确认备件编码无误">
          </el-switch>
          <div style="display: inline-block;padding-left: 10px" v-show="materialCountTip">
            检验要求/已分拣：
            外观：<span class="line-tip" v-text="countTip.test1Total+' - '+countTip.test1"></span>
            电性能：<span class="line-tip" v-text="countTip.test2Total+' - '+countTip.test2"></span>
          </div>
          <div style="display: inline-block;padding-right: 20px" class="tip" v-show="needGoldenKey">
            该备件将做金钥匙测试，请选择可做金钥匙检验的生产线。
          </div>
        </el-form-item>
        <el-form-item label="提示：" v-if="showUrgentTip">
          <div style="display: inline-block;padding-right: 20px;color: red;font-size: 18px" v-show="true">
            该备件加急，请优先处理。
          </div>
        </el-form-item>
        <el-form-item label="备件已分拣明细：" v-show="materialCountTip">
          <div class="line-tip">已分拣总数量：<span style="padding-right: 10px">{{ countTip.itemList.length }}</span>
            <el-button @click="changeShowInfo" type="text">{{ openTableTip ? '隐藏明细' : '显示明细' }}</el-button>
          </div>
          <el-table v-show="openTableTip" :data="countTip.itemList" size="mini" border highlight-current-row height="300" style="width: 900px;">
            <el-table-column align="center" label="资产码" prop="assetSn" width="210"></el-table-column>
            <el-table-column align="center" label="出厂SN码" prop="sortSn" width="210"></el-table-column>
            <el-table-column align="center" label="检验类型" prop="sortTestTypeName"></el-table-column>
            <el-table-column align="center" label="生产线" prop="sortLineName"></el-table-column>
            <el-table-column align="center" label="箱号" prop="sortBoxNo"></el-table-column>
          </el-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="检验类型：" :required="true">
              <el-select v-model="formData.sortTestType" @change="lineCheck" placeholder="请选择检验类型" class="form-item">
                <el-option label="外观" value="appearance"></el-option>
                <el-option label="电性能" value="electric"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="资产码：">
              <el-input v-model="formData.assetSn" ref="assetSn" @change="assetSnChange" placeholder="请输入资产码" maxlength="40" class="form-item"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出厂SN码：">
              <el-input ref="materialSn" v-model="formData.materialSn" maxlength="40" placeholder="请输入出厂SN码" class="form-item"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="分拣数量：">
              <el-input v-model="formData.sortNum" ref="sortNum" type="number" max="10000" min="0" placeholder="请输入分拣数量" class="form-item"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="资产码打印类型：" :required="true">
              <el-select v-model="formData.printType" placeholder="请选择资产码打印类型" class="form-item">
                <el-option label="不打印" value="1"></el-option>
                <el-option label="内码" value="2"></el-option>
                <el-option label="内码+外码" value="3"></el-option>
                <el-option label="外码" value="4"></el-option>
                <el-option label="2个内码" value="5"></el-option>
                <el-option label="2个内码+外码" value="6"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生产线：" :required="true">
              <div v-if="lineSelectDisabled" style="color: #0000FF;font-size: 16px">{{ fixLineName }}</div>
              <el-select v-else v-model="formData.lineCode" placeholder="请选择生产线" class="form-item">
                <el-option
                    v-for="option in capacityList"
                    :key="option.lineCode"
                    :label="option.lineName"
                    :value="option.lineCode"
                    :disabled="needGoldenKey&&!option.canGoldenKey"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="箱号：" :required="true">
              <el-input v-model="formData.sortBoxNo" placeholder="请输入箱号" class="form-item"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="区别码1：">
              <el-input v-model="formData.diff1" placeholder="资产码左侧区别码" maxlength="1" class="form-item"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="区别码2：">
              <el-input v-model="formData.diff2" placeholder="资产码右侧区别码" maxlength="1" class="form-item"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="原包装处理方式：">
              <el-select v-model="formData.originalPackMaterialType" placeholder="请选择原包装处理方式" class="form-item" clearable>
                <el-option v-for="item in originalPackMaterialTypeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="旧乐BOX：" v-if="order.testType=='20'">
              <el-input v-model="formData.sortBoxId" maxlength="40" placeholder="请扫乐BOX条码" class="form-item" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期代码：" v-if="showProductionDateCode">
              <el-input v-model.trim="formData.productionDateCode" placeholder="AMD的4位，intel的3位" maxlength="4" class="form-item"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="碑文：" v-if="isIntel">
              <el-input v-model.trim="formData.inscription" placeholder="5位碑文，不区分大小写" maxlength="5" class="form-item"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="外采标签：">
              <el-input v-model.trim="formData.outsourcedLabel" placeholder="外采商到货标签" maxlength="20" class="form-item"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="维修商代码：">
              <el-select v-model="formData.repairerCode" filterable clearable class="form-item" placeholder="请选择维修商代码">
                <el-option
                    v-for="item in repairerCodeList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="贴纸技服码：" v-if="isBoard&&coaCode">
              <el-input v-model.trim="formData.stickerCode" placeholder="贴纸技服码" maxlength="20" class="form-item"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="计划产能：">
          <div>
            <el-button @click="changeShowCapacity" type="text">{{ showCapacity ? '隐藏' : '显示' }}</el-button>
          </div>
          <div v-if="showCapacity" style="color: orange;line-height: 24px">
            <div v-for="capacity in capacityAndTotalList" :key="capacity.lineCode">
              <span style="display: inline-block;width: 100px;text-align: center">{{ capacity.lineName }}</span>今日产能/已分配：&nbsp;&nbsp;
              外观：<span class="capacity-tip" v-text="capacity.appearance+' - '+(capacity.appearanceAssignCnt+capacity.performanceAssignCnt+capacity.goldenKeyAssignCnt)"></span>
              电性能：<span class="capacity-tip" v-text="capacity.performance+' - '+capacity.performanceAssignCnt"></span>
              金钥匙：<span class="capacity-tip" v-text="capacity.goldenKey+' - '+capacity.goldenKeyAssignCnt"></span>
              <span style="padding-left: 20px" v-if="capacity.canGoldenKey">该生产线可做金钥匙检验</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-tag v-if="isCpu&&!isIntel&&!isAmd">提示：系统已忽略碑文/后缀校验，请仔细核对分拣数据，确保无误后提交。</el-tag>
          <el-tag v-if="qcStationTip">{{ qcStationTip }}</el-tag>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit" :loading="commitLoading">提交</el-button>
          <MessageComponent ref="commitMessage"/>
        </el-form-item>
        <el-form-item label="分拣结果：" v-if="resultList.length>0">
          <el-table :data="resultList" size="mini" border highlight-current-row style="width: 1000px;" height="300">
            <el-table-column align="center" label="资产码" prop="assetSn" width="210"></el-table-column>
            <el-table-column align="center" label="出厂SN码" prop="sortSn" width="210"></el-table-column>
            <el-table-column align="center" label="检验类型">
              <template slot-scope="scope">
                <div style="font-size: 16px;color: red;">{{ scope.row.sortTestTypeName }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" label="备件来源" prop="materialSourceName"></el-table-column>
            <el-table-column align="center" label="生产线" prop="sortLineName"></el-table-column>
            <el-table-column align="center" label="箱号" prop="sortBoxNo"></el-table-column>
          </el-table>
        </el-form-item>
      </el-form>

    </el-card>
  </div>
</template>

<script>
import OrderInfo from "@/views/sortMaterial/OrderInfo";
import MessageComponent from "@/components/MessageComponent";

export default {
  name: "SortMaterial",
  components: {MessageComponent, OrderInfo},
  data() {
    return {
      formData: {
        orderId: '',
        code: '',
        sortTestType: 'appearance',
        materialCode: '',
        materialSn: '',
        assetSn: '',
        sortNum: '1',
        sortBoxNo: '',
        lineCode: '',
        printType: '',
        currentPosition: '',
        sortBoxId: '',
        inscription: '',
        diff1: '',
        diff2: '',
        productionDateCode: '',
        outsourcedLabel: '',
        originalPackMaterialType: '',
        stickerCode: '',
        repairerCode: ''
      },
      order: {},
      capacityList: [],
      itemList: [],
      loading: false,
      commitLoading: false,
      codeNameRepeat: false,
      codeNameRepeatConfirm: false,
      equivalenceList: [],
      needGoldenKey: false,
      intercept: true,
      resultList: [],
      materialCountTip: false,
      openTableTip: false,
      showUrgentTip: false,
      countTip: {
        test1Total: 0,
        test2Total: 0,
        test1: 0,
        test2: 0,
        itemList: [],
      },
      hasProductionDateCode: false,
      coaCode: '',
      lineSelectDisabled: false,
      showCapacity: false,
      qcStationTip: ''
    };
  },
  computed: {
    fixLineName() {
      if (!this.formData.lineCode) {
        return ''
      }
      let lineObj = this.capacityList.find(item => item.lineCode == this.formData.lineCode);
      return lineObj ? lineObj.lineName : ''
    },
    originalPackMaterialTypeList() {
      return this.$store.getters.getDictList('originalPackMaterialType') || [];
    },
    repairerCodeList() {
      return this.$store.getters.getDictList('repairerCode') || [];
    },
    capacityAndTotalList() {
      let arr = this.capacityList.slice();
      let total = {
        lineCode: 'total',
        lineName: '合            计',
        appearance: 0,
        appearanceAssignCnt: 0,
        performance: 0,
        performanceAssignCnt: 0,
        goldenKey: 0,
        goldenKeyAssignCnt: 0
      }
      for (let i = 0; i < arr.length; i++) {
        total.appearance += arr[i].appearance;
        total.appearanceAssignCnt += arr[i].appearanceAssignCnt;
        total.performance += arr[i].performance;
        total.performanceAssignCnt += arr[i].performanceAssignCnt;
        total.goldenKey += arr[i].goldenKey;
        total.goldenKeyAssignCnt += arr[i].goldenKeyAssignCnt;
      }
      arr.push(total);
      return arr;
    },
    interceptSuccess() {
      return this.intercept && !this.$store.getters.hasRole('oper_sort_advanced')
    },
    isAmd() {
      let sn = this.formData.assign || this.formData.materialSn
      if (!sn || sn.indexOf('_') == -1) {
        //条码不包含下划线的不考虑
        return false
      }
      let arr = sn.split('_')
      if (arr.length != 2) {
        //不是只有一个下划线的不考虑
        return false
      }
      if (!arr[1] || arr[1].length < 5) {
        //后半部分长度太短的不考虑
        return false
      }
      return true;
    },
    isIntel() {
      let item = this.getItem();
      if (item.planCategory != '005') {
        return false
      }
      let intel = false;
      let name = item.materialName.toLowerCase();
      if (name.indexOf('intel') != -1 || name.indexOf('i5') != -1 || name.indexOf('i7') != -1 || name.indexOf('i3') != -1 || name.indexOf('i9') != -1) {
        intel = true;
      }
      return intel;
    },
    isCpu() {
      let item = this.getItem();
      if (item.planCategory != '005') {
        return false
      }
      return true;
    },
    isBoard() {
      let item = this.getItem()
      return ['051', '078', '079', '041', '228', '230'].includes(item.planCategory)
    },
    isKeyboardAte() {
      let item = this.getItem()
      return this.order.demanderStockArea == '3010317' && item.planCategory === '073' && (!item.materialSource || item.materialSource == '300');
    },
    showProductionDateCode() {
      if (!this.isCpu) {
        return false
      }
      if (!this.formData.assetSn && !this.formData.materialSn) {
        return false
      }
      if (this.hasProductionDateCode) {
        return false
      }
      return true
    },
  },
  async created() {
    this.refreshCapacity()
  },
  methods: {
    assetSnChange() {
      this.hasProductionDateCode = false
      if (!this.formData.assetSn) {
        return;
      }
      this.formData.assetSn = this.formData.assetSn.trim();
      this.formData.assetSn = this.formData.assetSn.toUpperCase();

      if (this.isCpu) {
        this.$axios.post('materialProductionInfo/getProductionDate', this.formData.assetSn).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error('获取日期代码错误，' + response.data.message);
          }
          if (response.data.data) {
            this.hasProductionDateCode = true
          }
        }, error => {
          return this.$message.error('获取日期代码错误，' + error.message);
        })
      }

    },
    materialSnIsError() {
      if (!this.formData.materialSn || !this.formData.materialCode) {
        return false;
      }
      this.formData.materialSn = this.formData.materialSn.trim();
      this.formData.materialSn = this.formData.materialSn.toUpperCase();

      if (this.formData.materialSn.indexOf(this.formData.materialCode) != -1) {
        return false;
      }
      for (let i = 0; i < this.equivalenceList.length; i++) {
        let code = this.equivalenceList[i];
        if (code && this.formData.materialSn.indexOf(code) != -1) {
          return false;
        }
      }
      return true;
    },
    async queryOrder() {
      this.$refs.searchMessage.clear()
      this.$refs.commitMessage.clear();
      this.qcStationTip = ''
      this.order = {};
      this.itemList = []
      this.resultList = []
      this.formData.materialCode = ''
      this.formData.orderId = ''
      this.formData.sortTestType = 'appearance'
      this.formData.materialSn = ''
      this.formData.assetSn = ''
      this.formData.sortBoxId = ''
      this.formData.inscription = ''
      this.formData.outsourcedLabel = ''
      this.formData.stickerCode = ''
      this.formData.sortNum = '1'
      this.formData.sortBoxNo = ''
      this.formData.lineCode = ''
      this.formData.printType = ''
      this.formData.repairerCode = ''
      this.showUrgentTip = false
      this.coaCode = ''
      this.lineSelectDisabled = false

      this.needGoldenKey = false;
      this.formData.sortTestType = 'appearance';
      this.resetCountTip();
      if (!this.formData.code) {
        return this.$refs.searchMessage.showError('请输入借出订单号')
      }
      this.loading = true;
      const {data: res} = await this.$axios.get('/lendOrder/queryOrderForSort', {
        params: {
          code: this.formData.code
        }
      })
      this.loading = false;
      if (res.code !== 0) {
        return this.$refs.searchMessage.showError(res.message);
      }
      this.order = res.data.order || {};
      this.itemList = res.data.itemList || [];

      this.refreshCapacity()
    },
    async refreshCapacity() {
      const {data: res} = await this.$axios.get('/busCapacity/getTodayCapacity')
      this.capacityList = res.data;
    },
    materialCodeChange() {
      this.codeNameRepeat = this.isRepeatMaterialCode(this.formData.materialCode);
      this.codeNameRepeatConfirm = false
      this.equivalenceList = []
      this.needGoldenKey = false;

      this.formData.sortNum = '1';
      this.formData.materialSn = '';
      this.formData.assetSn = ''
      this.formData.inscription = ''
      this.formData.diff1 = ''
      this.formData.diff2 = ''
      this.formData.productionDateCode = ''
      this.formData.outsourcedLabel = ''
      this.formData.stickerCode = ''
      this.formData.sortBoxId = ''
      this.formData.originalPackMaterialType = ''
      this.hasProductionDateCode = false
      this.coaCode = ''
      this.lineSelectDisabled = false

      this.checkIntercept();
      this.queryCountTip();
      this.queryRuleSortLine()
      this.loadEquivalence()
      this.lineCheck()
      this.urgentTip()
      this.loadGsku()
    },
    loadGsku() {
      this.coaCode = ''
      if (!this.isBoard) {
        return;
      }
      this.$axios.post('boardGsku/getCoaCode', this.formData.materialCode).then(response => {
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error('获取GSKU数据失败');
        }
        this.coaCode = res.data;
      }, error => {
        return this.$message.error('获取GSKU数据失败，' + error.message);
      })
    },
    loadEquivalence() {
      this.$axios.post('materialCodeEquivalence/queryEquivalence', this.formData.materialCode).then(response => {
        if (response.data.code !== 0) {
          return this.$message.error('获取备件编码等价字符失败');
        }
        this.equivalenceList = response.data.data;
      }, error => {
        return this.$message.error('获取备件编码等价字符失败，' + error.message);
      })
    },
    queryRuleSortLine() {
      this.$axios.post('ruleSortLine/queryRuleSortLine', {
        materialCode: this.formData.materialCode,
        lendOrderCode: this.order.code
      }).then(response => {
        if (response.data.code !== 0) {
          return this.$message.error('获取分拣线体设置失败');
        }
        let line = response.data.data
        if (line) {
          this.formData.lineCode = line
          this.lineSelectDisabled = true
        } else {
          this.lineSelectDisabled = false
        }
      }, error => {
        return this.$message.error('获取分拣线体设置失败，' + error.message);
      })
    },
    checkIntercept() { //是否拦截
      this.intercept = true;
      this.$axios.post('ruleIntercept/checkIntercept', {
        materialCode: this.formData.materialCode,
        supplierCode: this.order.supplierCode,
        lendOrderCode: this.order.code,
      }).then(response => {
        if (response.data.code !== 0) {
          return this.$message.error('判断备件是否需要拦截失败');
        }
        let data = response.data.data;
        if (data) {
          this.intercept = true;
        } else {
          this.intercept = false;
        }

        if (this.interceptSuccess) {
          this.$message.error('该备件设置了拦截，需高级分拣员进行分拣');
        } else if (this.intercept) {
          this.$message.success('该备件设置了拦截，您是高级分拣员，可进行分拣');
        }

      }, error => {
        return this.$message.error('判断备件是否需要拦截失败，' + error.message);
      })
    },
    lineCheck() { //生产线金钥匙控制
      if (this.formData.sortTestType != 'electric') {
        this.needGoldenKey = false;
        return;
      }
      this.$axios.get('/busGoldenKeyRate/needGoldenKey', {
        params: {
          materialCode: this.formData.materialCode
        }
      }).then(response => {
        if (response.data.code !== 0) {
          return this.$message.error('判断备件是否需要金钥匙测试失败');
        }
        let data = response.data.data;
        if (data) {
          this.needGoldenKey = true;
        }

      })
    },
    queryCountTip() {
      this.materialCountTip = false
      this.countTip.test1Total = 0
      this.countTip.test2Total = 0
      this.countTip.test1 = 0
      this.countTip.test2 = 0
      this.countTip.itemList = []

      this.$axios.post('/busItem/materialCountTip', {
        orderId: this.order.id,
        materialCode: this.formData.materialCode
      }).then(response => {
        if (response.data.code !== 0) {
          this.$message.error('获取备件已分拣数量错误');
          return;
        }
        this.countTip.itemList = response.data.data || [];
        let selectItem = this.getSelectItem();
        this.countTip.test1Total = selectItem.appearanceRequiredTestQty;
        this.countTip.test2Total = selectItem.electricityRequiredTestQty;

        this.countTip.test1 = 0;
        this.countTip.test2 = 0;
        for (let i = 0; i < this.countTip.itemList.length; i++) {
          let obj = this.countTip.itemList[i];
          if (obj.sortTestType == 'appearance') {
            this.countTip.test1++
          } else if (obj.sortTestType == 'electric' || obj.sortTestType == 'goldenKey') {
            this.countTip.test2++
          }
        }

        this.materialCountTip = true;
      })
    },
    urgentTip() {
      this.$axios.post('/busItem/urgentTip', {
        code: this.order.code,
        materialCode: this.formData.materialCode
      }).then(response => {
        if (response.data.code !== 0) {
          this.$message.error('获取加急提示信息错误');
          return;
        }
        this.showUrgentTip = response.data.data || false;
      })
    },
    getSelectItem() {
      for (let i = 0; i < this.itemList.length; i++) {
        let item = this.itemList[i];
        if (this.formData.materialCode == item.materialCode) {
          return item;
        }
      }
    },
    isRepeatMaterialCode(materialCode) {
      let name = '';
      for (let i = 0; i < this.itemList.length; i++) {
        if (this.itemList[i].materialCode == materialCode) {
          name = this.itemList[i].materialName;
          break;
        }
      }
      let cnt = 0;
      this.itemList.forEach(obj => {
        if (obj.materialName == name) {
          cnt = cnt + 1;
        }
      })
      return cnt > 1;
    },
    codeChange(val) {
      if (val) {
        this.$refs.searchMessage.clear()
      }
    },
    async queryLendOrderCode(queryString, callback) {
      if (queryString.length < 2) {
        callback([])
        return;
      }
      const {data: res} = await this.$axios.get('/lendOrder/queryCode', {
        params: {
          code: queryString
        }
      })
      if (res.code !== 0) {
        return this.$message.error('查询订单号失败');
      }
      res.data.forEach(n => {
        n.value = n.code
      })
      callback(res.data);
    },
    submit() {
      this.resultList = [];
      this.$refs.commitMessage.clear();
      this.qcStationTip = ''
      let currentPosition = localStorage.getItem('current_position') || '';
      if (!currentPosition) {
        return this.$refs.commitMessage.showError('未设置工位，请重新登录系统并设置工位信息');
      } else {
        this.formData.currentPosition = currentPosition;
      }

      if (!this.order.id) {
        this.$refs.commitMessage.showError('请先输入借出订单号查询借出订单信息');
        return;
      } else {
        this.formData.orderId = this.order.id
      }

      if (this.formData.assetSn) {
        this.formData.assetSn = this.formData.assetSn.trim().toUpperCase();
      }

      if (this.formData.materialSn) {
        this.formData.materialSn = this.formData.materialSn.trim().toUpperCase();
      }

      let selectItem = this.getSelectItem()
      //含GSKU字样，排除NON GSKU字样
      let name = selectItem.materialName.toUpperCase();
      if (name.indexOf('NON GSKU') == -1 && name.indexOf("GSKU") > -1 && !this.coaCode) {
        return this.$message.error(`备件编码${selectItem.materialCode}未维护COA编码，请反馈给主管进行维护`);
      }

      if (!this.formData.materialCode) {
        return this.$refs.commitMessage.showError('请选择备件编码');
      }

      if (this.interceptSuccess) {
        return this.$refs.commitMessage.showError('该备件设置了拦截，需高级分拣员进行分拣');
      }

      if (this.codeNameRepeat && !this.codeNameRepeatConfirm) {
        return this.$refs.commitMessage.showError('请先确认备件编码无误');
      }

      //键盘ATE必须输入8S
      if (this.isKeyboardAte) {
        if (!this.formData.materialSn) {
          return this.$refs.commitMessage.showError('出厂SN码不能为空，ATE测试的键盘，必须输入8S码');
        }

        if (!this.formData.materialSn.toUpperCase().startsWith("8S")) {
          return this.$refs.commitMessage.showError('ATE测试的键盘，出厂SN码必须以8S码开头');
        }

      }

      if (this.formData.assetSn) {
        if (this.formData.assetSn.length < 5 || this.formData.assetSn.length > 40) {
          return this.$refs.commitMessage.showError('资产码长度必须大于5且小于40');
        }
      } else if (this.formData.materialSn) {
        let len = this.formData.materialSn.length;
        /*
        计划大类为LCD(011)，位数必须为20、22、23、25
        计划大类为LCD模组(077)，位数必须为20、22、23
        计划大类为电池(028)，位数必须为20、22、23
        计划大类为电源(031)，位数必须为20、22、23、26
        计划大类为固态硬盘(171)，位数必须为19、20、22、23
        计划大类为主板(041/051/078/079/228/230)，位数必须为20、22、23，出厂SN码如果是8S开头的，长度必须是22、23、24位
        计划大类为CPU(005)，位数必须为13、23、26、27
         */
        let item = this.getItem();
        let msg = '出厂SN码长度(' + len + ')不正确，';
        if (item.planCategory == '011') {
          if (len != 20 && len != 22 && len != 23 && len != 25) {
            return this.$refs.commitMessage.showError(msg + '计划大类为LCD，长度必须为20、22、23、25');
          }
        } else if (item.planCategory == '077') {
          if (len != 20 && len != 22 && len != 23) {
            return this.$refs.commitMessage.showError(msg + '计划大类为LCD模组，长度必须为20、22、23');
          }
        } else if (item.planCategory == '028') {
          if (len != 20 && len != 22 && len != 23) {
            return this.$refs.commitMessage.showError(msg + '计划大类为电池，长度必须为20、22、23');
          }
        } else if (item.planCategory == '031') {
          if (len != 20 && len != 22 && len != 23 && len != 26) {
            return this.$refs.commitMessage.showError(msg + '计划大类为电源，长度必须为20、22、23、26');
          }
        } else if (item.planCategory == '171') {
          if (len != 19 && len != 20 && len != 22 && len != 23) {
            return this.$refs.commitMessage.showError(msg + '计划大类为固态硬盘，长度必须为19、20、22、23');
          }
        } else if (this.isBoard) {
          if (this.formData.materialSn.startsWith("8S")) {
            if (len != 22 && len != 23 && len != 24) {
              return this.$refs.commitMessage.showError(msg + '计划大类为主板，8S开头时长度必须为22、23、24');
            }
          } else if (len != 20 && len != 22 && len != 23) {
            return this.$refs.commitMessage.showError(msg + '计划大类为主板，长度必须为20、22、23');
          }
        } else if (item.planCategory == '005') {
          if (len != 13 && len != 18 && len != 23 && len != 26 && len != 27) {
            return this.$refs.commitMessage.showError(msg + '计划大类为CPU，长度必须为13、18、23、26、27');
          }
        }/* else if (item.planCategory == '216') {
          if (len < 8) {
            return this.$refs.commitMessage.showError(msg + '计划大类为U盘，长度必须为8位以上');
          }
        } else if (item.chargeLineCode != 'M' && (item.materialSource == '10' || item.materialSource == '20' || item.materialSource == '30')) {
          // 采购订单、索赔归还、维修归还且非数码选件
          if (!this.formData.materialSn.startsWith("8S") && !this.formData.materialSn.startsWith("11S")) {
            return this.$refs.commitMessage.showError('出厂SN码必须以8S/11S开头');
          }
        }*/

        if (len < 5 || len > 40) {
          return this.$refs.commitMessage.showError(msg + '长度必须大于5且小于40');
        }

        //除CPU外，出厂SN码必须包含备件编码或备件编码的等价字符
        if (item.planCategory != '005' && this.materialSnIsError()) {
          return this.$refs.commitMessage.showError('出厂SN码和备件编码不符，请检查备件编码、出厂SN码是否正确，如无误联系主管维护备件编码等价字符。');
        }
      }

      if (this.formData.assetSn || this.formData.materialSn) {
        if (this.formData.sortNum != 1) {
          return this.$refs.commitMessage.showError('有资产码或出厂SN码时，分拣数量必须为1');
        }
      } else {
        if (!this.formData.sortNum) {
          this.$refs.commitMessage.showError('请输入分拣数量')
          return
        }
        if (this.formData.sortNum < 1) {
          return this.$refs.commitMessage.showError('分拣数量必须大于0');
        }
      }

      if (!this.formData.printType) {
        return this.$refs.commitMessage.showError('请选择打印类型');
      }

      if (!this.formData.sortBoxNo) {
        return this.$refs.commitMessage.showError('请输入箱号');
      }

      if (!this.formData.lineCode) {
        return this.$refs.commitMessage.showError('请选择生产线');
      } else {
        if (this.needGoldenKey) {
          let checkRight = true;
          for (let i = 0; i < this.capacityList.length; i++) {
            if (this.capacityList[i].lineCode != this.formData.lineCode) {
              continue
            }
            if (!this.capacityList[i].canGoldenKey) {
              checkRight = false;
            }
          }
          if (!checkRight) {
            return this.$refs.commitMessage.showError('请选择可做金钥匙检验的生产线');
          }

        }
      }

      let inputPos = '';
      if (this.formData.assetSn) {
        inputPos = '【资产码】';
      } else if (this.formData.materialSn) {
        inputPos = '【出厂SN码】';
      }
      if (inputPos && inputPos != this.getInputPos()) {
        let msg = '逻辑判断条码应输在' + this.getInputPos() + '里，实际输在' + inputPos + '里，请确认条码输入位置是否正确？'

        this.$confirm(msg, '条码位置确认', {
          cancelButtonText: '去修改',
          confirmButtonText: '确认正确',
          type: 'warning'
        }).then(() => {
          this.checkMaterialCode()
        })
      } else {
        this.checkMaterialCode();
      }
    },
    checkMaterialCode() {
      //非资产码方式分拣的不检查，资产码包含备件编码的不检查
      if (!this.formData.assetSn || this.formData.assetSn.includes(this.formData.materialCode)) {
        this.checkCpu()
      } else {
        this.$axios.post('sort/getPossibleMaterialCode', this.formData.assetSn).then(response => {
          let res = response.data;
          if (res.code !== 0) {
            return this.$message.error('根据资产码检查所选备件编码失败，' + res.message);
          }

          let codeList = res.data || []
          if (codeList.length == 0) {
            this.$confirm('根据资产码未识别出备件编码，请确认所选备件编码是否正确？', '备件编码确认', {
              cancelButtonText: '去修改',
              confirmButtonText: '确认正确',
              type: 'warning'
            }).then(() => {
              this.checkCpu()
            })
          } else if (!codeList.includes(this.formData.materialCode)) {
            let str = codeList.join(',')
            this.$confirm(`根据资产码识别的备件编码为【${str}】，请确认所选备件编码是否正确？`, '备件编码确认', {
              cancelButtonText: '去修改',
              confirmButtonText: '确认正确',
              type: 'warning'
            }).then(() => {
              this.checkCpu()
            })
          } else {
            this.checkCpu()
          }

        }, error => {
          return this.$message.error('根据资产码检查所选备件编码失败，' + error.message);
        });
      }

    },
    checkCpu() {
      if (this.showProductionDateCode) {
        if (!this.formData.productionDateCode) {
          return this.$refs.commitMessage.showError('请输入日期代码');
        }
        this.formData.productionDateCode = this.formData.productionDateCode.toUpperCase();
        if (this.isIntel && this.formData.productionDateCode.length != 3) {
          return this.$refs.commitMessage.showError('Intel的CPU日期代码必须为3位(1位年2位周)');
        } else if (this.isAmd && this.formData.productionDateCode.length != 4) {
          return this.$refs.commitMessage.showError('AMD的CPU日期代码必须为4位(2位年2位周)');
        }
      }

      let inscription = '';
      if (this.formData.inscription) {
        this.formData.inscription = this.formData.inscription.toUpperCase();
      }
      if (this.isIntel) {
        if (!this.formData.inscription) {
          return this.$refs.commitMessage.showError('请输入Intel CPU的5位碑文');
        }
        inscription = this.formData.inscription
      } else if (this.isAmd) {
        let sn = this.formData.assetSn || this.formData.materialSn
        inscription = sn.split('_')[1];
      }
      if (inscription) {
        this.$axios.post('cpu/queryMaterialCode', inscription).then(response => {
          let res = response.data;
          if (res.code !== 0) {
            return this.$message.error(res.message);
          }
          let arr = res.data || []
          if (arr.length == 0) {
            return this.$refs.commitMessage.showError('该碑文/后缀【' + inscription + '】未维护对应的备件编码');
          }
          if (arr.indexOf(this.formData.materialCode) == -1) {
            return this.$refs.commitMessage.showError('所选备件编码与根据碑文/后缀匹配的备件编码不符，匹配备件编码为【' + arr + '】');
          }
          this.checkPrinter();
        }, error => {
          console.error(error)
          return this.$refs.commitMessage.showError('根据碑文校验选择的备件编码失败');
        })
      } else {
        this.checkPrinter();
      }
    },
    checkPrinter() {
      if (this.$socket.notOk()) {
        this.$confirm('未成功连接打印机小程序，是否继续？', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '确定',
          type: 'warning'
        }).then(() => {
          this.save()
        })
      } else {
        this.save();
      }
    },
    save() {
      this.commitLoading = true;
      this.$axios.post('/busItem/sortMaterial', this.formData, {timeout: 180000}).then(result => {
        this.commitLoading = false;
        var dataObj = result.data;
        if (dataObj.code != 0) {
          return this.$refs.commitMessage.showError(dataObj.message);
        }
        this.resultList = dataObj.data || [];

        let msg = '分拣成功';
        this.qcStationTip = ''
        if (this.resultList.length == 1) {
          let item = this.resultList[0];
          let ruleColorId = item.busItemInfo?.ruleColorId;
          if (ruleColorId == '3010313') {
            msg = msg + "，【校色拦截不打码】"
          }

          msg = msg + `，下一站为【${item.nextStationName}】`
          if (['qc', 'qcAppearance'].includes(item.nextStation)) {
            this.qcStationTip = `【${item.assetSn}】下一站为【${item.nextStationName}】`
          }
        }
        this.$refs.commitMessage.showSuccess(msg);

        //打印
        let printType = this.formData.printType
        for (let i = 0; i < this.resultList.length; i++) {
          let item = this.resultList[i];
          if (item.assetSnBoInner) {
            this.$socket.send({
              "requestId": Math.round(Math.random() * 100000000),
              "printType": "asset",
              "printer": process.env.VUE_APP_ASSET_SN_PRINTER_NAME,
              "printVO": item.assetSnBoInner
            })
            //如果是需要两个内码的，调用两次
            if (printType == '5' || printType == 6) {
              this.$socket.send({
                "requestId": Math.round(Math.random() * 100000000),
                "printType": "asset",
                "printer": process.env.VUE_APP_ASSET_SN_PRINTER_NAME,
                "printVO": item.assetSnBoInner
              })
            }

          }
          if (item.assetSnBoOuter) {
            this.$socket.send({
              "requestId": Math.round(Math.random() * 100000000),
              "printType": "asset",
              "printer": process.env.VUE_APP_ASSET_SN_PRINTER_NAME,
              "printVO": item.assetSnBoOuter
            })
          }
        }

        this.reInit();
      }, error => {
        this.commitLoading = false;
        return this.$refs.commitMessage.showError(error.message);
      })
    },
    reInit() {
      //清除数据
      this.formData.sortNum = '1';
      this.formData.materialSn = '';
      this.formData.assetSn = ''
      this.formData.inscription = ''
      this.formData.diff1 = ''
      this.formData.diff2 = ''
      this.formData.productionDateCode = ''
      this.formData.outsourcedLabel = ''
      this.formData.stickerCode = ''
      this.formData.sortBoxId = ''
      this.hasProductionDateCode = false
      this.refreshCapacity()
      this.queryCountTip()
    },
    resetCountTip() {
      this.countTip.test1Total = 0;
      this.countTip.test2Total = 0;
      this.countTip.test1 = 0;
      this.countTip.test2 = 0;
      this.countTip.itemList = [];
      this.materialCountTip = false;
      this.openTableTip = false;
    },
    changeShowInfo() {
      this.openTableTip = !this.openTableTip;
    },
    getItem() {
      for (let i = 0; i < this.itemList.length; i++) {
        if (this.formData.materialCode == this.itemList[i].materialCode) {
          return this.itemList[i]
        }
      }
      return {}
    },
    getInputPos() {
      let materialSource = this.getItem().materialSource
      if (materialSource == '10' || materialSource == '20' || materialSource == '30' || materialSource == '90' || materialSource == '100' || materialSource == '110' || materialSource == '130' || materialSource == '140') {
        return '【出厂SN码】';
      }
      return '【资产码】';
    },
    changeShowCapacity() {
      this.showCapacity = !this.showCapacity
    }
  }
}
</script>

<style scoped>
.box-card {
  padding-bottom: 100px;
}

.line-tip, .capaline-tip {
  color: red;
  font-size: 18px;
  padding-right: 10px;
}

.capacity-tip {
  width: 110px;
  display: inline-block;
  text-align: left;
}

.form-item {
  width: 300px;
}

.tip {
  color: goldenrod;
}
</style>
