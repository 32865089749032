<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      <el-row style="padding-bottom: 10px">
        <el-col :span="24" style="text-align: left;line-height: 40px">
          <el-input ref="assetSn" v-model="formData.assetSn" placeholder="请输入或扫资产码" @keyup.enter.native="search" style="width: 300px"></el-input>
          <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="search">查询</el-button>
          <MessageComponent ref="searchMessage"/>
        </el-col>
      </el-row>

      <MaterialInfo :busItem="busItem" :lendOrder="lendOrder" :lend-order-item="lendOrderItem" station="appearance"/>

      <el-form :model="formData" label-width="140px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="检验类型：">
              <el-select v-model="formData.sortTestType" disabled class="form-item">
                <el-option label="外观" value="appearance"></el-option>
                <el-option label="电性能" value="electric"></el-option>
                <el-option label="金钥匙" value="goldenKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="外观是否修复OK：">
              <el-radio-group v-model="formData.busItemInfo.test1RepairOk">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="外观修复备注：">
              <el-input v-model="formData.busItemInfo.test1RepairRemark" placeholder="请备注修复位置或无法维修原因" type="textarea" :rows="1" class="form-item"></el-input>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row>
          <el-col :span="5" style="text-align: right">
            <el-button type="primary" @click="submit" :loading="loading">作业结果提交</el-button>
          </el-col>
          <el-col :span="19">
            <MessageComponent ref="commitMessage"/>
          </el-col>
        </el-row>
      </el-form>
    </el-card>

  </div>
</template>

<script>
import MaterialInfo from "@/views/material/MaterialInfo";
import MessageComponent from "@/components/MessageComponent";

export default {
  name: "KeyboardRepair",
  components: {MaterialInfo, MessageComponent},
  data() {
    return {
      formData: {
        assetSn: '',
        busItemInfo: {
          test1RepairOk: undefined,
          test1ResultType: '',
          test1RepairRemark: ''
        }
      },
      canCheck: false,
      busItem: {},
      lendOrder: {},
      lendOrderItem: {},
      loading: false,
    }
  },
  methods: {
    resetValues() {
      this.formData.busItemInfo.test1RepairOk = undefined
      this.formData.busItemInfo.test1RepairRemark = ''
      this.formData.busItemInfo.test1ResultType = ''

      this.canCheck = false;
      this.busItem = {};
      this.lendOrder = {};
      this.lendOrderItem = {};
      this.loading = false;
    },
    async submit() {
      this.$refs.commitMessage.clear();

      if (!this.formData.assetSn) {
        return this.$refs.commitMessage.showError('请输入或扫资产码');
      }
      if (!this.canCheck) {
        return this.$refs.commitMessage.showError('该资产码不符合键盘维修操作的条件');
      }

      let info = this.formData.busItemInfo;
      if (info.test1RepairOk !== true && info.test1RepairOk != false) {
        return this.$refs.commitMessage.showError('请选择外观是否修复OK');
      }

      info.test1ResultType = info.test1RepairOk ? 'repair_ok' : 'repair_ng';

      if (!info.test1RepairRemark) {
        return this.$refs.commitMessage.showError('请备注修复位置或无法维修原因');
      }

      this.loading = true;
      const {data: res} = await this.$axios.post('/busItem/saveTest1RepairResult', this.formData);
      this.loading = false;
      if (res.code !== 0) {
        return this.$refs.commitMessage.showError(res.message);
      }
      this.$refs.commitMessage.showSuccess('提交成功，下一工站为：' + res.data.nextStationName);
      this.resetValues();
      this.formData.assetSn = '';
      this.$refs.assetSn.focus();
    },
    async search() {
      this.resetValues();
      this.$refs.searchMessage.clear();
      this.$refs.commitMessage.clear();

      if (!this.formData.assetSn) {
        this.$refs.searchMessage.showError('请输入或扫资产码');
        return;
      }
      this.formData.assetSn = this.formData.assetSn.trim();

      this.loading = true;
      let url = '/busItem/queryInfoForKeyboardRepair'
      const {data: res} = await this.$axios.get(url, {
        params: {assetSn: this.formData.assetSn}
      });
      this.loading = false;

      if (res.code !== 0) {
        this.$refs.searchMessage.showError(res.message);
        return
      }

      if (!res.data.busItem) {
        return this.$refs.searchMessage.showError('资产码不正确');
      }

      this.busItem = res.data.busItem || {};
      this.busItem.busItemInfo = this.busItem.busItemInfo || {};
      this.formData.sortTestType = this.busItem.sortTestType || '';
      this.lendOrder = res.data.lendOrder || {};
      this.lendOrderItem = res.data.lendOrderItem || {};

      if (this.busItem.currentStation != 'keyboardRepair') {
        let msg = '该物品当前工站不是键盘维修工站';
        if (this.busItem.currentStationStatus == 'finish' && this.busItem.nextStationName) {
          msg += '，工站为：' + this.busItem.nextStationName;
        } else if (this.busItem.currentStationName) {
          msg += '，工站为：' + this.busItem.currentStationName;
        }
        return this.$refs.searchMessage.showError(msg);
      } else if (this.busItem.currentStationStatus == 'finish') {
        return this.$refs.searchMessage.showError('当前工站已完成，下一工站为：' + this.busItem.nextStationName);
      }

      this.canCheck = true;

    },
  }
}
</script>

<style scoped>
.fun-tip {
  font-weight: bold;
  font-size: 16px;
  color: red;
}

.form-item {
  width: 320px;
}

.box-card {
  padding-bottom: 50px;
}
</style>
