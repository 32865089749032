<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      <el-row style="padding-bottom: 10px">
        <el-col :span="24" style="text-align: left;line-height: 40px">
          <el-input ref="assetSn" v-model="formData.assetSn" placeholder="请输入或扫资产码" @keyup.enter.native="search" style="width: 300px"></el-input>
          <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="search">查询</el-button>
          <MessageComponent ref="searchMessage"/>
        </el-col>
      </el-row>
      <MaterialInfo :busItem="busItem" :lendOrder="lendOrder" :lend-order-item="lendOrderItem" station="appearanceReview"/>
      <el-form :model="formData" label-width="130px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="检验类型：">
              <el-select v-model="busItem.sortTestType" disabled class="form-item">
                <el-option label="外观" value="appearance"></el-option>
                <el-option label="电性能" value="electric"></el-option>
                <el-option label="金钥匙" value="goldenKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="检验提示：">
              <div class="fun-tip">{{ ruleText }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="外观检验结果：">
              <el-radio-group v-model="formData.test1Result" @change="calcResult">
                <el-radio :label="true">正常</el-radio>
                <el-radio :label="false">不良</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="外观是否可修复：" v-if="formData.test1Result===false">
              <el-radio-group v-model="formData.test1Repairable">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否可翻新：" v-if="formData.test1Repairable===true&&busItem.planCategory=='011'">
              <el-radio-group v-model="formData.test1Refurbishable">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="formData.test1Result===false">
          <el-col :span="8">
            <el-form-item label="外观不良代码：">
              <el-select v-model="formData.test1TroubleCode" filterable placeholder="请选择外观不良代码" class="form-item">
                <el-option
                    v-for="item in troubleList"
                    :key="item.code"
                    :label="item.code+' '+item.name"
                    :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="外观不良原因：">
              <el-input v-model="formData.test1TroubleReason" type="textarea" :rows="2" class="form-item"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="needTest2">
          <el-col :span="8">
            <el-form-item label="电性能检验结果：">
              <el-radio-group v-model="formData.test2Result" @change="calcResult">
                <el-radio :label="true">正常</el-radio>
                <el-radio :label="false">不良</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电性能不良代码：" v-if="formData.test2Result===false">
              <el-select v-model="formData.test2TroubleCode" filterable placeholder="请选择电性能不良代码" class="form-item">
                <el-option
                    v-for="item in troubleList"
                    :key="item.code"
                    :label="item.code+' '+item.name"
                    :value="item.code"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电性能不良原因：" v-if="formData.test2Result===false">
              <el-input v-model="formData.test2TroubleReason" type="textarea" :rows="2" class="form-item"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="检验结果：">
              <el-select v-model="formData.testResult" class="form-item">
                <el-option
                    v-for="item in testResultList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="处置意见：">
              <el-select v-model="formData.disposeOpinion" class="form-item">
                <el-option
                    v-for="item in disposeOpinionList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检验结果描述：">
              <el-input v-model="formData.testResultRemark" type="textarea" :rows="2" class="form-item"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="外观检验照片：">
              <PhotographComponent @setImgIds="setTest1ImgIds" ref="test1ImageUpload"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电性能检验照片：" v-if="needTest2">
              <PhotographComponent @setImgIds="setTest2ImgIds" ref="test2ImageUpload"/>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="联想目标库区：" v-if="lenovoStore&&showLenovoSelect">
              <el-select v-model="formData.storeType" filterable placeholder="请选择联想目标库区" class="form-item">
                <el-option
                    v-for="item in lenovoStoreList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="5" style="text-align: right">
            <el-button type="primary" @click="submit" :loading="submitLoading">不良复检结果提交</el-button>
          </el-col>
          <el-col :span="19">
            <MessageComponent ref="commitMessage"/>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import MessageComponent from "@/components/MessageComponent";
import MaterialInfo from "@/views/material/MaterialInfo";
import PhotographComponent from "@/components/PhotographComponent";

export default {
  name: "ReviewTest",
  components: {PhotographComponent, MaterialInfo, MessageComponent},
  data() {
    return {
      formData: {
        assetSn: '',
        test1Result: undefined,
        test1Repairable: '',
        test1Refurbishable: '',
        test1TroubleReason: '',
        test1TroubleCode: '',
        test1ImageIds: [],
        test2ImageIds: [],
        test2Result: undefined,
        test2TroubleCode: '',
        test2TroubleReason: '',
        testResult: '',
        disposeOpinion: '',
        testResultRemark: '',
        currentPosition: '',
        storeType: '',
        judgeStoreType: ''
      },
      canCheck: false,
      busItem: {},
      lendOrder: {},
      lendOrderItem: {},
      troubleList: [],
      ruleText: '',
      loading: false,
      submitLoading: false,
      lenovoStoreList: [],
      showLenovoSelect: false
    }
  },
  computed: {
    disposeOpinionList() {
      return this.$store.getters.getDictList('disposeOpinion') || [];
    },
    testResultList() {
      return this.$store.getters.getDictList('testResult') || [];
    },
    storeTypeList() {
      return this.$store.getters.getDictList('storeType') || [];
    },
    lastTest() {
      return true
    },
    lenovoStore() {
      return this.lastTest && this.busItem.lenovoJudgeStore
    },
    judgeStoreType() {
      if (this.lenovoStore && this.showLenovoSelect) {
        return 'lenovo_more'
      } else if (this.lenovoStore) {
        return 'lenovo_one'
      }
      return 'system_auto'
    },
    needTest2() {
      let sortTestType = this.busItem.sortTestType;
      let typeArr = ['electric', 'goldenKey'];
      if (sortTestType === 'appearance' && typeArr.includes(this.busItem.busItemInfo?.testType)) {
        return true
      }
      return typeArr.includes(sortTestType);
    },
    needEmail() {
      if (this.formData.testResult == '1') {
        return false;
      }
      //采购订单、buffer订单有不良时提示
      if (this.busItem.materialSource == '10' || this.busItem.materialSource == '90' || this.busItem.materialSource == '100') {
        return true;
      }
      //索赔归还指定供应商时提示
      if (this.busItem.materialSource == '20' && this.busItem.supplierCode) {
        let str = ',2009103,2016063,2015508,2015819,2017277,2015818,4027687,2000018,2007766,2012756,2002534,2013506,2013819,2020191,'
        return str.indexOf(',' + this.busItem.supplierCode + ',') != -1;
      }
      //索赔归还指定供应商时提示,计划大类为LCD模组
      if (this.busItem.materialSource == '20' && this.busItem.supplierCode && this.busItem.planCategory == '077') {
        let str = ',1000338,'
        return str.indexOf(',' + this.busItem.supplierCode + ',') != -1;
      }
      //索赔归还指定供应商时提示,计划大类为主板
      if (this.busItem.materialSource == '20' && this.busItem.supplierCode
          && (this.busItem.planCategory == '041' || this.busItem.planCategory == '051' || this.busItem.planCategory == '078' || this.busItem.planCategory == '079')) {
        let str = ',4002529,VS00000027,2010247,2000018,'
        return str.indexOf(',' + this.busItem.supplierCode + ',') != -1;
      }
      return false;
    }
  },
  methods: {
    calcResult() {
      if (this.busItem.sortTestType == 'appearance') {
        if (this.formData.test1Result) {
          this.formData.testResult = '1'
          this.formData.disposeOpinion = '20'
        } else {
          this.formData.testResult = undefined
          this.formData.disposeOpinion = undefined
        }
      } else {
        if (this.formData.test1Result && this.formData.test2Result) {
          this.formData.testResult = '1'
          this.formData.disposeOpinion = '20'
        } else {
          this.formData.testResult = undefined
          this.formData.disposeOpinion = undefined
        }
      }

    },
    resetValues() {
      this.formData.test1Result = undefined
      this.formData.test1Repairable = ''
      this.formData.test1Refurbishable = ''
      this.formData.test1TroubleReason = ''
      this.formData.test1TroubleCode = ''
      this.formData.test2Result = undefined
      this.formData.test2TroubleCode = ''
      this.formData.test2TroubleReason = ''
      this.formData.test1ImageIds = []
      this.formData.test2ImageIds = []
      this.formData.testResult = ''
      this.formData.testResultRemark = ''
      this.formData.disposeOpinion = ''
      this.formData.storeType = '';
      this.lenovoStoreList = [];
      this.showLenovoSelect = false;

      this.canCheck = false;
      this.busItem = {};
      this.lendOrder = {};
      this.lendOrderItem = {};
      this.troubleList = [];
      this.ruleText = '';
      this.loading = false;
      this.submitLoading = false;
      this.$refs.test1ImageUpload.onClear();
      if (this.$refs.test2ImageUpload) {
        this.$refs.test2ImageUpload.onClear();
      }
    },
    async submit() {
      this.$refs.commitMessage.clear();
      if (!this.lastTest) {
        this.formData.storeType = '';
        this.lenovoStoreList = [];
        this.showLenovoSelect = false;
      }
      let currentPosition = localStorage.getItem('current_position') || '';
      if (!currentPosition) {
        return this.$refs.commitMessage.showError('未设置工位，请重新登录系统并设置工位信息');
      } else {
        this.formData.currentPosition = currentPosition;
      }
      if (!this.formData.assetSn) {
        return this.$refs.commitMessage.showError('请输入或扫资产码');
      }
      if (!this.canCheck) {
        return this.$refs.commitMessage.showError('该资产码不符合不良复检检验条件');
      }
      //不良时校验不良字段
      if (!this.formData.test1Result) {
        if (!this.formData.test1TroubleCode) {
          return this.$refs.commitMessage.showError('请选择外观不良代码');
        }
      }

      if (this.needTest2 && this.formData.test2Result !== false && this.formData.test2Result !== true) {
        return this.$refs.commitMessage.showError('请选择电性能检验结果');
      }

      if (this.formData.test2Result === false) {
        if (!this.formData.test2TroubleCode) {
          return this.$refs.commitMessage.showError('请选择电性能不良代码');
        }
      }

      let good = this.formData.test1Result;
      if (this.needTest2) {
        good = this.formData.test1Result && this.formData.test2Result;
      }
      if (!this.formData.testResult) {
        return this.$refs.commitMessage.showError('请选择检验结果');
      } else if (good && this.formData.testResult != '1') {
        return this.$refs.commitMessage.showError('检验结果必须为合格');
      }
      if (!this.formData.disposeOpinion) {
        return this.$refs.commitMessage.showError('请选择处置意见');
      } else if (good && this.formData.disposeOpinion != '20') {
        return this.$refs.commitMessage.showError('处置意见必须为合格');
      }

      if (!this.formData.storeType && this.lenovoStore) {
        if (this.lenovoStoreList.length == 0) {
          this.submitLoading = true;
          const {data: storeRes} = await this.$axios.post('/busItem/judgeStore', this.formData);
          this.submitLoading = false;
          if (storeRes.code !== 0) {
            return this.$refs.commitMessage.showError(storeRes.message);
          }
          this.lenovoStoreList = storeRes.data || [];
          if (this.lenovoStoreList.length == 0) {
            this.$message.error('联想判保接口没有返回库区，请将条码及检验结果信息给主管，找联想协助检查判库规则')
          }
        }
        if (this.lenovoStoreList.length == 1) {
          this.formData.storeType = this.lenovoStoreList[0].id;
        } else {
          this.showLenovoSelect = true
          return this.$refs.commitMessage.showError("请选择联想目标仓库");
        }
      }

      if (this.needEmail) {
        this.$confirm('检验不良需要邮件提报', '提示', {
          cancelButtonText: '取消',
          confirmButtonText: '我已知晓',
          type: 'warning'
        }).then(() => {
          this.saveReviewResult()
        })
      } else {
        this.saveReviewResult();
      }
    },
    async saveReviewResult() {
      this.formData.judgeStoreType = this.judgeStoreType
      this.submitLoading = true;
      const {data: res} = await this.$axios.post('/busItem/saveReviewResult', this.formData);
      this.submitLoading = false;
      if (res.code !== 0) {
        return this.$refs.commitMessage.showError(res.message);
      }
      let msg = '提交成功，下一工站为：' + res.data.nextStationName;
      this.$refs.commitMessage.showSuccess(msg);
      this.$refs.searchMessage.showSuccess(msg);
      this.resetValues();
      this.formData.assetSn = ''
      this.$refs.assetSn.focus();
    },
    async search() {
      this.resetValues()
      this.$refs.searchMessage.clear();
      this.$refs.commitMessage.clear();
      if (!this.formData.assetSn) {
        this.$refs.searchMessage.showError('请输入或扫资产码');
        return;
      }
      this.formData.assetSn = this.formData.assetSn.trim();

      this.loading = true;
      const {data: res} = await this.$axios.get('/busItem/queryInfoForReview', {
        params: {assetSn: this.formData.assetSn}
      });
      this.loading = false;

      if (res.code !== 0) {
        this.$refs.searchMessage.showError(res.message);
        return
      }

      if (!res.data.busItem) {
        return this.$refs.searchMessage.showError('资产码不正确');
      }

      this.busItem = res.data.busItem || {};
      this.busItem.busItemInfo = this.busItem.busItemInfo || {};
      this.lendOrder = res.data.lendOrder || {};
      this.lendOrderItem = res.data.lendOrderItem || {};
      this.formData.test1Result = this.busItem.test1Result;
      this.formData.test1Repairable = this.busItem.test1Repairable
      this.formData.test1Refurbishable = this.busItem.test1Refurbishable
      this.formData.test1TroubleReason = this.busItem.test1TroubleReason
      this.formData.test1TroubleCode = this.busItem.test1TroubleCode
      this.formData.test2Result = this.busItem.test2Result;
      this.formData.test2TroubleReason = this.busItem.test2TroubleReason
      this.formData.test2TroubleCode = this.busItem.test2TroubleCode
      this.formData.testResult = this.busItem.testResult
      this.formData.testResultRemark = this.busItem.testResultRemark;
      this.formData.disposeOpinion = this.busItem.disposeOpinion

      this.troubleList = res.data.troubleList || [];
      this.ruleText = res.data.ruleText || '';
      let inLineCnt = this.busItem.busItemInfo.inLineCnt || 0;
      if (inLineCnt > 2) {
        this.ruleText = '【第' + inLineCnt + '次来货】' + this.ruleText
      }

      if (res.data.canCheck === false) {
        return this.$refs.searchMessage.showError('该物品需要高级检验人员检验');
      }

      if (this.busItem.currentStation != 'appearanceReview' && this.busItem.currentStation != 'electricReview') {
        var msg = '该物品当前工站不是不良复检工站';
        if (this.busItem.currentStationStatus == 'finish' && this.busItem.nextStationName) {
          msg += '，工站为：' + this.busItem.nextStationName;
        } else if (this.busItem.currentStationName) {
          msg += '，工站为：' + this.busItem.currentStationName;
        }
        return this.$refs.searchMessage.showError(msg);
      } else if (this.busItem.currentStationStatus == 'finish') {
        return this.$refs.searchMessage.showError('当前工站已完成，下一工站为：' + this.busItem.nextStationName);
      }

      this.canCheck = true;
    },
    setTest1ImgIds(idList) {
      this.formData.test1ImageIds = idList || []
    },
    setTest2ImgIds(idList) {
      this.formData.test2ImageIds = idList || []
    },
  }
}
</script>

<style scoped>
.fun-tip {
  font-weight: bold;
  font-size: 16px;
  color: red;
}

.form-item {
  width: 320px;
}
</style>