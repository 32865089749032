<template>
  <div :class="classArr" v-if="msg.length>0">
    {{ msgShow }}
  </div>
</template>

<script>
export default {
  name: 'MessageComponent',
  props: {
    fontMini: {
      type: Number,
      default: 60
    },
    fontLarge: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      msg: '',
      type: '',
      msgShow: ''
    };
  },
  computed: {
    classArr() {
      let arr = []
      arr.push(this.type == 'success' ? 'successMsg' : 'errorMsg')

      if (this.msgShow.length < this.fontLarge) {
        arr.push('large')
      }
      if (this.msgShow.length > this.fontMini) {
        arr.push('mini')
      }
      return arr
    }
  },
  created() {

  },
  methods: {
    currentDate() {
      var date = new Date();
      var h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
      var m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      var current = h + ':' + m + ':' + s;
      return current;
    },
    showSuccess(msg) {
      this.msg = msg
      this.msgShow = this.currentDate() + "     " + msg
      this.type = 'success'
    },
    showError(msg) {
      this.msg = msg
      this.msgShow = this.currentDate() + "     " + msg
      this.type = 'error'
    },
    clear() {
      this.msg = ''
      this.msgShow = ''
      this.type = ''
    },
  }
}
</script>

<style scoped>

.successMsg {
  display: inline-block;
  font-size: 16px;
  color: #67c23a;
  background: #f0f9eb;
  height: 38px;
  line-height: 38px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #c2e7b0;
  margin-left: 40px;
  margin-top: -2px;
}

.errorMsg {
  display: inline-block;
  font-size: 16px;
  color: #f56c6c;
  background: #fef0f0;
  height: 38px;
  line-height: 38px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #fbc4c4;
  margin-left: 20px;
  margin-top: -2px;
}

.mini {
  font-size: 12px;
}

.large {
  font-size: 22px;
}
</style>