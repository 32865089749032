<template>
  <el-card class="order-info-card" shadow="never">
    <el-row>
      <el-col :span="3" class="itemText">检验类型：</el-col>
      <el-col :span="5" class="itemValue">{{ order.testTypeName }}</el-col>
      <el-col :span="3" class="itemText">外观检验总数量：</el-col>
      <el-col :span="5" class="itemValue">{{ order.appearanceRequiredTestQty }}</el-col>
      <el-col :span="3" class="itemText">电性能检验总数量：</el-col>
      <el-col :span="5" class="itemValue">{{ order.electricityRequiredTestQty }}</el-col>
      <el-col :span="3" class="itemText">备件总件数：</el-col>
      <el-col :span="5" class="itemValue">{{ order.totalNumber }}</el-col>
    </el-row>
    <el-row>
      <el-col :span="3" class="itemText">订单号：</el-col>
      <el-col :span="5" class="itemValue">{{ order.code }}</el-col>
      <el-col :span="3" class="itemText">订单类型：</el-col>
      <el-col :span="5" class="itemValue">{{ order.orderTypeName }}</el-col>
      <el-col :span="3" class="itemText">出库库区名称：</el-col>
      <el-col :span="5" class="itemValue">{{ order.providerStockAreaName }}</el-col>
      <el-col :span="3" class="itemText">订单状态：</el-col>
      <el-col :span="3" class="itemValue">{{ order.statusName }}</el-col>
      <el-col :span="2">
        <el-button @click="changeShowInfo" type="text">{{ openOrderInfo ? '隐藏更多' : '展开更多' }}</el-button>
      </el-col>
    </el-row>
    <div v-show="openOrderInfo">
      <el-row>
        <el-col :span="3" class="itemText">入库库房ID：</el-col>
        <el-col :span="5" class="itemValue">{{ order.demanderWareHouse }}</el-col>
        <el-col :span="3" class="itemText">入库库房名称：</el-col>
        <el-col :span="5" class="itemValue">{{ order.demanderWareHouseName }}</el-col>
        <el-col :span="3" class="itemText">入库库区ID：</el-col>
        <el-col :span="5" class="itemValue">{{ order.demanderStockArea }}</el-col>
        <el-col :span="3" class="itemText">入库库区名称：</el-col>
        <el-col :span="5" class="itemValue">{{ order.demanderStockAreaName }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="3" class="itemText">出库库房ID：</el-col>
        <el-col :span="5" class="itemValue">{{ order.providerWareHouse }}</el-col>
        <el-col :span="3" class="itemText">出库库房名称：</el-col>
        <el-col :span="5" class="itemValue">{{ order.providerWareHouseName }}</el-col>
        <el-col :span="3" class="itemText">出库库区ID：</el-col>
        <el-col :span="5" class="itemValue">{{ order.providerStockArea }}</el-col>
        <el-col :span="3" class="itemText">备件粒度：</el-col>
        <el-col :span="5" class="itemValue">{{ order.granularity }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="3" class="itemText">检验商编码：</el-col>
        <el-col :span="5" class="itemValue">{{ order.testVendorCode }}</el-col>
        <el-col :span="3" class="itemText">检验商名称：</el-col>
        <el-col :span="5" class="itemValue">{{ order.testVendorName }}</el-col>
        <el-col :span="3" class="itemText">说明：</el-col>
        <el-col :span="13" class="itemValue">{{ order.description }}</el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "OrderInfo",
  props: {order: Object},
  data(){
    return{
      openOrderInfo: false,
    }
  },
  methods:{
    changeShowInfo() {
      this.openOrderInfo = !this.openOrderInfo
    },
  }
}
</script>

<style scoped>
.el-row{
  line-height: 32px;
}
.order-info-card {
  font-size: 12px;
  color: #606266;
  line-height: 40px;
  margin-bottom: 22px;
}

.order-info-card .el-row {
  display: flex;
}
.itemText {
  text-align: right;
}

.itemValue {
  color: goldenrod;
}
</style>